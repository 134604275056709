import React, { useContext, useEffect, useRef, useState } from "react";
import "./ReviewMarketplace.scss";

import { motion, useInView } from "framer-motion";
import mainContext from "../context/MainContext";

const animation = {
  anim1: (isInView) => {
    let style = {
      transform: isInView ? "none" : "translateX(250px)",
      WebkitTransform: isInView ? "none" : "translateX(250px)",
      MozTransform: isInView ? "none" : "translateX(250px)",
      msTransform: isInView ? "none" : "translateX(250px)",
      OTransform: isInView ? "none" : "translateX(250px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s`,
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateX(50px)",
      WebkitTransform: isInView ? "none" : "translateX(50px)",
      MozTransform: isInView ? "none" : "translateX(50px)",
      msTransform: isInView ? "none" : "translateX(50px)",
      OTransform: isInView ? "none" : "translateX(50px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.7}s`,
    };
    return style;
  },
};

const ReviewMarketplace = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { getReviewMarketplace, assetsUrl } = useContext(mainContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data === null) {
      getReviewMarketplace(setData);
    }
  }, [getReviewMarketplace, data]);

  return (
    <div className="reviewMarketplace flex flex-col space-y-4 sm:space-y-8 lg:space-y-12 pt-2 pb-12 sm:pt-4 sm:pb-[10vh] lg:pt-6 lg:pb-[10vh]">
      <div
        ref={ref}
        className="w-full sm:w-5/12 flex flex-col mx-auto items-center text-center"
      >
        <h5 className="text-graylight text-xl sm:text-1xl lg:text-2xl xl:text-2.1xl font-bold font-nunito-sans">
          Review Marketplace
        </h5>
      </div>

      {/* Laptop Screen */}
      <div className="hidden lg:block">
        <motion.div
          className="cursor-grab w-[94%] xl:w-11/12 flex flex-nowrap items-center mx-auto py-2 px-0"
          drag="x"
          dragConstraints={{
            right: 0,
            left:
              data !== null
                ? window.innerWidth *
                  -0.225 *
                  (data.length < 4 ? 0 : data.length - 4)
                : 0,
          }}
        >
          {data !== null &&
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-full sm:w-6/12 lg:w-3/12 px-2 sm:px-6 lg:px-3 xl:px-4 shrink-0"
                  style={animation.anim2(isInView, 0.3 * (index + 1))}
                >
                  <div
                    className="w-full rounded-md flex flex-col space-y-4 5xl:space-y-6 bg-white text-sm 5xl:text-base text-graylight"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.12) 0px 3px 8px" }}
                  >
                    <img
                      draggable="false"
                      className="w-full h-auto object-contain rounded-t-md"
                      src={`${assetsUrl}/review/${item.image}`}
                      alt="Review Marketplace Official DHC Supplement"
                    />
                  </div>
                </div>
              );
            })}
        </motion.div>
      </div>

      {/* Mobile Screen */}
      <motion.div className="reviewMarketplace__list w-[98%] sm:w-[96%] lg:hidden flex flex-nowrap items-start mx-auto px-2 sm:px-4 py-5">
        {data !== null &&
          data.map((item, index) => {
            return (
              <div
                key={index}
                className="reviewMarketplace__list-item w-full sm:w-6/12 px-2 sm:px-6 shrink-0"
                style={animation.anim2(isInView, 0.3 * (index + 1))}
              >
                <div
                  className="w-full rounded-md flex flex-col space-y-4 bg-white"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.12) 0px 3px 8px" }}
                >
                  <img
                    className="w-full h-auto object-contain rounded-t-md"
                    src={`${assetsUrl}/review/${item.image}`}
                    alt="Review Marketplace Official DHC Supplement"
                    draggable="false"
                  />
                </div>
              </div>
            );
          })}
      </motion.div>
    </div>
  );
};

export default ReviewMarketplace;
