import React, { useContext, useEffect, useState } from "react";
import "./Hero.scss";

import { motion } from "framer-motion";
import {
  EllipseDots,
  ArrowLeft,
  ArrowRight,
  IconFlower,
  EllipseDotsSm,
} from "../components/svg";
import Stars from "../components/svg/Stars";
import {
  variantsStars1,
  variantsStars2,
  variantsStars3,
  variantsStars4,
} from "../utils/MotionVariants";
import {
  screenLaptopLg,
  screenLaptopMd,
  screenLaptopSm,
  screenLaptopXl,
  screenLaptopXs,
  screenMobile,
} from "../utils/Breakpoints";
import mainContext from "../context/MainContext";

const variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: "easeIn",
    },
  },
};

const variantsImg = {
  slider1: {
    x: "18.5vw",
    width: "8vw",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider2: {
    x: "10vw",
    width: "9.5vw",
    transition: { duration: 0.75 },
    zIndex: 30,
  },
  slider3: {
    x: 0,
    width: "11vw",
    transition: { duration: 0.75 },
    zIndex: 40,
  },
  slider4: {
    x: "-10vw",
    width: "9.5vw",
    transition: { duration: 0.75 },
    zIndex: 30,
  },
  slider5: {
    x: "-18.5vw",
    width: "8vw",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider6: {
    x: "0vw",
    width: "7.5vw",
    transition: { duration: 0.75 },
    zIndex: 15,
  },
  slider7: {
    x: 0,
    width: "7.5vw",
    transition: { duration: 0.75 },
    zIndex: 10,
  },
  slider8: {
    x: 0,
    width: "7.5vw",
    transition: { duration: 0.75 },
    zIndex: 10,
  },
  slider9: {
    x: 0,
    width: "7.5vw",
    transition: { duration: 0.75 },
    zIndex: 10,
  },
  slider10: {
    x: 0,
    width: "7.5vw",
    transition: { duration: 0.75 },
    zIndex: 10,
  },
};

const variantsImgMobile = {
  slider1: {
    left: "15vw",
    top: "50%",
    transform: "translateY(-50%)",
    WebkitTransform: "translateY(-50%)",
    MozTransform: "translateY(-50%)",
    msTransform: "translateY(-50%)",
    OTransform: "translateY(-50%)",
    width: window.innerWidth > 378 ? "170px" : "140px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider2: {
    left: "6vw",
    top: "10%",
    transform: "translateY(-10%)",
    WebkitTransform: "translateY(-10%)",
    MozTransform: "translateY(-10%)",
    msTransform: "translateY(-10%)",
    OTransform: "translateY(-10%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider3: {
    left: -65,
    top: "5%",
    transform: "translateY(-5%)",
    WebkitTransform: "translateY(-5%)",
    MozTransform: "translateY(-5%)",
    msTransform: "translateY(-5%)",
    OTransform: "translateY(-5%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider4: {
    left: -65,
    top: "80%",
    transform: "translateY(-80%)",
    WebkitTransform: "translateY(-80%)",
    MozTransform: "translateY(-80%)",
    msTransform: "translateY(-80%)",
    OTransform: "translateY(-80%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider5: {
    left: -65,
    top: "95%",
    transform: "translateY(-95%)",
    WebkitTransform: "translateY(-95%)",
    MozTransform: "translateY(-95%)",
    msTransform: "translateY(-95%)",
    OTransform: "translateY(-95%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider6: {
    left: "6vw",
    top: "90%",
    transform: "translateY(-90%)",
    WebkitTransform: "translateY(-90%)",
    MozTransform: "translateY(-90%)",
    msTransform: "translateY(-90%)",
    OTransform: "translateY(-90%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider7: {
    left: "6vw",
    top: "90%",
    transform: "translateY(-90%)",
    WebkitTransform: "translateY(-90%)",
    MozTransform: "translateY(-90%)",
    msTransform: "translateY(-90%)",
    OTransform: "translateY(-90%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider8: {
    left: "6vw",
    top: "90%",
    transform: "translateY(-90%)",
    WebkitTransform: "translateY(-90%)",
    MozTransform: "translateY(-90%)",
    msTransform: "translateY(-90%)",
    OTransform: "translateY(-90%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider9: {
    left: "6vw",
    top: "90%",
    transform: "translateY(-90%)",
    WebkitTransform: "translateY(-90%)",
    MozTransform: "translateY(-90%)",
    msTransform: "translateY(-90%)",
    OTransform: "translateY(-90%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
  slider10: {
    left: "6vw",
    top: "90%",
    transform: "translateY(-90%)",
    WebkitTransform: "translateY(-90%)",
    MozTransform: "translateY(-90%)",
    msTransform: "translateY(-90%)",
    OTransform: "translateY(-90%)",
    width: window.innerWidth > 378 ? "50px" : "38px",
    transition: { duration: 0.75 },
    zIndex: 20,
  },
};

function Hero() {
  const { products, assetsUrl } = useContext(mainContext);

  const [productsData, setProductsData] = useState(null);
  const [theme, setTheme] = useState("vitamin-c");
  const [rotateNum, setRotateNum] = useState(0);
  const [slider, setSlider] = useState(1);
  const [circleTop, setCircleTop] = useState("");
  const [circleSmSize, setCircleSmSize] = useState(275);
  const [isAuto, setAuto] = useState(true);
  const [productsSlider, setProductsSlider] = useState(null);

  const calcCircleTop = () => {
    let top;

    if (window.innerWidth >= screenLaptopXl) {
      // screen 1920
      top = "-56vh";
    } else if (
      window.innerWidth >= screenLaptopLg &&
      window.innerWidth < screenLaptopXl
    ) {
      // screen 1536
      top = "-66vh";
    } else if (
      window.innerWidth >= screenLaptopMd &&
      window.innerWidth < screenLaptopLg
    ) {
      // screen 1440
      top = "-370px";
    } else if (
      window.innerWidth >= screenLaptopSm &&
      window.innerWidth < screenLaptopMd
    ) {
      // screen 1280
      top = "-400px";
    } else if (
      window.innerWidth >= screenLaptopXs &&
      window.innerWidth < screenLaptopSm
    ) {
      // screen 1024
      top = "-250px";
    }

    setCircleTop(top);
  };

  const slideRight = () => {
    let selectTheme;

    if (productsData !== null) {
      if (slider === productsData.length) {
        setSlider(1);
      } else {
        setSlider(slider + 1);
      }

      if (productsData[slider - 1].slug === theme) {
        selectTheme = productsData[slider + 1].slug;
      }
    }

    setTheme(selectTheme);
    if (window.innerWidth > screenMobile) {
      setRotateNum(rotateNum - 9);
    } else {
      setRotateNum(rotateNum - 18);
    }
  };

  const slideLeft = () => {
    let selectTheme;

    if (productsData !== null) {
      if (slider === 1) {
        setSlider(productsData.length);
      } else {
        setSlider(slider - 1);
      }

      if (productsData[slider - 1].slug === theme) {
        selectTheme = productsData[productsData.length - slider].slug;
      }
    }

    setTheme(selectTheme);
    setRotateNum(rotateNum + 9);
  };

  const variants2 = {
    initial: { rotate: rotateNum === 0 ? 0 : rotateNum + 9 },
    hidden: { opacity: 0 },
    rotate: {
      rotate: rotateNum,
      rotateNum,
      transition: {
        duration: 1,
      },
    },
  };

  // Default Variants
  const flowerVariants = {
    initial: { rotate: rotateNum === 0 ? 0 : rotateNum + 9 },
    hidden: { opacity: 0 },
    rotate1: {
      rotate: rotateNum * 1.4,
      transition: {
        duration: 1,
      },
    },
    rotate2: {
      rotate: -rotateNum * 1.4,
      transition: {
        duration: 1,
      },
    },
    rotate3: {
      rotate: rotateNum * 1.4,
      transition: {
        duration: 1,
      },
    },
  };

  useEffect(() => {
    if (productsData === null) {
      if (products !== null) {
        let filteredData = products.filter((item) => item.homepages === "yes");

        setProductsData(filteredData);
      }
    }

    if (productsData !== null && productsSlider === null) {
      const newData2 = [...productsData]
        .slice(0, 3)
        .sort((a, b) => b.id - a.id);
      const newData3 = [...productsData]
        .slice(3, productsData.length)
        .sort((a, b) => b.id - a.id);

      setProductsSlider({
        slider1: newData2,
        slider2: newData3,
      });
    }

    let autoTimeout;
    const circleSize = document.querySelector(
      ".hero__mobile-circle"
    ).clientWidth;

    if (circleTop === "") {
      calcCircleTop();
    }
    if (circleSmSize === 0 || circleSmSize !== Math.ceil(circleSize)) {
      setCircleSmSize(Math.ceil(circleSize));
    }

    if (isAuto) {
      autoTimeout = setTimeout(() => {
        slideRight();
      }, 3000);
    } else {
      setTimeout(() => {
        setAuto(true);
      }, 2000);
    }

    return () => {
      // if (!isAuto) {
      clearTimeout(autoTimeout);
      // }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circleTop, isAuto, slider, circleSmSize, products, productsData]);

  return (
    <div className="hero w-full flex flex-col sm:flex-row items-start bg-white sm:bg-transparent pb-5 sm:pb-0">
      {/* Mobile Screen */}
      <div className="hero__mobile block sm:hidden w-full h-[92vh] relative">
        {productsData !== null && (
          <div
            className="hero__mobile-circle w-[80vh] h-[80vh] relative z-[2]"
            style={{ marginLeft: -circleSmSize / 2 }}
          >
            <motion.img
              draggable="false"
              src={`${assetsUrl}/product/${productsData[slider - 1]?.slug}/${
                productsData[slider - 1]?.bgimage_homepage_sm
              }`}
              alt={theme}
              className="w-auto h-full object-contain"
              variants={variants}
              initial={{ opacity: 0.7, scale: 1 }}
              animate={"show"}
            />
          </div>
        )}
        <EllipseDotsSm
          className="hero__mobile-circle absolute h-[80vh] z-[3] top-0 scale-95"
          style={{ marginLeft: -circleSmSize / 2 }}
          variants={variants2}
          initial={"initial"}
          animate={"rotate"}
        />
        {/* Default Theme */}
        <IconFlower
          className="hero__mobile-dots w-[36%] absolute top-8 -right-1 overflow-visible"
          variants={flowerVariants}
        />
        {/* Ramadhan Theme */}
        {/* <LampionHero
          className="hero__mobile-dots w-[30%] h-auto absolute -top-[16%] right-0 z-20 overflow-visible"
        /> */}
        <div className="hero__mobile-list absolute w-full h-[80vh] top-0">
          {productsData !== null &&
            productsData !== null &&
            productsData.map((item, index) => {
              if (index === 0) {
                return (
                  <motion.img
                    key={index}
                    draggable="false"
                    className={`products-${slider} absolute z-10`}
                    src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                    alt={item.name_product}
                    variants={variantsImgMobile}
                    animate={`slider${slider}`}
                  />
                );
              }

              return (
                <motion.img
                  key={index}
                  draggable="false"
                  className={`products-${
                    slider > index
                      ? slider - index
                      : slider + (productsData.length - index)
                  }  absolute z-10`}
                  variants={variantsImgMobile}
                  src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                  alt={item.name_product}
                  animate={`slider${
                    slider > index
                      ? slider - index
                      : slider + (productsData.length - index)
                  }`}
                />
              );
            })}
        </div>
        <div className="flex sm:hidden flex-col items-end font-lora absolute bottom-[12vh] right-2 z-50">
          <h1 className="leading-sm font-semibold text-primary text-5xl">
            DHC
          </h1>
          <h2 className="leading-sm font-semibold">
            <motion.span
              className="text-3.1xl"
              animate={
                productsData !== null && {
                  color: productsData[slider - 1]?.color || "#000",
                  transition: { duration: 0.75 },
                }
              }
            >
              {productsData !== null
                ? productsData[slider - 1].name_product
                : "Vitamin C + B2"}
            </motion.span>{" "}
          </h2>
        </div>
      </div>

      {/* Laptop Screen */}
      <div className="hero__left w-full sm:w-[40%] flex flex-col space-y-6">
        <div className="hidden sm:flex flex-col space-y-2 font-lora">
          <h1 className="leading-sm font-semibold text-primary text-5.1xl xl:text-5.2xl 4xl:text-6xl">
            DHC
          </h1>
          <h2 className="leading-sm font-semibold pt-1">
            <motion.span
              className="text-4xl xl:text-4.1xl 4xl:text-4.2xl"
              animate={
                productsData !== null && {
                  color: productsData[slider - 1]?.color || "#000",
                  transition: { duration: 0.75 },
                }
              }
            >
              {productsData !== null
                ? productsData[slider - 1].name_product
                : "Vitamin C + B2"}
            </motion.span>{" "}
          </h2>
        </div>
        <div className="flex flex-col space-y-4 sm:space-y-5 text-[#717171] pt-4">
          <h3 className="text-xl sm:text-2xl xl:text-3xl 4xl:text-3.1xl leading-none font-serif font-medium whitespace-nowrap">
            Made In Japan. Loved Worldwide.
          </h3>
          <p className="text-base sm:text-xl xl:text-2xl 4xl:text-2.1xl font-nunito-sans leading-tight">
            There's a reason <br />
            customers love our products.
          </p>
          <p className="text-base sm:text-xl xl:text-2xl 4xl:text-2.1xl font-nunito-sans leading-tight">
            Produk berkualitas tinggi dan
            <br />
            Inovatif dari Jepang yang dicintai
            <br />
            di seluruh dunia.
          </p>
        </div>
        <div className="w-full flex flex-row items-start space-x-2 text-center pt-4">
          <div className="w-3/12 flex flex-col space-y-4 items-center">
            <img
              className="w-[3rem] h-[3rem] sm:w-[3.4rem] sm:h-[3.4rem] xl:w-[3.9rem] xl:h-[3.9rem]"
              src="/images/universal/bpom.png"
              alt="BPOM"
            />
            <p className="text-xxs xl:text-sm text-graylight">BPOM Certified</p>
          </div>
          <div className="w-3/12 flex flex-col space-y-4 items-center">
            <img
              className="w-[3rem] h-[3rem] sm:w-[3.4rem] sm:h-[3.4rem] xl:w-[3.9rem] xl:h-[3.9rem]"
              src="/images/universal/japan.png"
              alt="Japan"
            />
            <p className="text-xxs xl:text-sm text-graylight">Made In Japan</p>
          </div>
          <div className="w-3/12 flex flex-col space-y-4 items-center">
            <img
              className="w-[3rem] h-[3rem] sm:w-[3.4rem] sm:h-[3.4rem] xl:w-[3.9rem] xl:h-[3.9rem]"
              src="/images/universal/premium-quality.png"
              alt="Japan"
            />
            <p className="text-xxs xl:text-sm text-graylight">
              Premium Quality
            </p>
          </div>
          <div className="w-3/12 flex flex-col space-y-4 items-center">
            <img
              className="w-[3rem] h-[3rem] sm:w-[3.4rem] sm:h-[3.4rem] xl:w-[3.9rem] xl:h-[3.9rem]"
              src="/images/universal/no-1-market-share.png"
              alt="Japan"
            />
            <p className="text-xxs xl:text-sm text-graylight">
              No.1 in Japan
              <br />
              Market Share
            </p>
          </div>
        </div>
      </div>

      <div className="hero__right w-[60%] flex ">
        <div className="hero__right-main hidden sm:flex w-full h-screen relative">
          {productsData !== null && circleTop !== "" && (
            <div
              className="absolute w-[120%]"
              style={{ top: circleTop, right: "-15vw" }}
            >
              <motion.img
                draggable="false"
                src={`${assetsUrl}/product/${productsData[slider - 1]?.slug}/${
                  productsData[slider - 1]?.bgimage_homepage
                }`}
                alt={theme}
                className="w-full h-auto object-contain select-none"
                variants={variants}
                initial={{ opacity: 0.7, scale: 1 }}
                animate={"show"}
              />
            </div>
          )}
          {productsData !== null && circleTop !== "" && (
            <EllipseDots
              className="absolute circle-dots"
              widthSvg="120%"
              style={{ top: circleTop, right: "-15vw" }}
              variants={variants2}
              initial={"initial"}
              animate={"rotate"}
            />
          )}
          <div className="hero__right-products z-50">
            {productsData !== null &&
              productsSlider?.slider1.map((item, index) => {
                if (index === 0) {
                  return (
                    <motion.img
                      key={index}
                      draggable="false"
                      className={`products-${slider}`}
                      animate={`slider${slider}`}
                      src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                      alt="Product"
                      variants={variantsImg}
                    />
                  );
                }

                return (
                  <motion.img
                    key={index}
                    draggable="false"
                    className={`products-${
                      slider > productsData.length - index
                        ? slider - (productsData.length - index)
                        : slider + index
                    }`}
                    animate={`slider${
                      slider > productsData.length - index
                        ? slider - (productsData.length - index)
                        : slider + index
                    }`}
                    src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                    alt="Product"
                    variants={variantsImg}
                  />
                );
              })}

            {productsData !== null &&
              productsSlider?.slider2.map((item, index) => {
                return (
                  <motion.img
                    key={index}
                    draggable="false"
                    className={`products-${
                      slider > productsData.length - (index + 3)
                        ? slider - (productsData.length - (index + 3))
                        : slider + (index + 3)
                    }`}
                    animate={`slider${
                      slider > productsData.length - (index + 3)
                        ? slider - (productsData.length - (index + 3))
                        : slider + (index + 3)
                    }`}
                    src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                    alt="Product"
                    variants={variantsImg}
                  />
                );
              })}

            <ArrowLeft
              onClick={() => {
                slideLeft();
                setAuto(false);
              }}
              className="arrow-left-btn absolute z-10"
              color={productsData !== null && productsData[slider - 1]?.color}
            />
            <ArrowRight
              onClick={() => {
                slideRight();
                setAuto(false);
              }}
              className="arrow-right-btn absolute z-10"
              color={productsData !== null && productsData[slider - 1]?.color}
            />
            <Stars
              initial={{ x: 0, y: 0 }}
              variants={variantsStars1}
              animate={`move${slider}`}
            />
            <Stars
              initial={{ x: 0, y: 0 }}
              variants={variantsStars2}
              animate={`move${slider}`}
            />
            <Stars
              initial={{ x: 0, y: 0 }}
              variants={variantsStars3}
              animate={`move${slider}`}
            />
            <Stars
              initial={{ x: 0, y: 0 }}
              variants={variantsStars4}
              animate={`move${slider}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
