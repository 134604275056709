import React, { useContext, useEffect, useState } from "react";
import "./LinkStore.scss";

import { Helmet } from "react-helmet-async";
import mainContext from "../context/MainContext";

const LinkStore = () => {
  const { getListStore, assetsUrl2 } = useContext(mainContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data === null) {
      getListStore(setData);
    }
  }, [data, getListStore]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href={`https://dhcsupplement.co.id/link-store`} />
        <title>Store Links - DHC Supplement</title>
        <meta
          name="description"
          content="Link sosial media dan Official E-Commerce DHC Supplement. Ikuti sosial media dan Official E-Commerce kami agar kalian selalu update informasi terbaru dari produk-produk DHC."
        />
        <meta
          name="keywords"
          content="dhc supplement, suplemen dhc, suplemen kesehatan, link suplemen dhc, social media suplemen dhc, official e-commerce dhc supplement"
        />
        <meta property="og:title" content={`Store Links - DHC Supplement`} />
        <meta
          property="og:description"
          content="Link sosial media dan Official E-Commerce DHC Supplement. Ikuti sosial media dan Official E-Commerce kami agar kalian selalu update informasi terbaru dari produk-produk DHC."
        />
        <meta
          property="og:url"
          content="https://dhcsupplement.co.id/link-store"
        />
      </Helmet>

      <div className="linkStore w-full ">
        <div className="fixed w-full h-[100vh] z-0">
          <img
            src="/images/universal/shape.svg"
            alt="Logo"
            className="block lg:hidden w-full h-full object-cover object-center"
          />
          <img
            src="/images/universal/shape-lg.svg"
            alt="Logo"
            className="hidden lg:block w-full h-full object-cover object-center"
          />
        </div>

        <div className="linkTree__body relative z-10 mx-auto w-full h-full py-10 flex flex-col space-y-6 font-nunito-sans text-[#252525]">
          <div className="flex items-center justify-center w-24 h-24 md:w-24 md:h-24 lg:w-28 lg:h-28 mx-auto bg-primary rounded-full shrink-0">
            <img
              src={data?.logo || "/images/universal/logo-light.png"}
              alt="Logo"
              className="w-8/12 h-auto object-cover"
            />
          </div>
          {data === null || data?.status === "error" ? (
            <div className="flex flex-col space-y-1 justify-center items-center text-center text-slate-800">
              <h1 className="text-xl sm:text-2.1xl font-bold">
                DHC Supplement Indonesia
              </h1>
              <p className="w-[82%] sm:w-8/12 md:w-6/12 lg:w-4/12 text-sm sm:text-lg font-semibold">
                Tersedia di Apotek dan Supermarket Jabodetabek, Bandung,
                Semarang, dan Bali
              </p>
            </div>
          ) : (
            <div className="flex flex-col space-y-1 justify-center items-center text-center text-slate-800">
              <h1 className="text-xl sm:text-2.1xl font-bold">{data?.title}</h1>
              <p className="w-[82%] sm:w-8/12 md:w-6/12 lg:w-4/12 text-sm sm:text-lg font-semibold">
                {data?.subtitle}
              </p>
            </div>
          )}
          <div className="w-[94%] sm:w-8/12 md:w-6/12 lg:w-4/12 flex flex-col space-y-10 mx-auto pt-5 pb-5">
            {data === null || data?.status === "error"
              ? ""
              : data?.data?.map((item, index) => {
                  return (
                    <div key={index} className="w-full flex flex-col space-y-3">
                      <h5 className="text-slate-800 text-xl font-semibold text-center">
                        {item?.location_name}
                      </h5>
                      <div className="w-full flex flex-col space-y-5">
                        {item?.stores?.length ? (
                          item?.stores?.map((i, idx) => {
                            return (
                              <a
                                key={idx}
                                href={i?.store_map || ""}
                                target="_blank"
                                rel="noreferrer"
                                className="relative flex w-full rounded-full"
                              >
                                <div className="linkCard relative flex items-center justify-center w-full p-4 text-slate-800 text-sm sm:text-base font-bold transition-hover">
                                  <div className="absolute flex justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-slate-800 shrink-0">
                                    <img
                                      src={
                                        `${assetsUrl2}/${i?.store_image}` ||
                                        "/images/link-icon/outlets.svg"
                                      }
                                      alt="Store"
                                      className="w-full h-full object-cover rounded-full"
                                    />
                                  </div>

                                  <p className="pl-10 w-full text-center overflow-hidden text-ellipsis whitespace-nowrap">
                                    {i?.store_name}
                                  </p>
                                </div>
                              </a>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkStore;
