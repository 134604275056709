import React from 'react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { ease: 'easeInOut', duration: 0.7 },
  },
};

const IconCrown = (props) => (
  <motion.svg
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    initial="hidden"
    animate={props.isHovered === props.bdgIndex + 1 ? 'visible' : 'hidden'}
    onMouseEnter={() => props.hoverEnter(props.bdgIndex, props.badgeName)}
    onMouseLeave={props.hoverLeave}
  >
    <motion.path
      d="M129.692 27.3728C127.295 28.9706 125.806 68.7577 125.356 88.6303C125.353 88.7524 125.369 88.864 125.416 88.9766C129.29 98.1988 179.127 101.472 182.114 97.1575C184.408 93.844 190.735 60.6771 194.018 42.271C194.178 41.37 193.146 40.7548 192.422 41.3152C184.034 47.8137 170.604 58.0733 171.096 57.0897C171.59 56.1006 168.117 39.5861 165.921 29.6326C165.732 28.7759 164.615 28.565 164.124 29.2925C158.049 38.3076 147.886 53.5102 147.389 54.7524C146.721 56.4219 132.697 25.3694 129.692 27.3728Z"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M125.686 87.8085C138.04 82.6887 166.555 77.1905 181.78 96.1559"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M129.023 20.027C125.357 22.1217 127.808 25.6706 129.584 27.2778C129.655 27.342 129.74 27.3968 129.829 27.4325C134.024 29.12 133.647 17.3845 129.023 20.027Z"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M164.65 20.0827C160.984 22.1773 163.435 25.7263 165.211 27.3335C165.282 27.3976 165.367 27.4525 165.456 27.4881C169.651 29.1756 169.274 17.4402 164.65 20.0827Z"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M199.002 33.3793C194.983 32.0888 194.019 36.2928 194.032 38.6877C194.032 38.7833 194.049 38.8832 194.083 38.9727C195.669 43.2069 204.074 35.0074 199.002 33.3793Z"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M115 8.00684C117.003 10.2328 120.943 15.219 120.676 17.356"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M124.35 4C125.351 5.55819 127.154 9.14203 126.353 11.0119"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M137.371 7.67285C137.259 9.45364 136.703 13.2823 135.367 14.3508"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M160.076 5.66943L161.412 13.0152"
      stroke="#F5F08E"
      strokeWidth={2}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M169.091 5.66943C169.091 6.89373 168.824 9.60943 167.756 10.6779"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M179.109 11.3457C177.996 12.7926 175.77 16.0203 175.77 17.3559"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M200.812 18.0239C200.812 19.916 200.612 23.9673 199.811 25.0358"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M210.161 22.0303L205.82 27.3726"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M208.492 33.7171L214.168 29.0425"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M127.02 90.8134L132.184 87.0951C133.025 86.4897 134.118 87.4054 133.67 88.3394L131.36 93.1507"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
    <motion.path
      d="M132.029 93.8188L139.176 85.5723C139.753 84.9069 140.846 85.2578 140.928 86.1346L141.759 95.0699C141.849 96.0369 143.125 96.321 143.617 95.4835L149.46 85.5289C149.954 84.6864 151.239 84.9801 151.319 85.9538L152.089 95.3851C152.172 96.4088 153.56 96.6564 153.993 95.7249L158.151 86.7672C158.638 85.7194 160.219 86.1942 160.047 87.3367L158.821 95.5098C158.659 96.5926 160.105 97.1115 160.668 96.1727L165.543 88.0481C166.104 87.1126 167.544 87.624 167.39 88.704L166.498 94.9504C166.332 96.1085 167.949 96.56 168.408 95.4837L170.496 90.5806C170.946 89.5248 172.53 89.9346 172.411 91.0761L171.896 96.0135C171.785 97.0777 173.198 97.5491 173.748 96.6316L176.215 92.5205C176.671 91.7608 177.817 91.9253 178.04 92.7826L179.443 98.1595"
      stroke="#F5F08E"
      strokeWidth={3}
      strokeLinecap="round"
      variants={pathVariants}
    />
  </motion.svg>
);

export default IconCrown;
