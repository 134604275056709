import React from 'react';

function BubbleText(props) {
  return (
    <svg
      viewBox="0 0 1098 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.652 0c-13.255 0-24 10.745-24 24v202.83c0 2.99.546 5.852 1.545 8.492C34.527 245.713 19.292 255.653 0 262.649c27.431 2.561 82.733 2.859 116.728-11.819h957.152c13.25 0 24-10.745 24-24V24c0-13.255-10.75-24-24-24H68.652z"
        fill="#fff"
      />
    </svg>
  );
}

export default BubbleText;
