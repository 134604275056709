import React, { Fragment, useContext, useEffect, useState } from "react";
import "./ProductDetails.scss";

import { Link, useParams, useLocation } from "react-router-dom";

import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import { ArrowButton, ButtonSimple, BuyNowButton } from "../components/Button";
import { AnimatePresence, motion } from "framer-motion";
import BuyNowModal from "../components/BuyNowModal";
import Accordion from "../components/Accordion";
import {
  IconCheck,
  IconCrown,
  IconWater,
  IconRibbon,
  IconSevenDay,
  IconThumbs,
  IconLine,
  IconDocFyi,
  IconDiet,
} from "../components/svg";
import ForYourInfo from "../section/ForYourInfo";
import mainContext from "../context/MainContext";
import {
  screenLaptopLg,
  screenLaptopMd,
  screenLaptopSm,
  screenLaptopXl,
  screenLaptopXs,
} from "../utils/Breakpoints";
import ProductModal from "../components/ProductModal";
import ProductSliderMobile from "../components/mobile/ProductSliderMobile";
import { MdShare } from "react-icons/md";
import ShareModal from "../components/ShareModal";
import FyiModal from "../components/FyiModal";
// import unslugify from "unslugify";
import keywordProducts from "../utils/keywordProducts";
import MetaKeywords from "../components/MetaKeywords";
import unslugify from "unslugify";

const SvgComponents = {
  "No.1 Market Share": IconCrown,
  "Collagen Peptide": IconWater,
  "Sold More than 44 Million": IconRibbon,
  CBP: IconThumbs,
};

const transition = {
  type: "tween",
  duration: 0.5,
  ease: [0.7, -0.4, 0.4, 1.2],
};

const badgeVariants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    y: 15,
    zIndex: -1,
  },
  show: { opacity: 1, scale: 1, y: 0, zIndex: 0 },
};

// Framer Motion Variants
const badgeMobileVariants = {
  open: { height: "auto", zIndex: 10, opacity: 1 },
  collapsed: { height: 0, zIndex: -10, opacity: 0 },
};

const btnVariants = {
  enter: { scale: 1.1, boxShadow: "rgba(17, 17, 26, 0.32) 0px 0px 16px" },
  leave: { scale: 1, boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px" },
  active: { opacity: 1 },
  disable: { opacity: 0.7 },
};

function ProductDetails() {
  const location = useLocation();

  const [product, setProduct] = useState(null);
  const [slide, setSlide] = useState(1);
  const [accordion, setAccordion] = useState(0);
  const [arrowX, setArrowX] = useState(0);
  const [modalSlide, setModalSlide] = useState(1);
  const [badge, setBadge] = useState("");
  const [badgeMobile, setBadgeMobile] = useState(0);
  const [usage, setUsage] = useState("");

  // Modal State
  const [showModal, setShowModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const { name_product } = useParams();

  // Badge Animation
  const [isHovered, setHovered] = useState(false);

  // Handle notfound product
  const [isNotfound, setNotfound] = useState();

  const {
    isFyiHovered,
    showFyiModal,
    setShowFyiModal,
    getDetailProduct,
    assetsUrl,
  } = useContext(mainContext);

  let RenderEffect = SvgComponents[badge];

  const calcX = () => {
    let amountX = "";

    if (window.innerWidth >= screenLaptopXl) {
      amountX = "19.6vw";
    } else if (
      window.innerWidth >= screenLaptopLg &&
      window.innerWidth < screenLaptopXl
    ) {
      amountX = "19.1vw";
    } else if (
      window.innerWidth >= screenLaptopMd &&
      window.innerWidth < screenLaptopLg
    ) {
      amountX = "19.25vw";
    } else if (
      window.innerWidth >= screenLaptopSm &&
      window.innerWidth < screenLaptopMd
    ) {
      amountX = "19vw";
    } else if (
      window.innerWidth >= screenLaptopXs &&
      window.innerWidth < screenLaptopSm
    ) {
      amountX = "18.55vw";
    }

    setArrowX(amountX);
  };

  const variants = {
    initial: { x: 0, y: 0, scale: 1, opacity: 0.5 },
    show: { x: "19vw", y: "13vh", scale: 2.8, opacity: 1 },
    show2: { x: "19vw", y: "-13vh", scale: 2.8, opacity: 1 },
    show3: { x: "19vw", y: "-37vh", scale: 3.8, opacity: 1 },
  };

  const handleAccordion = (id) => {
    if (accordion === id) {
      setAccordion(0);
    } else {
      setAccordion(id);
    }
  };

  const productDetailsLinks = [
    {
      page: "Home",
      link: "/",
    },
    {
      page: "Our Product",
      link: "/our-product",
    },
    {
      page: product !== null ? product.name_product : "",
      link: `/our-product/${name_product}`,
    },
  ];

  const slideRight = () => {
    if (slide !== 3) {
      setSlide(slide + 1);
    }
  };

  const slideLeft = () => {
    if (slide !== 1) {
      setSlide(slide - 1);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);

    if (showModal === false) {
      setShowFyiModal(false);
      setShowProductModal(false);
      setShowShareModal(false);
    }

    if (showModal) {
      document.body.classList.remove("hide-scroll");
    } else {
      document.body.classList.add("hide-scroll");
    }
  };

  const toggleProductModal = (id) => {
    setShowProductModal(!showProductModal);
    setModalSlide(id);

    if (showProductModal === false) {
      setShowModal(false);
      setShowFyiModal(false);
      setShowShareModal(false);
    }

    if (showProductModal) {
      document.body.classList.remove("hide-scroll");
    } else {
      document.body.classList.add("hide-scroll");
    }
  };

  const toggleShareModal = () => {
    setShowShareModal(!showShareModal);

    if (showShareModal === false) {
      setShowModal(false);
      setShowFyiModal(false);
      setShowProductModal(false);
    }
  };

  const toggleFyiModal = () => {
    setShowFyiModal(!showFyiModal);

    if (showFyiModal === false) {
      setShowModal(false);
      setShowShareModal(false);
      setShowProductModal(false);
    }
  };

  const handleImgProduct = (idx) => {
    if (slide !== idx) {
      setSlide(idx);
    } else {
      toggleProductModal(idx);
    }
  };

  const hoverEnter = (badgeIndex, badgeName) => {
    if (window.innerWidth >= screenLaptopXs) {
      setHovered(badgeIndex + 1);
      setBadge(badgeName);
    }
  };

  const hoverLeave = () => {
    if (window.innerWidth >= screenLaptopXs) {
      setHovered(false);
      setBadge("");
    }
  };

  const hoverUsageEnter = (usageIndex, usageName) => {
    if (window.innerWidth >= screenLaptopXs) {
      setHovered(99 + usageIndex);
      setUsage(usageName);
    }
  };

  const hoverUsageLeave = () => {
    if (window.innerWidth >= screenLaptopXs) {
      setHovered(false);
      setUsage("");
    }
  };

  const handleBadgeMobile = (id) => {
    if (badgeMobile !== id) {
      setBadgeMobile(id);

      setTimeout(() => {
        setBadgeMobile(0);
      }, 4000);
    }
  };

  const PickIconUsage = (name, idx) => {
    let icon = {
      "7 hari": (
        <IconSevenDay
          className={`w-12 h-12 xl:w-14 xl:h-14 relative ${
            isHovered === 99 + idx ? "z-[2]" : "z-[1]"
          }`}
          onMouseEnter={() => hoverUsageEnter(idx, name)}
          onMouseLeave={hoverLeave}
        />
      ),
      diet: (
        <IconDiet
          className={`w-12 h-12 xl:w-14 xl:h-14 relative ${
            isHovered === 99 + idx ? "z-[2]" : "z-[1]"
          }`}
          onMouseEnter={() => hoverUsageEnter(idx, name)}
          onMouseLeave={hoverLeave}
        />
      ),
    };

    return icon[name];
  };

  useEffect(() => {
    if (product === null) {
      getDetailProduct(setProduct, name_product, setNotfound);
    }
    if (arrowX === 0) {
      calcX();
    }
  }, [name_product, product, arrowX, getDetailProduct]);

  return (
    <>
      {/* <Helmet>
        <link
          rel="canonical"
          href={`https://dhcsupplement.co.id/our-product/${name_product}`}
        />
        <title>{unslugify(name_product)} - DHC Supplement</title>
        <meta
          name="description"
          content={keywordProducts[name_product].description}
        />
        <meta name="keywords" content={keywordProducts[name_product].keyword} />
        <meta
          property="og:title"
          content={`${unslugify(name_product)} - DHC Supplement`}
        />
        <meta
          property="og:description"
          content={keywordProducts[name_product].description}
        />
        <meta
          property="og:url"
          content={`https://dhcsupplement.co.id/our-product/${name_product}`}
        />
      </Helmet> */}

      <MetaKeywords
        slug={name_product}
        path={location?.pathname}
        keyword={keywordProducts[name_product].keyword}
        description={keywordProducts[name_product].description}
        title={unslugify(name_product)}
      />

      {product !== null ? (
        <BuyNowModal
          showModal={showModal}
          setShowModal={setShowModal}
          toggleModal={toggleModal}
          name_product={product.name_product}
          img={product.img1}
          shop_list={product.shop_list}
          slug={product.slug}
        />
      ) : null}
      {product !== null ? (
        <ProductModal
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
          toggleProductModal={toggleProductModal}
          name_product={product.name_product}
          img_front={`${assetsUrl}/product/${product.slug}/${product.img_front}`}
          img_back={`${assetsUrl}/product/${product.slug}/${product.img_back}`}
          img_object={`${assetsUrl}/product/${product.slug}/${product.img_object}`}
          modalSlide={modalSlide}
          setModalSlide={setModalSlide}
        />
      ) : null}
      {product !== null ? (
        <ShareModal
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
          toggleShareModal={toggleShareModal}
          product={product}
        />
      ) : null}
      {product !== null ? (
        <FyiModal product={product} toggleFyiModal={toggleFyiModal} />
      ) : null}
      <motion.div
        className="w-screen h-screen fixed bg-black transition-all duration-200 ease-in"
        initial={{ opacity: 0, zIndex: -1 }}
        animate={
          isFyiHovered || isHovered
            ? { opacity: 0.4, zIndex: 2, display: "block" }
            : { opacity: 0, zIndex: -1, display: "none" }
        }
        transition={{
          ease: "easeOut",
          duration: 0.05,
        }}
      ></motion.div>

      <Layout activeNav="our-product">
        <main className="productDetails layout-gap relative">
          <Breadcrumb links={productDetailsLinks} />
          <MdShare
            onClick={toggleShareModal}
            className="productDetails-share bg-primary w-12 h-12 fixed bottom-6 right-6 sm:bottom-8 sm:right-8 cursor-pointer text-white"
          />
          <IconDocFyi
            className="productDetails-fyi block sm:hidden w-12 h-12 fixed bottom-24 right-6 cursor-pointer rounded-full"
            onClick={toggleFyiModal}
          />

          {product !== null ? (
            <div>
              <div className="productDetails__main w-full flex flex-col sm:flex-row items-start p-5 pt-8 sm:p-14 sm:pt-20">
                <div className="productDetails__main-left w-[6%] hidden sm:flex flex-col space-y-8">
                  <div className="hidden sm:block w-full h-auto relative">
                    <motion.img
                      className="w-full h-auto cursor-pointer"
                      src={`${assetsUrl}/product/${product.slug}/${product.img_front}`}
                      alt="Product"
                      animate={slide === 1 ? { opacity: 1 } : { opacity: 0 }}
                      onClick={() => setSlide(1)}
                    />
                    <motion.img
                      className="w-full h-auto absolute top-0 cursor-pointer"
                      initial="initial"
                      src={`${assetsUrl}/product/${product.slug}/${product.img_front}`}
                      alt="Product"
                      variants={variants}
                      animate={slide === 1 ? "show" : "initial"}
                      transition={transition}
                      onClick={() => handleImgProduct(1)}
                    />
                  </div>
                  <div className="hidden sm:block w-full h-auto relative">
                    <motion.img
                      className="w-full h-auto cursor-pointer"
                      src={`${assetsUrl}/product/${product.slug}/${product.img_back}`}
                      alt="Product"
                      animate={slide === 2 ? { opacity: 1 } : { opacity: 0 }}
                      onClick={() => setSlide(2)}
                    />
                    <motion.img
                      className="w-full h-auto absolute top-0 cursor-pointer"
                      initial="initial"
                      src={`${assetsUrl}/product/${product.slug}/${product.img_back}`}
                      alt="Product"
                      variants={variants}
                      animate={slide === 2 ? "show2" : "initial"}
                      transition={transition}
                      onClick={() => handleImgProduct(2)}
                    />
                  </div>
                  <div className="hidden sm:block w-full h-auto relative">
                    <motion.img
                      className="w-full h-auto scale-105 cursor-pointer"
                      src={`${assetsUrl}/product/${product.slug}/${product.img_object}`}
                      alt="Product"
                      animate={slide === 3 ? { opacity: 1 } : { opacity: 0 }}
                      onClick={() => setSlide(3)}
                    />
                    <motion.img
                      className="w-full h-auto absolute top-0 cursor-pointer"
                      initial="initial"
                      src={`${assetsUrl}/product/${product.slug}/${product.img_object}`}
                      alt="Product"
                      variants={variants}
                      animate={slide === 3 ? "show3" : "initial"}
                      transition={transition}
                      onClick={() => handleImgProduct(3)}
                    />
                  </div>
                </div>
                <div className="productDetails__main-mid w-[35.6%] hidden sm:flex pt-[35vh] xl:pt-[39vh]">
                  <div className="w-full flex flex-col items-center pb-5">
                    <div className="w-full flex flex-row items-center justify-center space-x-5 px-16 pt-20 xl:pt-32">
                      <motion.img
                        className="w-9 h-9 cursor-pointer z-20 rounded-full"
                        src="/images/universal/arrow-left.png"
                        alt="Arrow Left"
                        onClick={slideLeft}
                        variants={btnVariants}
                        animate={slide === 1 ? "disable" : "active"}
                        whileHover={slide === 1 ? "leave" : "enter"}
                      />

                      <motion.img
                        className="w-9 h-9 cursor-pointer z-20 rounded-full"
                        src="/images/universal/arrow-right.png"
                        alt="Arrow Right"
                        onClick={slideRight}
                        variants={btnVariants}
                        animate={slide === 3 ? "disable" : "active"}
                        whileHover={slide === 3 ? "leave" : "enter"}
                      />
                    </div>
                    <div className="py-6">
                      <BuyNowButton onClick={toggleModal}>
                        Beli Produk
                      </BuyNowButton>
                    </div>

                    <div
                      className={`flex flex-col ${
                        product.badge !== null ? "space-y-5" : "space-y-0"
                      }`}
                    >
                      <div className="w-full flex flex-row space-x-8 items-start">
                        {product.icon !== null &&
                          product.icon.map((icon, index) => {
                            return (
                              <div
                                key={index}
                                className="flex flex-col space-y-4 items-center"
                              >
                                <img
                                  className="w-16 h-16 xl:w-[70px] xl:h-[70px]"
                                  src={`${assetsUrl}/product/${product.slug}/${icon.img}`}
                                  alt={icon.text}
                                />
                                <p className="text-xs xl:text-sm text-graylight">
                                  {icon.text}
                                </p>
                              </div>
                            );
                          })}
                      </div>

                      {product.badge !== null && (
                        <div
                          className={`w-full flex flex-row justify-center items-center ${
                            product.badge.length > 1
                              ? "space-x-14"
                              : "space-x-0"
                          }`}
                        >
                          {product.badge.map((bdg, bdgIndex) => {
                            return (
                              <div
                                key={bdgIndex}
                                className={`relative h-auto ${
                                  isHovered === bdgIndex + 1 ? "z-[2]" : "z-[1]"
                                }`}
                              >
                                {badge !== "" && (
                                  <RenderEffect
                                    isHovered={isHovered}
                                    bdgIndex={bdgIndex}
                                    className="absolute w-44 h-44 xl:w-48 xl:h-48 2xl:w-52 2xl:h-52 absolute-center select-none z-0"
                                    hoverEnter={hoverEnter}
                                    hoverLeave={hoverLeave}
                                    badgeName={bdg.badge_name}
                                  />
                                )}

                                <img
                                  className="w-16 h-16 xl:w-[70px] xl:h-[70px]"
                                  src={`${assetsUrl}/product/${product.slug}/${bdg.badge_img}`}
                                  alt={bdg.badge_name}
                                  onMouseEnter={() =>
                                    hoverEnter(bdgIndex, bdg.badge_name)
                                  }
                                  onMouseLeave={hoverLeave}
                                />
                                {bdg.badge_description !== "" && (
                                  <motion.div
                                    variants={badgeVariants}
                                    initial="hidden"
                                    animate={
                                      isHovered === bdgIndex + 1
                                        ? "show"
                                        : "hidden"
                                    }
                                    transition={{ duration: 0.3 }}
                                  >
                                    <motion.div
                                      animate={{
                                        display:
                                          isHovered === bdgIndex + 1
                                            ? "flex"
                                            : "none",
                                      }}
                                      className="absolute badgeHover"
                                    >
                                      <div
                                        className="text-center font-nunito-sans text-sm xl:text-base whitespace-pre text-white"
                                        dangerouslySetInnerHTML={{
                                          __html: bdg.badge_description,
                                        }}
                                      ></div>
                                    </motion.div>
                                  </motion.div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Slider just show on mobile */}
                <ProductSliderMobile
                  img_front={product.img_front}
                  img_back={product.img_back}
                  img_object={product.img_object}
                  slug={product.slug}
                />

                <div className="block mx-auto sm:hidden py-6">
                  <BuyNowButton onClick={toggleModal}>Beli Produk</BuyNowButton>
                </div>

                <div className="productDetails__main-right w-full sm:w-7/12 flex flex-col text-primary font-nunito-sans text-lg">
                  <div className="w-full flex items-center space-x-5 pb-3">
                    <h2 className="productDetails__main-productTitle font-bold text-xl sm:text-2xl xl:text-2.1xl font-lora shrink-0">
                      {`DHC ${product.name_product}`}
                    </h2>

                    <div className="w-full relative flex space-x-3 items-center">
                      {product.usage.map((item, usgIndex) => {
                        return (
                          <div
                            className="relative flex whitespace-nowrap"
                            key={usgIndex}
                          >
                            {PickIconUsage(item.usage_title, usgIndex)}
                            <motion.p
                              className="absolute hidden lg:inline left-20 absolute-y-center w-full text-white text-sm xl:text-base font-nunito-sans select-none"
                              initial={{
                                opacity: 0,
                                zIndex: 0,
                              }}
                              animate={
                                isHovered === 99 + usgIndex
                                  ? { opacity: 1, zIndex: 2 }
                                  : {
                                      opacity: 0,
                                      zIndex: 0,
                                    }
                              }
                              transition={{ duration: 0.3 }}
                            >
                              {item?.usage_title === "diet" ||
                              !item.usage_title.includes("hari") ? (
                                <span>produk ini&nbsp;&nbsp;&nbsp;</span>
                              ) : (
                                <span>
                                  1 pouch/pack produk ini&nbsp;&nbsp;&nbsp;
                                </span>
                              )}
                              <span className="relative">
                                {`untuk ${item.usage_title}`}
                                {usage === item.usage_title && (
                                  <IconLine
                                    idx={usgIndex}
                                    isHovered={isHovered}
                                    className="absolute w-full h-auto left-0 -bottom-3 select-none z-0 scale-[1.22] overflow-visible"
                                    hoverEnter={hoverUsageEnter}
                                    hoverLeave={hoverUsageLeave}
                                    badgeName={item.usage_title}
                                  />
                                )}
                              </span>
                            </motion.p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="w-full flex flex-row text-base xl:text-lg">
                    <div
                      className={`w-full sm:w-[90%] xl:w-[86%] flex flex-col space-y-5`}
                    >
                      <div
                        className="text-justify whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      ></div>
                      <div className="flex flex-col space-y-3 pb-7">
                        {product.list.map((itemList, idxList) => {
                          return (
                            <div
                              key={idxList}
                              className="w-full flex items-center space-x-2"
                            >
                              <IconCheck className="shrink-0 w-6 xl:w-7 h-auto" />
                              <p>{itemList.text}</p>
                            </div>
                          );
                        })}
                      </div>

                      <div className="flex flex-col text-justify">
                        {product.accordion.map((acc, index) => {
                          return (
                            <Fragment key={index}>
                              <Accordion
                                id={index + 1}
                                title={acc.title}
                                handleAccordion={handleAccordion}
                                accordion={accordion}
                                lastChild={
                                  product.accordion.length - 1 === index
                                    ? true
                                    : false
                                }
                                Icon={`${assetsUrl}/product/${product.slug}/${acc.icon}`}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: acc.content,
                                  }}
                                ></div>
                              </Accordion>
                            </Fragment>
                          );
                        })}
                      </div>

                      {/* Mobile Screen Badge */}
                      <div
                        className={`flex sm:hidden flex-col ${
                          product.badge !== null ? "space-y-5" : "space-y-0"
                        }`}
                      >
                        <div
                          className={`w-full flex flex-wrap flex-row justify-center items-start`}
                        >
                          {product.icon !== null &&
                            product.icon.map((icon, index) => {
                              return (
                                <motion.div
                                  key={index}
                                  className="w-3/12 flex flex-col space-y-4 items-center"
                                  animate={
                                    badgeMobile === index + 1 ||
                                    badgeMobile === 0
                                      ? {
                                          zIndex: 0,
                                          width: "25%",
                                          transition: {
                                            duration: 0.3,
                                            delay: 0.4,
                                          },
                                        }
                                      : { zIndex: -200, width: 0 }
                                  }
                                  transition={{ duration: 0.3 }}
                                >
                                  <img
                                    className="w-14 h-14"
                                    src={`${assetsUrl}/product/${product.slug}/${icon.img}`}
                                    alt="BPOM"
                                    onClick={() => handleBadgeMobile(index + 1)}
                                  />
                                  <AnimatePresence initial={false}>
                                    {badgeMobile === index + 1 && (
                                      <motion.p
                                        className="text-xs text-center whitespace-nowrap"
                                        variants={badgeMobileVariants}
                                        initial="collapsed"
                                        animate="open"
                                        exit="collapsed"
                                      >
                                        {icon.text}
                                      </motion.p>
                                    )}
                                  </AnimatePresence>
                                </motion.div>
                              );
                            })}

                          {product.badge !== null && (
                            <>
                              {product.badge.map((bdg, bdgIndex) => {
                                return (
                                  <motion.div
                                    key={bdgIndex}
                                    className="w-3/12 flex flex-col space-y-4 items-center"
                                    animate={
                                      badgeMobile === bdgIndex + 3 ||
                                      badgeMobile === 0
                                        ? {
                                            zIndex: 0,
                                            width: "25%",
                                            transition: {
                                              duration: 0.3,
                                              delay: 0.4,
                                            },
                                          }
                                        : { zIndex: -200, width: 0 }
                                    }
                                    transition={{ duration: 0.3 }}
                                  >
                                    <img
                                      className="w-14 h-14"
                                      src={`${assetsUrl}/product/${product.slug}/${bdg.badge_img}`}
                                      alt={bdg.badge_name}
                                      onClick={() =>
                                        handleBadgeMobile(bdgIndex + 3)
                                      }
                                    />
                                    {bdg.badge_description !== "" && (
                                      <AnimatePresence initial={false}>
                                        {badgeMobile === bdgIndex + 3 && (
                                          <motion.div
                                            className="text-xs text-center font-nunito-sans whitespace-pre"
                                            variants={badgeMobileVariants}
                                            initial="collapsed"
                                            animate="open"
                                            exit="collapsed"
                                            dangerouslySetInnerHTML={{
                                              __html: bdg.badge_description,
                                            }}
                                          ></motion.div>
                                        )}
                                      </AnimatePresence>
                                    )}
                                  </motion.div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ForYourInfo>
                <div dangerouslySetInnerHTML={{ __html: product.fyi }}></div>
              </ForYourInfo>

              <div className="productDetails__banner w-full">
                <img
                  className="hidden sm:block w-full h-auto"
                  src={`${assetsUrl}/product/${product.slug}/${product.img_banner}`}
                  alt="Banner Product"
                />
                <img
                  className="sm:hidden block w-full h-auto"
                  src={`${assetsUrl}/product/${product.slug}/${product.img_banner_sm}`}
                  alt="Banner Product"
                />
              </div>

              <div className="productDetails__btn w-full flex justify-center items-center h-auto sm:h-[40vh] py-8 sm:py-0">
                <ArrowButton link="/our-product">Produk Lainnya</ArrowButton>
              </div>
            </div>
          ) : (
            <div className="w-full h-[80vh] flex flex-col space-y-2 items-center justify-center">
              <div className="h-16 flex items-center justify-center space-x-2 animate-bounce">
                <div className="w-8 h-8 bg-primary rounded-full"></div>
                <div className="w-8 h-8 bg-light-primary rounded-full"></div>
                <div className="w-8 h-8 bg-theme-blue rounded-full"></div>
              </div>
              {product === null && isNotfound && (
                <div className="flex items-center justify-center">
                  <div className="flex flex-col justify-center items-center space-y-5 font-nunito-sans">
                    <p className="text-2.1xl text-primary font-bold">
                      Maaf, halaman ini tidak bisa kami temukan.
                    </p>
                    <Link to="/">
                      <ButtonSimple>Kembali ke Home</ButtonSimple>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </main>
      </Layout>
    </>
  );
}

export default ProductDetails;
