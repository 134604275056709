import React, { Fragment, useContext, useEffect, useState } from "react";
import "./ArticleDetails.scss";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";

import Layout from "../../components/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import mainContext from "../../context/MainContext";
import Badge from "../../components/Badge";
import { CgFacebook } from "react-icons/cg";
import { AiOutlineTwitter } from "react-icons/ai";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import unslugify from "unslugify";
import MetaKeywords from "../../components/MetaKeywords";
import axios from "axios";

function ArticleDetails() {
  const {
    getArticleDetails,
    getSideArticle,
    getAdsArticle,
    assetsUrl2,
    getMeta,
  } = useContext(mainContext);
  const { slug, category } = useParams();
  const currentUrl = window.location.href;
  const location = useLocation();

  const [isCallData, setCallData] = useState(true);
  const [data, setData] = useState(null);
  const [dataSide, setDataSide] = useState(null);
  const [isHovered, setHovered] = useState(false);
  const [ads, setAds] = useState(null);

  const [dataMeta, setDataMeta] = useState(null);
  const [meta, setMeta] = useState(null);
  const [canonicalUrl, setCanonicalUrl] = useState("");

  const articleLinks = [
    {
      page: "Home",
      link: "/",
    },
    {
      page: "Article",
      link: "/article",
    },
    {
      page: data?.title || "...",
      link: `/article/${data?.id}`,
    },
  ];

  const articleByCatLinks = [
    {
      page: "Home",
      link: "/",
    },
    {
      page: "Article",
      link: "/article",
    },
    {
      page: unslugify(category || "") || "...",
      link: `/article/c/${category}`,
    },
    {
      page: data?.title || "...",
      link: `/article/c/${category}/${data?.id}`,
    },
  ];

  useEffect(() => {
    const getMetaArticle = async () => {
      await axios
        .get(`https://dhcsupplement.co.id/meta.php`)
        .then((res) => {
          // Parse the response to extract canonical URL
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(res.data, "text/html");
          const linkElement = htmlDoc.querySelector('link[rel="canonical"]');

          if (linkElement) {
            setCanonicalUrl(linkElement.getAttribute("href"));
          }

          // setData(res.data);
        })
        .catch((err) => {
          setData({
            status: "error",
            msg: err?.message || "Data tidak ditemukan",
          });
        });
    };

    if (isCallData) {
      setCallData(false);

      if (canonicalUrl === "") {
        getMetaArticle();
      }

      if (data === null) {
        getArticleDetails(setData, { slug });
      }
      if (dataSide === null && data !== null && data !== "error") {
        let catList = [];
        data?.category?.map((item) => {
          catList = [...catList, item?.title];
          return catList;
        });
        getSideArticle(setDataSide, { category: catList || [] });
      }
      if (ads === null) {
        getAdsArticle(setAds);
      }

      if (dataMeta === null) {
        getMeta(setDataMeta);
      }
    }

    if (dataMeta !== null && dataMeta?.status !== "error" && meta === null) {
      if (dataMeta?.find((item) => item?.slug === location?.pathname)) {
        const currMeta = dataMeta?.find(
          (item) => item?.slug === location?.pathname
        );

        setMeta(currMeta);
      } else {
        setMeta({
          slug: location?.pathname,
          title: "DHC Supplement",
          keyword:
            "suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen",
          description:
            "DHC Supplement Made in Japan. Loved Worldwide produk berkualitas tinggi dan inovatif dari jepang yang dicintai di seluruh penjuru dunia",
        });
      }
    }
  }, [
    ads,
    canonicalUrl,
    data,
    dataMeta,
    dataSide,
    getAdsArticle,
    getArticleDetails,
    getMeta,
    getSideArticle,
    isCallData,
    location?.pathname,
    meta,
    slug,
  ]);

  return (
    <>
      {/* <Helmet>
        <link
          rel="canonical"
          href={`https://dhcsupplement.co.id/article/${data?.id}`}
        />
        <title>{data?.title || "Artikel"} - DHC Supplement</title>
        <meta
          name="description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          name="keywords"
          content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
        />
        <meta property="og:title" content="About Us - DHC Supplement" />
        <meta
          property="og:description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          property="og:url"
          content={`https://dhcsupplement.co.id/article/${data?.id}`}
        />
      </Helmet> */}

      <MetaKeywords
        slug={meta?.slug || data?.id}
        path={location?.pathname}
        keyword={meta?.keyword}
        description={meta?.description}
        title={meta?.title || data?.title}
      />

      <Layout activeNav="article">
        <main className="article layout-gap">
          <Breadcrumb links={category ? articleByCatLinks : articleLinks} />

          <div className="article__list py-8 sm:py-12 font-nunito-sans">
            {data === null || data?.status === "error" ? (
              <div className="w-10/12 flex flex-col mx-auto">
                <h5 className="w-full px-3 text-primary text-center text-base sm:text-lg xl:text-xl font-bold">
                  {data?.msg || "Data tidak ditemukan"}
                </h5>
              </div>
            ) : (
              <div className="w-full">
                {ads === null || ads?.status === "error" ? (
                  ""
                ) : (
                  <>
                    {ads
                      ?.filter((i) => i.posisi === "top")
                      ?.map((ad, adIdx) => {
                        return (
                          <Fragment key={adIdx}>
                            {ad?.banner && (
                              <div className="article__ads__top w-full lg:w-10/12 h-auto hidden lg:flex mx-auto -m-2 pb-6 sm:pb-8 px-5 lg:px-0">
                                <a
                                  className="w-full"
                                  href={ad?.link}
                                  target="__blank"
                                  referrerPolicy="no-referrer"
                                >
                                  <img
                                    className="w-full h-auto object-fill"
                                    src={`${assetsUrl2}/${ad?.banner}`}
                                    alt={ad?.judul}
                                  />
                                </a>
                              </div>
                            )}
                            {ad?.mobileBanner && (
                              <div className="article__ads__top w-full lg:w-10/12 h-auto flex lg:hidden mx-auto -m-2 pb-6 sm:pb-8 px-5 lg:px-0">
                                <a
                                  className="w-full"
                                  href={ad?.link}
                                  target="__blank"
                                  referrerPolicy="no-referrer"
                                >
                                  <img
                                    className="w-full h-auto block sm:hidden object-fill"
                                    src={`${assetsUrl2}/${ad?.mobileBanner}`}
                                    alt={ad?.judul}
                                  />
                                </a>
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                  </>
                )}

                <div className="w-full lg:w-10/12 flex flex-wrap mx-auto px-5 lg:px-0 space-y-8 lg:space-y-0">
                  <main className="w-full lg:w-8/12 flex flex-col space-y-5 pr-0 lg:pr-4">
                    <h1 className="w-full text-primary text-2xl sm:text-3xl xl:text-3.1xl font-bold">
                      {data?.title}
                    </h1>
                    <div className="w-full flex flex-wrap flex-col md:flex-row items-start md:items-center space-x-0 space-y-3 md:space-x-3 md:space-y-0">
                      <div className="flex space-x-2 text-xxs">
                        {data?.category?.map((item, cateIndex) => {
                          return (
                            <Link
                              key={`cats-${cateIndex}`}
                              to={`/article/c/${item?.slug}`}
                            >
                              <Badge>{item?.title}</Badge>
                            </Link>
                          );
                        })}
                      </div>

                      <p className="text-xxs lg:text-sm">
                        Ditinjau oleh{" "}
                        <b className="font-semibold">{data?.creator}</b>,{" "}
                        {moment(data?.date).format("LL")}
                      </p>
                    </div>
                    <div className="w-full flex text-base">
                      <p
                        className="article__content"
                        dangerouslySetInnerHTML={{ __html: data?.content }}
                      ></p>
                    </div>

                    {/* Share Section */}
                    <div className="w-full flex justify-center lg:justify-start items-center space-x-4 lg:space-x-5 pt-3 lg:pt-0">
                      <p className="text-primary text-base font-bold lg:font-semibold">
                        Yuk share artikel ini{" "}
                      </p>
                      <span className="h-[80%] border border-x-primary"></span>
                      <div className="w-auto flex space-x-2">
                        <FacebookShareButton url={currentUrl}>
                          <div
                            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-transparent rounded-full overflow-hidden"
                            onMouseEnter={() => setHovered(1)}
                            onMouseLeave={() => setHovered(false)}
                          >
                            <motion.div
                              className="w-full h-full flex justify-center items-center z-[1]"
                              animate={
                                isHovered === 1
                                  ? { rotateY: 360 }
                                  : { rotateY: 0 }
                              }
                              transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                              <CgFacebook
                                className={`w-[75%] h-auto bg-transparent ${
                                  isHovered === 1
                                    ? "text-white"
                                    : "text-primary"
                                } transition-all duration-200 ease-out`}
                              />
                            </motion.div>
                            <motion.div
                              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#395693] rounded-full absolute top-0"
                              initial={{ scale: 0 }}
                              animate={
                                isHovered === 1 ? { scale: 0.9 } : { scale: 0 }
                              }
                              transition={{ ease: "easeOut", duration: 0.5 }}
                            ></motion.div>
                          </div>
                        </FacebookShareButton>

                        <TwitterShareButton url={currentUrl}>
                          <div
                            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-transparent rounded-full overflow-hidden"
                            onMouseEnter={() => setHovered(2)}
                            onMouseLeave={() => setHovered(false)}
                          >
                            <motion.div
                              className="w-full h-full flex justify-center items-center z-[1]"
                              animate={
                                isHovered === 2
                                  ? { rotateY: 360 }
                                  : { rotateY: 0 }
                              }
                              transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                              <AiOutlineTwitter
                                className={`w-[75%] h-auto bg-transparent ${
                                  isHovered === 2
                                    ? "text-white"
                                    : "text-primary"
                                } transition-all duration-200 ease-out`}
                              />
                            </motion.div>
                            <motion.div
                              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#55ACEE] rounded-full absolute top-0"
                              initial={{ scale: 0 }}
                              animate={
                                isHovered === 2 ? { scale: 0.9 } : { scale: 0 }
                              }
                              transition={{ ease: "easeOut", duration: 0.5 }}
                            ></motion.div>
                          </div>
                        </TwitterShareButton>

                        <WhatsappShareButton url={currentUrl}>
                          <div
                            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-transparent rounded-full overflow-hidden"
                            onMouseEnter={() => setHovered(3)}
                            onMouseLeave={() => setHovered(false)}
                          >
                            <motion.div
                              className="w-full h-full flex justify-center items-center z-[1]"
                              animate={
                                isHovered === 3
                                  ? { rotateY: 360 }
                                  : { rotateY: 0 }
                              }
                              transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                              <FaWhatsapp
                                className={`w-[75%] h-auto bg-transparent ${
                                  isHovered === 3
                                    ? "text-white"
                                    : "text-primary"
                                } transition-all duration-200 ease-out`}
                              />
                            </motion.div>
                            <motion.div
                              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#26D366] rounded-full absolute top-0"
                              initial={{ scale: 0 }}
                              animate={
                                isHovered === 3 ? { scale: 0.92 } : { scale: 0 }
                              }
                              transition={{ ease: "easeOut", duration: 0.5 }}
                            ></motion.div>
                          </div>
                        </WhatsappShareButton>
                      </div>
                    </div>
                  </main>

                  {/* Sidebar Article */}
                  <aside className="w-full lg:w-4/12 flex flex-col space-y-5 pl-0 lg:pl-4">
                    <div className="w-full flex flex-col space-y-6">
                      <p className="text-xl font-semibold">Artikel Terkait</p>

                      <div className="w-full flex flex-col space-y-4">
                        {dataSide !== null &&
                          dataSide?.status !== "error" &&
                          dataSide?.map((item, sideIdx) => {
                            return (
                              <div
                                key={`dataSide-${sideIdx}`}
                                className="w-full flex space-x-3"
                              >
                                <Link
                                  to={`/article/${item?.slug}`}
                                  className="h-[80px] w-auto shrink-0 rounded-md cursor-pointer"
                                  onClick={() => {
                                    setMeta(null);
                                    getArticleDetails(setData, {
                                      slug: item?.slug,
                                    });
                                  }}
                                >
                                  <img
                                    src={`${assetsUrl2}/${item?.thumbnail}`}
                                    alt={`${item?.title}`}
                                    className="w-auto h-full rounded-md"
                                  />
                                </Link>

                                <div className="w-full flex flex-col space-y-3">
                                  <Link
                                    to={`/article/${item?.slug}`}
                                    className="h-10 text-sm line-clamp-2 font-semibold cursor-pointer"
                                    onClick={() => {
                                      setMeta(null);
                                      getArticleDetails(setData, {
                                        slug: item?.slug,
                                      });
                                    }}
                                  >
                                    {item?.title}
                                  </Link>

                                  <div className="flex flex-wrap items-center space-x-0 text-xxs">
                                    {item?.category?.map((cat, catIndex) => {
                                      return (
                                        <Fragment key={`cat-${catIndex}`}>
                                          {data?.category?.map((i, idx) => {
                                            return (
                                              i.title === cat.title && (
                                                <Link
                                                  to={`/article/c/${i?.slug}`}
                                                  key={`subCat-${idx}`}
                                                >
                                                  <Badge
                                                    className={
                                                      "shrink-0 !whitespace-pre-nowrap mb-2 mr-2"
                                                    }
                                                  >
                                                    {cat?.title}
                                                  </Badge>
                                                </Link>
                                              )
                                            );
                                          })}
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="article__ads__side">
                        {ads === null || ads?.status === "error" ? (
                          ""
                        ) : (
                          <div className="w-full h-auto flex flex-col space-y-5">
                            {ads
                              ?.filter((i) => i.posisi === "side")
                              ?.map((ad, adIdx) => {
                                return (
                                  <a
                                    key={`sideAds-${adIdx}`}
                                    href={ad?.link}
                                    target="__blank"
                                    referrerPolicy="no-referrer"
                                  >
                                    <img
                                      className="w-full h-auto object-fill rounded-md"
                                      src={`${assetsUrl2}/${ad?.banner}`}
                                      alt={ad?.judul}
                                    />
                                  </a>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            )}
          </div>
        </main>
      </Layout>
    </>
  );
}

export default ArticleDetails;
