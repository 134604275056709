import React, { useRef } from "react";
import "./AboutUs.scss";

import { useInView } from "framer-motion";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb";
import { Helmet } from "react-helmet-async";

const aboutUsLinks = [
  {
    page: "Home",
    link: "/",
  },
  {
    page: "About Us",
    link: "/about-us",
  },
];

const animation = {
  anim1: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateX(-100px)",
      WebkitTransform: isInView ? "none" : "translateX(-100px)",
      MozTransform: isInView ? "none" : "translateX(-100px)",
      msTransform: isInView ? "none" : "translateX(-100px)",
      OTransform: isInView ? "none" : "translateX(-100px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.4}s`,
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateY(50px)",
      WebkitTransform: isInView ? "none" : "translateY(50px)",
      MozTransform: isInView ? "none" : "translateY(50px)",
      msTransform: isInView ? "none" : "translateY(50px)",
      OTransform: isInView ? "none" : "translateY(50px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.4}s`,
    };
    return style;
  },
};

function AboutUs() {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const isInView = useInView(ref, { once: true });
  const isInView2 = useInView(ref2, { once: true });

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://dhcsupplement.co.id/about-us" />
        <title>About Us - DHC Supplement</title>
        <meta
          name="description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          name="keywords"
          content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
        />
        <meta property="og:title" content="About Us - DHC Supplement" />
        <meta
          property="og:description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          property="og:url"
          content="https://dhcsupplement.co.id/about-us"
        />
      </Helmet>

      <Layout activeNav="about-us">
        <main className="aboutUs layout-gap">
          <Breadcrumb links={aboutUsLinks} />

          <div className="aboutUs__desc pt-10 sm:pt-12">
            <div
              ref={ref}
              className="aboutUs__desc-box w-full bg-white px-2 sm:px-[4.3vw] xl:px-[5vw] py-[4vw]"
            >
              <img
                className="w-4/12 mx-auto mb-3 sm:mx-0 sm:w-1/12 sm:mb-4"
                src="/images/universal/logo-primary.png"
                alt="Logo"
                style={animation.anim1(isInView, 0)}
              />

              <div className="w-full flex flex-col space-y-9 px-0 sm:px-3 py-8 text-primary font-nunito-sans">
                <div className="flex flex-col space-y-5 sm:space-y-8">
                  <h5
                    className="text-xl font-semibold bg-transparent sm:text-3xl xl:text-3.1xl border-l-[6px] text-primary border-primary py-1 px-3 sm:py-0 sm:px-4 sm:font-bold"
                    style={animation.anim1(isInView, 0.3)}
                  >
                    DHC Corporation
                  </h5>
                  <p
                    className="text-base xl:text-lg text-graylight text-justify px-5"
                    style={animation.anim1(isInView, 0.5)}
                  >
                    DHC Corporation adalah perusahaan dari{" "}
                    <strong>Jepang</strong> yang berdiri dari tahun 1972, dengan
                    fokus di berbagai jenis lini bisnis. DHC Corporation juga
                    merupakan perusahaan <strong>suplemen</strong> kesehatan
                    terbesar di Jepang, dimana produknya sudah beredar di 28
                    negara, dengan posisi terdepan di <strong>Jepang</strong>.
                    Suplemen Kesehatan DHC menawarkan kualitas, kandungan dan
                    keamanan produk yang tidak tertandingi untuk mendukung hidup
                    sehat sehari-hari. Ide-ide inovatif dan produk-produk
                    berkualitas merupakan kekuatan pendorong utama DHC untuk
                    terus tumbuh dan berkembang.
                  </p>
                </div>

                <div className="flex flex-col space-y-5 sm:space-y-8">
                  <h5
                    className="text-xl font-semibold bg-transparent sm:text-3xl xl:text-3.1xl border-l-[6px] text-primary border-primary py-1 px-3 sm:py-0 sm:px-4 sm:font-bold"
                    style={animation.anim1(isInView, 0.9)}
                  >
                    Suplemen Kesehatan DHC Indonesia
                  </h5>
                  <p
                    className="text-base xl:text-lg text-graylight text-justify px-5"
                    style={animation.anim1(isInView, 1.1)}
                  >
                    Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan
                    memberikan yang terbaik dari <strong>Jepang</strong>,{" "}
                    <strong>Suplemen</strong> Kesehatan DHC hadir di Indonesia
                    dengan kemasan berbahasa Indonesia dan tentu saja telah
                    melalui kajian dan sesuai dengan peraturan BPOM. Kami
                    berkomitmen untuk terus memberikan produk yang terbaik,
                    inovatif, aman dan berkualitas tinggi. Semua{" "}
                    <strong>Suplemen</strong> Kesehatan DHC dengan kemasan
                    Bahasa Indonesia adalah varian produk yang lulus kajian dan
                    sesuai dengan peraturan BPOM.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="aboutUs__vm w-full py-6 px-2 mt-10 sm:mt-0 sm:px-[3.8vw] xl:px-[4.5vw] sm:py-20 flex flex-col sm:flex-row flex-wrap items-start">
            <h5
              ref={ref2}
              className="w-full ml-0 py-1 px-3 font-semibold sm:py-0 sm:ml-[1.5vw] text-xl sm:text-3xl xl:text-3.1xl border-l-[6px] border-white text-white sm:px-4 leading-none sm:font-bold font-nunito-sans mb-8 sm:mb-12"
              style={animation.anim1(isInView2, 0)}
            >
              Produk DHC Adalah
            </h5>

            <div className="aboutUs__vm-scroll-mobile w-full flex flex-row flex-nowrap pb-4 sm:pb-0 sm:flex-wrap">
              <div className="aboutUs__vm-item w-[95%] shrink-0 sm:shrink sm:w-4/12 px-3">
                <div
                  className="aboutUs__vm-item-box w-full flex flex-col items-center space-y-6 bg-white text-white p-5 xl:p-6 rounded-[10px]"
                  style={animation.anim2(isInView2, 0.3)}
                >
                  <div className="w-40 h-40 sm:w-52 sm:h-52 p-8 rounded-full bg-primary flex items-center justify-center">
                    <h5 className="font-nunito-sans text-2.1xl sm:text-3.1xl xl:text-3.2xl font-bold text-center leading-tight">
                      Kualitas Terbaik
                    </h5>
                  </div>

                  <h4 className="text-lg sm:text-1xl xl:text-2.1xl text-primary font-nunito-sans font-semibold leading-tight text-center">
                    Memberikan produk Paling Aman dan Berkualitas Tinggi
                  </h4>

                  <p className="text-base xl:text-lg text-graylight text-center sm:text-justify font-nunito-sans">
                    Kami melakukan pemeriksaan berulang dan ketat mulai tahap
                    bahan baku, proses produksi sampai ke produk jadi.
                  </p>

                  <p className="text-base xl:text-lg text-graylight text-center sm:text-justify font-nunito-sans">
                    Kami juga terus berupaya untuk meningkatkan kualitas
                    formulasi produk agar dapat menghasilkan dan memberikan
                    produk yang paling efektif di pasar. Sehingga kami dapat
                    memastikan bahwa produk kami dapat memberikan kualitas
                    tertinggi.
                  </p>
                </div>
              </div>

              <div className="aboutUs__vm-item w-[95%] shrink-0 sm:shrink sm:w-4/12 px-3">
                <div
                  className="aboutUs__vm-item-box w-full flex flex-col items-center space-y-6 bg-white text-white p-5 xl:p-6 rounded-[10px]"
                  style={animation.anim2(isInView2, 0.7)}
                >
                  <div className="w-40 h-40 sm:w-52 sm:h-52 p-8 rounded-full bg-primary flex items-center justify-center">
                    <h5 className="font-nunito-sans text-2.1xl sm:text-3.1xl xl:text-3.2xl font-bold text-center leading-tight">
                      Keamanan
                    </h5>
                  </div>

                  <h4 className="text-lg sm:text-1xl xl:text-2.1xl text-primary font-nunito-sans font-semibold leading-tight text-center">
                    Standar keamanan
                    <br />
                    yang ketat.
                  </h4>

                  <p className="text-base xl:text-lg text-graylight text-center sm:text-justify font-nunito-sans">
                    DHC telah menetapkan [DHC Safety Standards], yang melarang
                    penggunaan bahan baku dari sumber yang belum diverifikasi
                    keamanannya. Standar ini memastikan DHC hanya menggunakan
                    bahan baku yang dipilih dengan teliti.
                  </p>

                  <p className="text-base xl:text-lg text-graylight text-center sm:text-justify font-nunito-sans">
                    Untuk menjaga keamanan produk, semua produk DHC hanya
                    diproduksi di <strong>Jepang</strong>, di pabrik yang
                    memenuhi standar GMP (Good Manufacturing Practice). Semua
                    itu kami lakukan agar produk DHC aman dikonsumsi untuk
                    menjaga kesehatan.
                  </p>
                </div>
              </div>

              <div className="aboutUs__vm-item w-[95%] shrink-0 sm:shrink sm:w-4/12 px-3">
                <div
                  className="aboutUs__vm-item-box w-full flex flex-col items-center space-y-7 sm:space-y-6 bg-white text-white p-5 xl:p-6 rounded-[10px]"
                  style={animation.anim2(isInView2, 1.1)}
                >
                  <div className="w-40 h-40 sm:w-52 sm:h-52 p-8 rounded-full bg-primary flex items-center justify-center">
                    <h5 className="font-nunito-sans text-2.1xl sm:text-3.1xl xl:text-3.2xl font-bold text-center leading-tight">
                      Inovatif
                    </h5>
                  </div>

                  <h4 className="text-lg sm:text-1xl xl:text-2.1xl text-primary font-nunito-sans font-semibold leading-tight text-center">
                    Beragam varian
                    <br />
                    <strong>Suplemen</strong> Kesehatan.
                  </h4>

                  <p className="text-base xl:text-lg text-graylight text-center sm:text-justify font-nunito-sans">
                    <strong>Suplemen</strong> Kesehatan DHC terus berinovasi
                    untuk membantu kebutuhan kesehatan masyarakat yang terus
                    meningkat, dengan fokus kepada kebutuhan masyarakat dan
                    memberikan berbagai varian produk agar pelanggan dapat
                    memilih produk yang sesuai dengan kebutuhan dan gaya hidup
                    masing-masing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

export default AboutUs;
