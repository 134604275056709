import { createContext, useEffect, useState } from "react";
import { screenLaptopXs, screenMobile } from "../utils/Breakpoints";

const motionContext = createContext({
  rotateLeft: 0,
  rotateX1Left: 0,
  rotateX2Left: 0,
  rotateRight: 0,
  rotateX1Right: 0,
  rotateX2Right: 0,
  setRotateLeft: () => {},
  setRotateX1Left: () => {},
  setRotateX2Left: () => {},
  setRotateRight: () => {},
  setRotateX1Right: () => {},
  setRotateX2Right: () => {},
});

export const MotionContextProvider = ({ children }) => {
  // Ramadhan Theme
  const [rotateLeft, setRotateLeft] = useState(0);
  const [rotateX1Left, setRotateX1Left] = useState(0);
  const [rotateX2Left, setRotateX2Left] = useState(0);
  const [rotateRight, setRotateRight] = useState(0);
  const [rotateX1Right, setRotateX1Right] = useState(0);
  const [rotateX2Right, setRotateX2Right] = useState(0);

  useEffect(() => {
    if (window.innerWidth >= screenLaptopXs) {
      setRotateLeft(7);
      setRotateX1Left(7);
      setRotateX2Left(14);

      setRotateRight(7);
      setRotateX1Right(9);
      setRotateX2Right(16);
    } else if (window.innerWidth >= screenMobile) {
      setRotateLeft(3);
      setRotateX1Left(3);
      setRotateX2Left(6);

      setRotateRight(3);
      setRotateX1Right(4.5);
      setRotateX2Right(9);
    } else {
      setRotateLeft(3);
      setRotateX1Left(3);
      setRotateX2Left(6);

      setRotateRight(3);
      setRotateX1Right(4.5);
      setRotateX2Right(9);
    }
  }, []);

  return (
    <motionContext.Provider
      value={{
        rotateLeft,
        rotateX1Left,
        rotateX2Left,
        rotateRight,
        rotateX1Right,
        rotateX2Right,
        setRotateLeft,
        setRotateX1Left,
        setRotateX2Left,
        setRotateRight,
        setRotateX1Right,
        setRotateX2Right,
      }}
    >
      {children}
    </motionContext.Provider>
  );
};

export default motionContext;
