import { useEffect, useState } from 'react';

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const toastConfig = {
  position: 'top-center',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'light',
  hideProgressBar: true,
};

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState('up');

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const descendingSort = (data) => {
  let newData = [...data];
  
  return newData.sort((a, b) => b.id - a.id);
};

export { isValidEmail, toastConfig, useScrollDirection, descendingSort };
