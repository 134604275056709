import React, { useState } from 'react';
import './Button.scss';
import {
  HiArrowRight,
  HiOutlineCheckCircle,
  HiOutlineXCircle,
} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MdSend } from 'react-icons/md';
import {
  sendBtnVariants,
  subsBtnVariants,
  textSendBtnVariants,
  textSubsBtnVariants,
} from '../utils/Variants';

const arrowBtnVariants = {
  initial: {
    x: 0,
  },
  hover: {
    x: '0.5rem',
  },
};

function ButtonSimple({ children, className, type }) {
  return (
    <button
      type={type ? type : 'button'}
      className={`border border-graylight px-10 py-2 text-base xl:text-xl text-graylight font-semibold font-nunito-sans rounded-full hover-gradient-primary transition-all ease-in duration-300 ${
        className ? className : ''
      }`}
    >
      {children}
    </button>
  );
}

function DetailsButton({ children, color, link }) {
  const [isHover, setHover] = useState(false);
  return (
    <div className={`detailsBtn border-y-[3px] sm:border-y-4 border-white `}>
      <Link
        to={`/our-product/${link}`}
        className={`text-base sm:text-xl xl:text-2xl font-semibold text-white hover:text-[${color}`}
        style={{ color: isHover ? color : '#ffffff' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </Link>
    </div>
  );
}

function BuyNowButton({ children, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`buyNowBtn border border-graylight px-8 sm:px-10 py-2 text-base sm:text-xl xl:text-2xl text-graylight font-semibold font-nunito-sans rounded-full hover-gradient-primary transition-all ease-in duration-300`}
    >
      {children}
    </button>
  );
}

function ArrowButton({ children, link }) {
  const [isHovered, setHovered] = useState(false);

  return (
    <Link
      to={link}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="arrowBtn flex items-center space-x-8 sm:space-x-10 py-2 px-5 sm:px-6 font-nunito-sans rounded-full"
    >
      <p className="text-base sm:text-xl xl:text-2xl">{children}</p>
      <motion.div
        variants={arrowBtnVariants}
        initial={{ x: 0 }}
        animate={isHovered ? 'hover' : 'initial'}
      >
        <HiArrowRight className="w-5 h-5 sm:w-6 sm:h-6 xl:w-7 xl:h-7" />
      </motion.div>
    </Link>
  );
}

function SendButton({ children, className, type, status }) {
  return (
    <motion.button
      type={type ? type : 'button'}
      className={`flex justify-center items-center relative border border-graylight px-10 py-2 text-base xl:text-xl font-semibold font-nunito-sans rounded-full overflow-hidden hover-gradient-primary transition-all ease-in duration-300 ${
        className ? className : ''
      } ${
        status === 'Berhasil Terkirim'
          ? 'text-green-400'
          : status === 'Gagal Terkirim'
          ? 'text-red-500'
          : 'text-graylight'
      }`}
      disabled={
        status === 'Berhasil Terkirim' || status === 'Gagal Terkirim'
          ? true
          : false
      }
    >
      <motion.div
        className={`absolute bottom-1/4 -left-6`}
        variants={sendBtnVariants}
        animate={status}
      >
        <MdSend className="w-6 h-6" />
      </motion.div>
      <motion.div
        className={`pr-2`}
        variants={textSendBtnVariants}
        animate={status + 'Icon'}
      >
        {status === 'Gagal Terkirim' ? (
          <HiOutlineXCircle className="w-7 h-7" />
        ) : (
          <HiOutlineCheckCircle className="w-7 h-7" />
        )}
      </motion.div>
      <motion.p variants={textSendBtnVariants} animate={status}>
        {children}
      </motion.p>
    </motion.button>
  );
}

function SubscribeButton({ children, status }) {
  return (
    <button
      type="submit"
      className="subs-btn w-[150px] text-center bg-transparent border-2 border-grayborder text-graylight text-sm  font-bold relative overflow-hidden hover-gradient-primary transition-all duration-300 ease-out"
      disabled={status === 'Subscribe' ? false : true}
    >
      <motion.div
        className={`absolute bottom-[27%] -left-6`}
        variants={subsBtnVariants}
        animate={status}
      >
        <MdSend className="w-5 h-5" />
      </motion.div>
      <motion.p variants={textSubsBtnVariants} animate={status}>
        {children}
      </motion.p>
    </button>
  );
}

export {
  ButtonSimple,
  DetailsButton,
  BuyNowButton,
  ArrowButton,
  SendButton,
  SubscribeButton,
};
