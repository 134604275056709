export const variantsStars1 = {
  move1: {
    width: '1.4vw',
    x: '-8.5vw',
    y: '25vh',
    transition: {
      duration: 0.75,
    },
  },
  move2: {
    width: '2.2vw',
    x: '-23vw',
    y: '-34vh',
    transition: {
      duration: 0.75,
    },
  },
  move3: {
    width: '1.6vw',
    x: '-20.3vw',
    y: '-32vh',
    transition: {
      duration: 0.75,
    },
  },
  move4: {
    width: '1.6vw',
    x: '10vw',
    y: '-25.3vh',
    transition: {
      duration: 0.75,
    },
  },
  move5: {
    width: '2vw',
    x: '16.5vw',
    y: '-22vh',
    transition: {
      duration: 0.75,
    },
  },
  move6: {
    width: '1.8vw',
    x: '-13vw',
    y: '-35vh',
    transition: {
      duration: 0.75,
    },
  },
  // move7: {
  //   width: '1.4vw',
  //   x: '-11.5vw',
  //   y: '23vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
  // move8: {
  //   width: '2.2vw',
  //   x: '-13.5vw',
  //   y: '-25vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
};

export const variantsStars2 = {
  move1: {
    width: '2.2vw',
    x: '-18vw',
    y: '-34vh',
    transition: {
      duration: 0.75,
    },
  },
  move2: {
    width: '2.2vw',
    x: '0',
    y: '0',
    transition: {
      duration: 0.75,
    },
  },
  move3: {
    width: '2.2vw',
    x: '-21.6vw',
    y: '-35.8vh',
    transition: {
      duration: 0.75,
    },
  },
  move4: {
    width: '1.6vw',
    x: '18.5vw',
    y: '29vh',
    transition: {
      duration: 0.75,
    },
  },
  move5: {
    width: '2.2vw',
    x: '-7vw',
    y: '28vh',
    transition: {
      duration: 0.75,
    },
  },
  move6: {
    width: '1.6vw',
    x: '15vw',
    y: '33vh',
    transition: {
      duration: 0.75,
    },
  },
  // move7: {
  //   width: '2.2vw',
  //   x: '-12vw',
  //   y: '-28vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
  // move8: {
  //   width: '2vw',
  //   x: '26.5vw',
  //   y: '23vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
};

export const variantsStars3 = {
  move1: {
    width: '2.2vw',
    x: '6vw',
    y: '-25vh',
    transition: {
      duration: 0.75,
    },
  },
  move2: {
    width: '2.2vw',
    x: '10.3vw',
    y: '-23vh',
    transition: {
      duration: 0.75,
    },
  },
  move3: {
    width: '1vw',
    x: '-10vw',
    y: '25.5vh',
    transition: {
      duration: 0.75,
    },
  },
  move4: {
    width: '2.2vw',
    x: '6vw',
    y: '-29vh',
    transition: {
      duration: 0.75,
    },
  },
  move5: {
    width: '1.6vw',
    x: '14vw',
    y: '26vh',
    transition: {
      duration: 0.75,
    },
  },
  move6: {
    width: '1.6vw',
    x: '21vw',
    y: '-30vh',
    transition: {
      duration: 0.75,
    },
  },
  // move7: {
  //   width: '1.6vw',
  //   x: '17.5vw',
  //   y: '33vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
  // move8: {
  //   width: '2.6vw',
  //   x: '13vw',
  //   y: '-29vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
};

export const variantsStars4 = {
  move1: {
    width: '2.2vw',
    x: '19vw',
    y: '-36vh',
    transition: {
      duration: 0.75,
    },
  },
  move2: {
    width: '2.2vw',
    x: '19.3vw',
    y: '25vh',
    transition: {
      duration: 0.75,
    },
  },
  move3: {
    width: '1vw',
    x: '13.5vw',
    y: '24vh',
    transition: {
      duration: 0.75,
    },
  },
  move4: {
    width: '2vw',
    x: '-24.5vw',
    y: '-26.5vh',
    transition: {
      duration: 0.75,
    },
  },
  move5: {
    width: '1.2vw',
    x: '13vw',
    y: '23.3vh',
    transition: {
      duration: 0.75,
    },
  },
  move6: {
    width: '1.6vw',
    x: '-28.7vw',
    y: '-6.5vh',
    transition: {
      duration: 0.75,
    },
  },
  // move7: {
  //   width: '2.2vw',
  //   x: '17vw',
  //   y: '30vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
  // move8: {
  //   width: '1.4vw',
  //   x: '-19.5vw',
  //   y: '-21vh',
  //   transition: {
  //     duration: 0.75,
  //   },
  // },
};
