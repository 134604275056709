import React, { useContext } from 'react';
import './BuyNowModal.scss';
import Close from './svg/Close';
import { motion } from 'framer-motion';
import mainContext from '../context/MainContext';

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: 'backOut', duration: 0.5 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: 'backOut', duration: 0.5 },
  },
};

const variants2 = {
  hidden: {
    x: '-100vw',
    transition: { ease: 'easeInOut', duration: 0.45 },
  },
  show: {
    x: 0,
    transition: { ease: 'easeInOut', duration: 0.45 },
  },
};

function BuyNowModal({
  showModal,
  toggleModal,
  name_product,
  img,
  shop_list,
  slug,
}) {
  const { assetsUrl } = useContext(mainContext);

  return (
    <motion.div
      className="buyNowModal"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={showModal ? 'show' : 'hidden'}
    >
      <motion.div
        className="bg-white max-w-full w-full sm:w-[50vw] h-full absolute left-0 top-0 flex flex-col py-4 overflow-y-auto"
        initial={{ x: '-100vw' }}
        variants={variants2}
        animate={showModal ? 'show' : 'hidden'}
      >
        <button
          onClick={toggleModal}
          className="w-9 h-9 sm:w-10 sm:h-10 2xl:w-11 2xl:h-11 absolute top-4 right-4 cursor-pointer rounded-full"
        >
          <Close className="w-full h-auto" />
        </button>
        <div className="w-full flex flex-col justify-center items-center space-y-2 sm:space-y-5 p-4 pb-20 lg:pb-6 font-nunito-sans text-primary">
          <h2 className="text-xl sm:text-2.1xl font-bold font-lora pb-2 sm:pb-0">
            DHC {name_product !== typeof undefined ? name_product : '...'}
          </h2>
          <img
            className="buyNowModal__productImg w-[46%] sm:w-[21%]"
            src={`${assetsUrl}/product/${slug}/${img}`}
            alt={name_product}
          />
          <p className="text-sm sm:text-base text-center sm:text-left font-nunito-sans pt-4 sm:pt-10 px-8 sm:px-0">
            Tersedia di Official Store E-Commerce & Apotek dibawah ini
          </p>
          <div className="w-[87%] flex flex-row flex-wrap space-y-5 items-end">
            {shop_list.map((item, index) => {
              if (item.shop_name.includes('Tiktok')) {
                return (
                  <a
                    href={item.shop_url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    className="w-6/12 sm:w-4/12 block lg:hidden px-1"
                  >
                    <div className="w-full flex items-center justify-center rounded-full border border-[#e1e1e1] hover:border-primary transition-all duration-200 ease-out px-2 py-2">
                      <img
                        className="w-auto h-5 sm:h-6 object-contain"
                        src={`${assetsUrl}/product/${slug}/${item.shop_img}`}
                        alt={`${item.shop_name} Store`}
                      />
                    </div>
                  </a>
                );
              }

              return (
                <a
                  href={item.shop_url}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className="w-6/12 sm:w-4/12 px-1"
                >
                  <div className="w-full flex items-center justify-center rounded-full border border-[#e1e1e1] hover:border-primary transition-all duration-200 ease-out px-2 py-2">
                    <img
                      className="w-auto h-5 sm:h-6 object-contain"
                      src={`${assetsUrl}/product/${slug}/${item.shop_img}`}
                      alt={`${item.shop_name} Store`}
                    />
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default BuyNowModal;
