import React, { useContext } from 'react';
import './FyiModal.scss';

import { motion } from 'framer-motion';
import mainContext from '../context/MainContext';

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: 'backOut', duration: 0.25 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: 'backOut', duration: 0.25 },
  },
};

const variants2 = {
  hidden: {
    scale: 0.6,
    transition: { ease: 'easeInOut', duration: 0.25 },
  },
  show: {
    scale: 1,
    transition: { ease: 'easeInOut', duration: 0.25 },
  },
};

function FyiModal({ product, toggleFyiModal }) {
  const { showFyiModal } = useContext(mainContext);

  return (
    <motion.div
      className="fyiModal scrollbar-custom"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={showFyiModal ? 'show' : 'hidden'}
      onClick={toggleFyiModal}
    >
      <motion.div
        className="fyiModal-card bg-white max-w-full w-[80vw] h-auto relative flex flex-col p-6 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={showFyiModal ? 'show' : 'hidden'}
      >
        <div className="relative w-full flex flex-col space-y-3 justify-center items-center text-primary font-nunito-sans">
          <h2 className="relative w-full text-xl text-primary text-center font-bold">
            Tahukah Anda?
          </h2>
          <div
            className="text-sm text-justify"
            dangerouslySetInnerHTML={{ __html: product.fyi }}
          ></div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default FyiModal;
