import React from 'react';

function BackgroundAlert({ className, status }) {
  return (
    <svg
      viewBox="0 0 228 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x={155.75}
        width={42.009}
        height={220.264}
        rx={21.005}
        transform="rotate(45 155.75 0)"
        fill={status === 'Success' ? '#39D39F' : '#FF3823'}
        fillOpacity={0.1}
      />
      <rect
        x={160.861}
        y={58.543}
        width={42.009}
        height={220.264}
        rx={21.005}
        transform="rotate(45 160.861 58.543)"
        fill={status === 'Success' ? '#39D39F' : '#FF3823'}
        fillOpacity={0.1}
      />
      <rect
        x={197.945}
        y={85.112}
        width={42.009}
        height={220.264}
        rx={21.005}
        transform="rotate(45 197.945 85.112)"
        fill={status === 'Success' ? '#39D39F' : '#FF3823'}
        fillOpacity={0.1}
      />
    </svg>
  );
}

export default BackgroundAlert;
