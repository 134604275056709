import React, { useContext, useEffect, useState } from "react";
import "./FAQPage.scss";

import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import { DoctorFaq } from "../components/svg";
import { Helmet } from "react-helmet-async";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import mainContext from "../context/MainContext";

const faqLinks = [
  {
    page: "Home",
    link: "/",
  },
  {
    page: "FAQ",
    link: "/faq",
  },
];

function FAQPage() {
  const { getFaq, assetsUrl } = useContext(mainContext);

  const [faqScroll, setFaqScroll] = useState({
    faq1: 0,
    faq2: 0,
    faq3: 0,
    faq4: 0,
  });
  const [maxScroll, setMaxScroll] = useState(null);
  const [faqScrollerSize, setFaqScrollerSize] = useState(null);
  const [data, setData] = useState(null);

  const scrollToNextPage = (id, faq) => {
    const faqScroller = document.querySelector(`.faqList-${id}`);
    const faqItemSize = faqScroller.querySelector(
      ".faqPage__list-item"
    ).clientWidth;

    faqScroller.scrollTo(faqScroller.scrollLeft + faqItemSize, 0);
  };

  const scrollToPrevPage = (id, faq) => {
    const faqScroller = document.querySelector(`.faqList-${id}`);
    const faqItemSize = faqScroller.querySelector(
      ".faqPage__list-item"
    ).clientWidth;

    faqScroller.scrollTo(faqScroller.scrollLeft - faqItemSize, 0);
  };

  const handleScroll = (e, idx) => {
    setFaqScroll({
      ...faqScroll,
      [`faq${idx + 1}`]: Math.floor(e.currentTarget.scrollLeft),
    });
  };

  useEffect(() => {
    if (data === null) {
      getFaq(setData);
    }

    if (data !== null) {
      setFaqScrollerSize(document.querySelector(`.faqPage__list`).clientWidth);

      if (maxScroll === null) {
        const allFaqLength = data.map((item, idx) => {
          let currentFaq = {
            [`faq${idx + 1}`]: item.faq_list.length,
          };
          return { ...currentFaq };
        });
        setMaxScroll(allFaqLength);
      }
    }
  }, [maxScroll, data, getFaq]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://dhcsupplement.co.id/faq" />
        <title>FAQ - DHC Supplement</title>
        <meta
          name="description"
          content="FAQ atau pertanyaan yang sering ditanyakan seputar DHC Supplement."
        />
        <meta
          name="keywords"
          content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
        />
        <meta property="og:title" content="FAQ - DHC Supplement" />
        <meta
          property="og:description"
          content="FAQ atau pertanyaan yang sering ditanyakan seputar DHC Supplement."
        />
        <meta property="og:url" content="https://dhcsupplement.co.id/faq" />
      </Helmet>

      <Layout activeNav="faq">
        <main className="faqPage layout-gap">
          <Breadcrumb links={faqLinks} />

          <div className="flex flex-col">
            <div className="flex py-2">
              <h1 className="faqPage-title text-xl sm:text-4xl xl:text-4.0xl font-bold font-nunito-sans text-primary px-5 pb-4 pt-8 sm:px-10 sm:py-11 3xl:py-12">
                FAQ Page
              </h1>
            </div>

            <div className="flex flex-col mb-10">
              {data !== null
                ? data.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="relative w-full font-nunito-sans"
                      >
                        <div className="flex w-full sm:w-3/12 my-4">
                          <div className="flex justify-center items-center shrink-0 py-4 px-5 sm:py-6 sm:px-20 bg-white text-lg sm:text-3xl xl:text-3.1xl text-primary font-bold capitalize">
                            {item.faq_type}
                          </div>
                          <div className="flex justify-center items-center py-2 px-10 rounded-r-[10px] bg-primary">
                            {item.faq_type_icon1 !== null ? (
                              <div className="w-8 sm:w-16">
                                <img
                                  src={`${assetsUrl}/faq/${item.faq_type_icon1}`}
                                  alt="FAQ Icon"
                                  className="w-full object-contain"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {idx === 0 ? (
                          <DoctorFaq className="block sm:hidden w-28 h-auto absolute -top-9 right-4 " />
                        ) : (
                          ""
                        )}
                        <div
                          className={`faqPage__list faqList-${idx} relative w-full sm:w-[90%] xl:w-[85%] flex flex-nowrap sm:flex-wrap mx-auto justify-between py-3`}
                          onScroll={(e) => handleScroll(e, idx)}
                        >
                          {item.faq_list.map((faqItem, faqIdx) => {
                            return (
                              <div
                                key={faqIdx}
                                className="faqPage__list-item w-full h-[700px] sm:w-[41vw] sm:h-[41vw] xl:w-[40.5vw] xl:h-[40.5vw] 4xl:w-[41vw] 4xl:h-[41vw] p-3 xl:p-4 my-3 shrink-0 sm:shrink"
                              >
                                {faqItem.id === 2 ? (
                                  <DoctorFaq className="hidden sm:block w-40 xl:w-44 3xl:w-48 h-auto absolute top-[-198px] xl:top-[-218px] 3xl:-top-60 right-0" />
                                ) : (
                                  ""
                                )}
                                <div className="relative w-full h-full flex flex-col p-3 xl:p-4 2xl:p-5 bg-white text-graylight scrollbar-custom">
                                  <h4 className="inline mb-1 p-2 font-bold z-10 text-justify text-lg xl:text-xl">
                                    {faqItem.id}.&nbsp;
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: faqItem.question,
                                      }}
                                    ></span>
                                  </h4>
                                  <p className="z-10 text-justify overflow-auto p-2 text-base xl:text-lg whitespace-pre-wrap">
                                    {faqItem.answer}
                                  </p>
                                  {item.faq_type_icon2 !== null ? (
                                    <div className="absolute w-[36%] h-auto top-8 right-5">
                                      <img
                                        src={`${assetsUrl}/faq/${item.faq_type_icon2}`}
                                        alt="FAQ Icon"
                                        className="w-full h-auto"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <button
                          className={`absolute block sm:hidden bottom-[42%] left-5 rounded-full p-[6px] bg-gray-200 ${
                            faqScroll[`faq${idx + 1}`] === 0
                              ? "opacity-60"
                              : "opacity-100"
                          } transition-all duration-200 ease-out z-10`}
                          onClick={() => scrollToPrevPage(idx, `faq${idx + 1}`)}
                        >
                          <VscChevronLeft
                            className={`w-6 h-6 text-primary ${
                              faqScroll[`faq${idx + 1}`] === 0
                                ? "opacity-60"
                                : "opacity-100"
                            } transition-all duration-200 ease-out`}
                          />
                        </button>
                        <button
                          className={`absolute block sm:hidden bottom-[42%] right-5 rounded-full p-[6px] bg-gray-200 ${
                            maxScroll !== null &&
                            faqScroll[`faq${idx + 1}`] >=
                              (maxScroll[idx][`faq${idx + 1}`] - 1) *
                                faqScrollerSize
                              ? "opacity-60"
                              : "opacity-100"
                          } transition-all duration-200 ease-out z-10`}
                          onClick={() => scrollToNextPage(idx, `faq${idx + 1}`)}
                        >
                          <VscChevronRight
                            className={`w-6 h-6 text-primary ${
                              maxScroll !== null &&
                              faqScroll[`faq${idx + 1}`] >=
                                (maxScroll[idx][`faq${idx + 1}`] - 1) *
                                  faqScrollerSize
                                ? "opacity-60"
                                : "opacity-100"
                            } transition-all duration-200 ease-out`}
                          />
                        </button>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

export default FAQPage;
