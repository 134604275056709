import React, { useContext } from 'react';
import './SubscribeModal.scss';

import { motion } from 'framer-motion';
import Close from './svg/Close';
import mainContext from '../context/MainContext';
import { BackgroundAlert, IconHappyFace, IconSadFace } from './svg';

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: 'backOut', duration: 0.5 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: 'backOut', duration: 0.5 },
  },
};

const variants2 = {
  hidden: {
    scale: 0.5,
    transition: { ease: 'easeInOut', duration: 0.5 },
  },
  show: {
    scale: 1,
    transition: { ease: 'easeInOut', duration: 0.5 },
  },
};

function SubscribeModal() {
  const { showSubsModal, setShowSubsModal, subsData, setSubsStatus } =
    useContext(mainContext);

  const handleBtn = () => {
    if (subsData !== null) {
      if (subsData.status === 'Success') {
        setShowSubsModal(false);
      } else {
        setShowSubsModal(false);
        setSubsStatus('Selesai.');

        setTimeout(() => {
          setSubsStatus('Subscribe');
        }, 2000);
      }
    } else {
      setShowSubsModal(false);
    }
  };

  return (
    <motion.div
      className="subscribeModal scrollbar-custom"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={showSubsModal ? 'show' : 'hidden'}
    >
      <motion.div
        className="subscribeModal-card bg-white max-w-full w-11/12 sm:w-[55vw] h-auto relative flex flex-col p-5 sm:p-8 rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={showSubsModal ? 'show' : 'hidden'}
      >
        <button
          onClick={handleBtn}
          className="w-9 h-9 sm:w-10 sm:h-10 2xl:w-11 2xl:h-11 absolute top-4 right-4 sm:top-6 sm:right-6 cursor-pointer rounded-full z-10"
        >
          <Close className="w-full h-auto" />
        </button>

        <div className="relative w-full flex flex-col space-y-5 sm:space-y-6 justify-center items-center">
          <BackgroundAlert
            className="absolute absolute-x-center top-0 w-7/12 sm:w-4/12"
            status={subsData !== null && subsData.status}
          />
          {subsData !== null && subsData.status === 'Success' ? (
            <IconHappyFace className="relative w-6/12 sm:w-4/12 mx-auto" />
          ) : (
            <IconSadFace className="relative w-6/12 sm:w-4/12 mx-auto" />
          )}
          <div className="flex flex-col items-center space-y-6 font-nunito-sans">
            <h3
              className={`text-1xl sm:text-2.1xl xl:text-3.1xl font-bold ${
                subsData !== null && subsData.status === 'Success'
                  ? 'text-success'
                  : 'text-failed'
              }`}
            >
              {subsData !== null && subsData.status}!
            </h3>
            <div
              className="text-base sm:text-lg xl:text-xl text-graylight text-center whitespace-pre-wrap"
              dangerouslySetInnerHTML={
                subsData !== null && { __html: subsData.msg }
              }
            >
              {/* {subsData !== null && subsData.msg} */}
            </div>
            <button
              onClick={handleBtn}
              className={`rounded-md text-lg sm:text-2xl xl:text-2.2xl text-white font-bold py-2 px-20 ${
                subsData !== null && subsData.status === 'Success'
                  ? 'bg-success'
                  : 'bg-failed'
              }`}
            >
              {subsData !== null && subsData.status === 'Success'
                ? 'Continue'
                : 'Close'}
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default SubscribeModal;
