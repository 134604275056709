import {
  IconRules,
  IconComposition,
  IconRisk,
  IconWarning,
  IconCollagen,
  IconCBP,
  IconForskohlii,
  IconProductFill,
  IconProductOutline,
  IconMedicine,
  IconSafety,
  IconPackagingFill,
  IconPackagingOutline,
  IconFolicAcid,
} from "../components/svg";

export const productsData = [
  {
    id: 1,
    name_product: "Vitamin C",
    slug: "vitamin-c",
    subtitle:
      "Mengandung 1000mg Vitamin C yang setara dengan kandungan Vitamin C di dalam 50 buah Lemon Jepang. Untuk yang sering terpapar Sinar Matahari dan Asap Rokok. Membantu meningkatkan imun tubuh, tinggi antioksidan dan mencegah tanda penuaan dini pada kulit.",
    img: "/images/products/vit-c.png",
    img2: "/images/our-product/vit-c.png",
    img_banner: "/images/products/vit-c-banner.png",
    img_banner_sm: "/images/products/vit-c-banner-sm.png",
    color: "#fec24d",
    homepages: "yes",
    bg_img: "/images/products/vit-c-bg.svg",
    bg_img_sm: "/images/products/vit-c-bg-sm.svg",
    content: {
      img_front: "/images/products/vit-c.png",
      img_back: "/images/products/vit-c-back.png",
      img_object: "/images/products/vit-c-object.png",
      description:
        "DHC Vitamin C + B2 mengandung 1000mg Vitamin C per 2 kapsul, setara dengan kandungan Vitamin C di dalam 50 buah Lemon Jepang, Tinggi Antioksidan dan dilengkapi dengan Vitamin B2 untuk membantu menjaga kesehatan kulit dan membrane mukosa.",
      list: [
        "Untuk Menambah Asupan Vitamin C.",
        "Untuk yang sering terpapar Sinar Matahari dan Asap Rokok.",
      ],
      fyi: "Kekurangan Vitamin C berdampak buruk bagi kesehatan jaringan tubuh seperti gusi berdarah, sariawan, muncul tanda penuaan kulit, imun tubuh lemah sehingga mudah sakit, penyembuhan luka yang lama serta mudah lelah. Konsumsi DHC Vitamin C + B2 untuk membantu menjaga kesehatan kulit dan membrane mukosa serta tinggi antioksidan.",
      badge: null,
      usage: [
        {
          id: 1,
          usage_title: "7 hari",
        },
      ],
      icon: [
        {
          img: "/images/universal/bpom.png",
          text: "BPOM Certified",
        },
        {
          img: "/images/universal/japan.png",
          text: "Made In Japan",
        },
      ],
      shop_list: [
        {
          id: 1,
          shop_name: "Shopee",
          shop_img: "/images/partners/shopee.png",
          shop_url:
            "https://shopee.co.id/DHC-Vitamin-C-i.697830791.12378969748 ",
        },
        {
          id: 2,
          shop_name: "Lazada",
          shop_img: "/images/partners/lazada.png",
          shop_url:
            "https://www.lazada.co.id/products/dhc-vitamin-c-i6245152447-s11865582452.html?spm=a2o4j.8553159.0.0.4ee41c535Le3b6&priceCompare=skuId%3A11865582452%3Bsource%3Alzd-shop-center%3Bsn%3A7085aed3-33ec-48cd-a7b2-702b067e5305%3BoriginPrice%3A2666000%3BvoucherPrice%3A2666000%3Btimestamp%3A1676270381619&search=store",
        },
        {
          id: 3,
          shop_name: "Tokopedia",
          shop_img: "/images/partners/tokopedia.png",
          shop_url:
            "https://www.tokopedia.com/dhcsupplement/dhc-vitamin-c?extParam=whid%3D13208526",
        },
        {
          id: 4,
          shop_name: "Tiktok Shop",
          shop_img: "/images/partners/tiktok-shop.png",
          shop_url:
            "https://shop.tiktok.com/view/product/1729588266776561766?region=ID&locale=en",
        },
        {
          id: 5,
          shop_name: "Halodoc",
          shop_img: "/images/partners/halodoc.png",
          shop_url:
            "https://www.halodoc.com/obat-dan-vitamin/dhc-vitamin-c-b2-14-kapsul",
        },
        // {
        //   id: 2,
        //   shop_name: 'Kalcare',
        //   shop_img: '/images/partners/kalcare.png',
        //   shop_url: '',
        // },
        // {
        //   id: 3,
        //   shop_name: 'Kimia Farma',
        //   shop_img: '/images/partners/kimia-farma.png',
        //   shop_url: '',
        // },

        // {
        //   id: 6,
        //   shop_name: 'Ranch Market',
        //   shop_img: '/images/partners/ranch-market.png',
        //   shop_url: '',
        // },
        // {
        //   id: 7,
        //   shop_name: 'Apotek Roxy',
        //   shop_img: '/images/partners/apotek-roxy.png',
        //   shop_url: '',
        // },
        // {
        //   id: 8,
        //   shop_name: 'Century',
        //   shop_img: '/images/partners/century.png',
        //   shop_url: '',
        // },
      ],
      accordion: [
        {
          id: 1,
          title: "Aturan Pakai",
          icon: IconRules,
          content: () => {
            return (
              <p>
                2 Kapsul sehari.
                <br />
                Dapat diminum dengan air biasa atau air hangat.
              </p>
            );
          },
        },
        {
          id: 2,
          title: "Komposisi",
          icon: IconComposition,
          content: () => {
            return (
              <>
                <p>Per 2 Kapsul mengandung:</p>
                <ul>
                  <li>Vitamin C (Ascorbic Acid) 1,000mg.</li>
                  <li>Vitamin B2 (Riboflavin) 2mg.</li>
                  <li>Gelatin (berasal dari Bovine / Sapi).</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 3,
          title: "Kelompok resiko tinggi kekurangan vitamin C",
          icon: IconRisk,
          content: () => {
            return (
              <ul>
                <li>Orang yang mengkonsumsi alkohol.</li>
                <li>Sering terpapar Sinar Matahari.</li>
                <li>Sering terpapar Asap Rokok.</li>
                <li>Orang dengan diet ketat atau nutrisi tidak seimbang.</li>
                <li>Orang yang mengkonsumsi obat-obatan jangka panjang.</li>
                <li>
                  Orang dengan kondisi medis khusus sehingga sulit mencerna dan
                  menyerap makanan.
                </li>
                <li>
                  Ibu hamil dan menyusui yang membutuhkan jumlah Vitamin lebih
                  tinggi.
                </li>
              </ul>
            );
          },
        },
        {
          id: 4,
          title: "Peringatan",
          icon: IconWarning,
          content: () => {
            return (
              <ul>
                <li>
                  Jangan dikonsumsi melebihi aturan pakai yang telah ditetapkan.
                </li>
                <li>
                  Hentikan pemakaian bila timbul reaksi yang tidak diinginkan.
                </li>
                <li>
                  Jangan dikonsumsi jika memiliki alergi terhadap bahan-bahan
                  yang tertera di komposisi.
                </li>
                <li>
                  Konsultasikan dengan dokter sebelum penggunaan jika sedang
                  dalam masa pengobatan, kondisi perawatan atau ibu hamil.
                </li>
                <li>
                  Konsultasikan dengan dokter untuk Batas Atas Asupan dan
                  Interaksi Suplemen jika mengkonsumsi lebih dari satu jenis
                  Suplemen.
                </li>
                <li>
                  Tutup rapat setelah dibuka, dikonsumsi segera setelah dibuka
                  dengan mengikuti aturan pakai yang telah ditetapkan.
                </li>
              </ul>
            );
          },
        },
      ],
    },
  },

  {
    id: 2,
    name_product: "Folic Acid",
    slug: "folic-acid",
    subtitle:
      "Mengandung Asam Folat 400μg, yang setara dengan nutrisi dari 360gram bayam Jepang. Untuk memenuhi kebutuhan Asam Folat harian Ibu Hamil dan Menyusui, serta untuk membantu meningkatkan Daya Ingat yang menurun pada Lansia.",
    img: "/images/products/folic-acid.png",
    img2: "/images/our-product/folic-acid.png",
    img_banner: "/images/products/folic-acid-banner.png",
    img_banner_sm: "/images/products/folic-acid-banner-sm.png",
    color: "#faa74a",
    homepages: "yes",
    bg_img: "/images/products/folic-acid-bg.svg",
    bg_img_sm: "/images/products/folic-acid-bg-sm.svg",
    content: {
      img_front: "/images/products/folic-acid.png",
      img_back: "/images/products/folic-acid-back.png",
      img_object: "/images/products/folic-acid-object.png",
      description:
        "DHC Folic Acid + Vit B mengandung Asam Folat 400μg setara dengan nutrisi 360gram bayam Jepang. Untuk memenuhi kebutuhan Asam Folat harian Ibu Hamil dan Menyusui, Meningkatkan Daya Ingat yang menurun pada lansia serta menjaga kesehatan sehari-hari. Dilengkapi dengan kandungan Vitamin B2, B6 dan B12 yang mampu mengoptimalkan pembentukan sel darah merah serta mengoptimalkan kerja syaraf.",
      list: [
        "Untuk Ibu Hamil dan Menyusui.",
        "Untuk Membantu Meningkatkan Daya Ingat.",
      ],
      fyi: "Berdasarkan hasil study, Asam Folat juga bermanfaat untuk membantu mencegah dementia dan meningkatkan daya ingat.",

      badge: null,
      usage: [
        {
          id: 1,
          usage_title: "7 hari",
        },
      ],
      icon: [
        {
          img: "/images/universal/bpom.png",
          text: "BPOM Certified",
        },
        {
          img: "/images/universal/japan.png",
          text: "Made In Japan",
        },
      ],
      shop_list: [
        {
          id: 1,
          shop_name: "Shopee",
          shop_img: "/images/partners/shopee.png",
          shop_url:
            "https://shopee.co.id/DHC-Folic-Acid-i.697830791.17512270771",
        },
        {
          id: 2,
          shop_name: "Lazada",
          shop_img: "/images/partners/lazada.png",
          shop_url:
            "https://www.lazada.co.id/products/dhc-folic-acid-i6245850291-s11866394201.html?spm=a2o4j.8553159.0.0.50ee1c53JS5XDD&priceCompare=skuId%3A11866394201%3Bsource%3Alzd-shop-center%3Bsn%3A57cdc6a7-62e1-4811-8f9a-0c5b3fbb1f6e%3BoriginPrice%3A1806000%3BvoucherPrice%3A1806000%3Btimestamp%3A1676271860601&search=store",
        },
        {
          id: 3,
          shop_name: "Tokopedia",
          shop_img: "/images/partners/tokopedia.png",
          shop_url:
            "https://www.tokopedia.com/dhcsupplement/dhc-folic-acid?extParam=whid%3D13208526",
        },
        {
          id: 4,
          shop_name: "Tiktok Shop",
          shop_img: "/images/partners/tiktok-shop.png",
          shop_url: "https://vt.tiktok.com/ZS8fGpGe1/",
        },
        {
          id: 5,
          shop_name: "Halodoc",
          shop_img: "/images/partners/halodoc.png",
          shop_url:
            "https://www.halodoc.com/obat-dan-vitamin/dhc-folic-acid-vit-b-7-tablet",
        },
        // {
        //   id: 2,
        //   shop_name: 'Kalcare',
        //   shop_img: '/images/partners/kalcare.png',
        //   shop_url: '',
        // },
        // {
        //   id: 3,
        //   shop_name: 'Kimia Farma',
        //   shop_img: '/images/partners/kimia-farma.png',
        //   shop_url: '',
        // },
        // {
        //   id: 6,
        //   shop_name: 'Ranch Market',
        //   shop_img: '/images/partners/ranch-market.png',
        //   shop_url: '',
        // },
        // {
        //   id: 7,
        //   shop_name: 'Apotek Roxy',
        //   shop_img: '/images/partners/apotek-roxy.png',
        //   shop_url: '',
        // },
        // {
        //   id: 8,
        //   shop_name: 'Century',
        //   shop_img: '/images/partners/century.png',
        //   shop_url: '',
        // },
      ],
      accordion: [
        {
          id: 1,
          title: "Pentingnya Asam Folat",
          icon: IconFolicAcid,
          content: () => {
            return (
              <div>
                <p>
                  Konsumsi Asam Folat sangat penting untuk wanita yang sedang
                  dalam perencanaan hamil atau dalam masa kehamilan, sebagai
                  pencegahan terjadinya gangguan perkembangan organ tubuh bayi.
                  Karena kekurangan Asam Folat di awal masa kehamilan dapat
                  mengakibatkan peningkatan kadar plasma homosistein, dan
                  efeknya adalah tabung saraf janin akan berisiko tinggi gagal
                  tertutup (Neural Tube Closure). Selain itu, Asam Folat sangat
                  penting pada periode pembelahan dan pertumbuhan sel pada anak
                  hingga dewasa. Kekurangan asam folat di masa kehamilan dapat
                  menyebabkan berbagai jenis kecacatan pada Janin seperti
                  spinabifida, anensefali, anencephali, bibir sumbing, gangguan
                  jantung, gangguan perkembangan pusat kecerdasan dll. Sedangkan
                  pada Ibu hamil dapat menyebabkan anemia, persalinan prematur,
                  plasenta lepas sebelum waktunya, keguguran, sesak napas hingga
                  gangguan pencernaan.{" "}
                </p>
                <br />
                <p>
                  Akan tetapi, tidak mudah untuk mendapatkan cukup asupan Asam
                  Folat melalui makanan sehari-hari dikarenakan berbagai faktor,
                  dan salah satunya adalah rendahnya tingkat penyerapan Asam
                  Folat melalui konsumsi makanan.
                </p>
              </div>
            );
          },
        },
        {
          id: 2,
          title: "Aturan Pakai",
          icon: IconRules,
          content: () => {
            return (
              <p>
                1 Tablet sehari.
                <br />
                Dapat diminum dengan air biasa atau air hangat.
              </p>
            );
          },
        },
        {
          id: 3,
          title: "Komposisi",
          icon: IconComposition,
          content: () => {
            return (
              <div>
                <p>1 Tablet mengandung:</p>
                <ul>
                  <li>Folic Acid 400μg.</li>
                  <li>Vitamin B6 (Pyridoxine Hydrochloride) 1.3mg.</li>
                  <li>Vitamin B2 (Riboflavin) 1.7mg.</li>
                  <li>Vitamin B12 (Cyanocobalamin) 2.5μg.</li>
                </ul>
              </div>
            );
          },
        },
        {
          id: 4,
          title: "Direkomendasikan mengkonsumsi Folic Acid",
          icon: IconRisk,
          content: () => {
            return (
              <ul>
                <li>Ibu hamil dan menyusui.</li>
                <li>Wanita yang sedang merencanakan kehamilan.</li>
                <li>Kelompok Lanjut Usia.</li>
                <li>Orang dengan konsumsi nutrisi tidak seimbang.</li>
                <li>
                  Orang dengan kondisi medis khusus sehingga sulit mencerna dan
                  menyerap makanan.
                </li>
              </ul>
            );
          },
        },
        {
          id: 5,
          title: "Peringatan",
          icon: IconWarning,
          content: () => {
            return (
              <ul>
                <li>
                  Jangan dikonsumsi melebihi aturan pakai yang telah ditetapkan.
                </li>
                <li>
                  Hentikan pemakaian bila timbul reaksi alergi yang tidak
                  diinginkan.
                </li>
                <li>
                  Jangan konsumsi jika memiliki alergi terhadap bahan-bahan yang
                  tertera di komposisi.
                </li>
                <li>
                  Konsultasikan dengan dokter sebelum penggunaan jika sedang
                  dalam masa pengobatan, kondisi perawatan atau ibu hamil.
                </li>
                <li>
                  Konsultasikan dengan dokter untuk Batas Atas Asupan dan
                  Interaksi Suplemen jika mengkonsumsi lebih dari satu jenis
                  Suplemen.
                </li>
                <li>
                  Tutup rapat setelah dibuka, konsumsi segera setelah dibuka
                  dengan mengikuti aturan pakai yang telah ditetapkan.
                </li>
              </ul>
            );
          },
        },
      ],
    },
  },
  {
    id: 3,
    name_product: "Calcium + CBP",
    slug: "calcium",
    subtitle: (() => {
      return (
        <p>
          Dilengkapi dengan CBP <i>(Concentrated Bovine Milk-Whey Protein)</i>{" "}
          untuk mengoptimalkan penyerapan Kalsium pada tubuh sehingga
          meningkatkan Kepadatan dan Kekuatan Tulang. 4 tablet setara dengan
          kandungan CBP di dalam 8 liter susu.
        </p>
      );
    })(),
    img: "/images/products/calcium.png",
    img2: "/images/our-product/calcium.png",
    img_banner: "/images/products/calcium-banner.png",
    img_banner_sm: "/images/products/calcium-banner-sm.png",
    color: "#00a3df",
    homepages: "yes",
    bg_img: "/images/products/calcium-bg.svg",
    bg_img_sm: "/images/products/calcium-bg-sm.svg",
    content: {
      img_front: "/images/products/calcium.png",
      img_back: "/images/products/calcium-back.png",
      img_object: "/images/products/calcium-object.png",
      description: (() => {
        return (
          <p>
            DHC Calcium + CBP mengandung Kalsium yang dilengkapi dengan
            CBP&nbsp;
            <i>(Concentrated Bovine Milk-Whey Active Protein)</i> yang berfungsi
            mengoptimalkan penyerapan Kalsium pada tubuh sehingga mampu
            meningkatkan Kepadatan dan Kekuatan tulang. 4 tablet setara dengan
            kandungan CBP dalam 8 liter susu. Rasa susu yang enak dikunyah,
            tidak meninggalkan bubuk putih dan tidak menempel di tenggorokan,
            sehingga anak-anak dan orang tua dapat menikmatinya dengan nyaman.
          </p>
        );
      })(),
      list: [
        "Untuk Mengoptimalkan Kekuatan Tulang dan Gigi.",
        "Untuk membantu Pertumbuhan dan Kepadatan Tulang dan Gigi.",
      ],
      fyi: "Kalsium merupakan mineral paling banyak dalam tubuh yaitu 1,5-2%, yang membantu tubuh membangun tulang dan gigi yang sehat. Kekurangan Kalsium dapat meningkatkan resiko Osteoporosis (tulang rapuh dan keropos) dan Osteopenia (kepadatan tulang yang rendah).",
      badge: [
        {
          badge_name: "No.1 Market Share",
          badge_img: "/images/universal/no-1-market-share.png",
          badge_description:
            "Market Share No. 1 \ndi segmen Suplemen Tulang・Persendian・Otot.\n[H.B Foods Marketing Handbook 2023 No.1] Fuji Keizai. \n(based on total sales of products category in 2021)",
        },
        {
          badge_name: "CBP",
          badge_img: "/images/universal/cbp.png",
          badge_description: "Quality Guaranteed CBP from Seperex",
        },
      ],
      usage: [
        {
          id: 1,
          usage_title: "7 hari",
        },
      ],
      icon: [
        {
          img: "/images/universal/bpom.png",
          text: "BPOM Certified",
        },
        {
          img: "/images/universal/japan.png",
          text: "Made In Japan",
        },
      ],
      shop_list: [
        {
          id: 1,
          shop_name: "Shopee",
          shop_img: "/images/partners/shopee.png",
          shop_url:
            "https://shopee.co.id/DHC-Calcium-CBP-i.697830791.17912614047?sp_atk=171addcb-82ba-4736-b844-4dd796fcbe41&xptdk=171addcb-82ba-4736-b844-4dd796fcbe41",
        },
        {
          id: 2,
          shop_name: "Lazada",
          shop_img: "/images/partners/lazada.png",
          shop_url:
            "https://www.lazada.co.id/products/dhc-calcium-cbp-i6256040597-s11883980795.html?spm=a2o4j.8553159.0.0.50ee1c53JS5XDD&priceCompare=skuId%3A11883980795%3Bsource%3Alzd-shop-center%3Bsn%3Aa42d67d3-4208-433a-bd21-473b4a08056d%3BoriginPrice%3A3526000%3BvoucherPrice%3A3526000%3Btimestamp%3A1676271860601&search=store",
        },
        {
          id: 3,
          shop_name: "Tokopedia",
          shop_img: "/images/partners/tokopedia.png",
          shop_url:
            "https://www.tokopedia.com/dhcsupplement/dhc-calcium-cbp?extParam=whid%3D13208526",
        },
        {
          id: 4,
          shop_name: "Tiktok Shop",
          shop_img: "/images/partners/tiktok-shop.png",
          shop_url: "https://vt.tiktok.com/ZS8fGeckC/",
        },
        {
          id: 5,
          shop_name: "Halodoc",
          shop_img: "/images/partners/halodoc.png",
          shop_url:
            "https://www.halodoc.com/obat-dan-vitamin/dhc-calcium-cbp-28-tablet",
        },
        // {
        //   id: 2,
        //   shop_name: 'Kalcare',
        //   shop_img: '/images/partners/kalcare.png',
        //   shop_url: '',
        // },
        // {
        //   id: 3,
        //   shop_name: 'Kimia Farma',
        //   shop_img: '/images/partners/kimia-farma.png',
        //   shop_url: '',
        // },
        // {
        //   id: 6,
        //   shop_name: 'Ranch Market',
        //   shop_img: '/images/partners/ranch-market.png',
        //   shop_url: '',
        // },
        // {
        //   id: 7,
        //   shop_name: 'Apotek Roxy',
        //   shop_img: '/images/partners/apotek-roxy.png',
        //   shop_url: '',
        // },
        // {
        //   id: 8,
        //   shop_name: 'Century',
        //   shop_img: '/images/partners/century.png',
        //   shop_url: '',
        // },
      ],
      accordion: [
        {
          id: 1,
          title: "CBP (Concentrated Bovine Milk-Whey Active Protein)",
          icon: IconCBP,
          content: () => {
            return (
              <p>
                CBP adalah singkatan dari{" "}
                <i>Concentrated Bovine Milk-Whey Active Protein</i> yaitu suatu
                senyawa protein yang ditemukan pada susu sapi, bekerja
                meningkatkan penyerapan dan retention rate sehingga membantu
                meningkatkan kepadatan dan kekuatan tulang. Hasil penelitian
                dari Seperex Corporation menemukan fakta bahwa low molecular
                protein yang terdapat di Whey tersebut sangat efektif untuk
                kekuatan tulang, pada dosis 6mg sampai 60mg CBP perhari dianggap
                paling memberikan manfaat pada Kesehatan tulang.
              </p>
            );
          },
        },
        {
          id: 2,
          title: "Aturan Pakai",
          icon: IconRules,
          content: () => {
            return (
              <p>
                4 Tablet sehari.
                <br />
                Dapat diminum dengan air atau dikunyah langsung.
              </p>
            );
          },
        },
        {
          id: 3,
          title: "Komposisi",
          icon: IconComposition,
          content: () => {
            return (
              <>
                <p>Per 4 Tablet mengandung:</p>
                <ul>
                  <li>
                    Egg Shell Powder (Made in Japan) yang mengandung Calcium
                    370mg.
                  </li>
                  <li>
                    <i>Concentrated Bovine Milk-Whey Active Protein</i>{" "}
                    (Milk-derived) 12.6mg.
                  </li>
                  <li>Vitamin D3 (Sheep-derived) 0.078μg.</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 4,
          title: "Kelompok resiko tinggi kekurangan Calcium",
          icon: IconRisk,
          content: () => {
            return (
              <ul>
                <li>Orang pasca menopause.</li>
                <li>Kelompok lanjut usia.</li>
                <li>Orang dengan intoleransi laktosa.</li>
                <li>Orang yang tidak suka minum susu/olahan dari susu.</li>
                <li>Orang yang jarang Berolahraga.</li>
                <li>
                  Orang yang sering berada di dalam ruangan dan jarang terkena
                  sinar matahari.
                </li>
                <li>Orang dengan diet ketat atau nutrisi tidak seimbang.</li>
              </ul>
            );
          },
        },
        {
          id: 5,
          title: "Peringatan",
          icon: IconWarning,
          content: () => {
            return (
              <ul>
                <li>
                  Jangan dikonsumsi melebihi aturan pakai yang telah ditetapkan.
                </li>
                <li>
                  Hentikan pemakaian bila timbul reaksi yang tidak diinginkan.
                </li>
                <li>
                  Jangan dikonsumsi jika memiliki alergi terhadap bahan-bahan
                  yang tertera di komposisi.
                </li>
                <li>
                  Konsultasikan dengan dokter sebelum penggunaan jika sedang
                  dalam masa pengobatan, kondisi perawatan atau ibu hamil.
                </li>
                <li>
                  Konsultasikan dengan dokter untuk Batas Atas Asupan dan
                  Interaksi Suplemen jika mengkonsumsi lebih dari satu jenis
                  Suplemen.
                </li>
                <li>
                  Tutup rapat setelah dibuka, dikonsumsi segera setelah dibuka
                  dengan mengikuti aturan pakai yang telah ditetapkan.
                </li>
              </ul>
            );
          },
        },
      ],
    },
  },
  {
    id: 4,
    name_product: "Forskohlii",
    slug: "forskohlii",
    subtitle:
      "Mengandung senyawa Forskolin dari Bahan Alami Tumbuhan untuk Diet yang Sehat dan Mempertahankan Tubuh tetap Ramping. Forskohlii membantu Diet yang sehat dengan membantu membakar lemak tubuh secara efektif dan membentuk LBM (Lean Body Mass) yang sehat.",
    img: "/images/products/forskohlii.png",
    img2: "/images/our-product/forskohlii.png",
    img_banner: "/images/products/forskohlii-banner.png",
    img_banner_sm: "/images/products/forskohlii-banner-sm.png",
    color: "#00b7ba",
    homepages: "yes",
    bg_img: "/images/products/forskohlii-bg.svg",
    bg_img_sm: "/images/products/forskohlii-bg-sm.svg",
    content: {
      img_front: "/images/products/forskohlii.png",
      img_back: "/images/products/forskohlii-back.png",
      img_object: "/images/products/forskohlii-object.png",
      description:
        // 'DHC Forskohlii + B1, B2 and B6 dengan kandungan senyawa Forskolin dari Bahan Alami Tumbuhan, yang dapat membantu membakar lemak tubuh secara efektif, and hasilnya adalah membantu membentuk LBM yang sehat. LBM yang sehat dapat membuat tubuh tidak mudah bertambah berat badan. Olahraga teratur dan diet rendah lemak/kalori untuk mendapatkan hasil yang maksimal.\n \n※LBM adalah Lean Body Mass atau Masa Tubuh Tanpa Lemak.\nBerat Badan – Lemak = Total berat Otot, Tulang dan Organ.\n※Produk di kemasan Bahasa Jepang memiliki kandungan yang sama dengan kemasan Bahasa Indonesia. Perbedaan Aturan Pakai versi Indonesia (2-3 Tablet sehari) dengan versi Jepang (2-4 Tablet sehari) dikarenakan adanya perbedaan regulasi di negara Jepang dan Indonesia',
        (() => {
          return (
            <div>
              <p>
                DHC Forskohlii + B1, B2 and B6 dengan kandungan senyawa
                Forskolin dari Bahan Alami Tumbuhan, yang dapat membantu
                membakar lemak tubuh secara efektif, dan hasilnya adalah
                membantu membentuk LBM yang sehat. LBM yang sehat dapat membuat
                tubuh tidak mudah bertambah berat badan. Olahraga teratur dan
                diet rendah lemak/kalori untuk mendapatkan hasil yang maksimal.
              </p>
              <br />
              <p className="text-sm xl:text-base">
                ※LBM adalah Lean Body Mass atau Masa Tubuh Tanpa Lemak.
                <br />
                Berat Badan – Lemak = Total berat Otot, Tulang dan Organ.
              </p>
              <p className="text-sm xl:text-base">
                ※Produk di kemasan Bahasa Jepang memiliki kandungan yang sama
                dengan kemasan Bahasa Indonesia. Perbedaan Aturan Pakai versi
                Indonesia (2-3 Tablet sehari) dengan versi Jepang (2-4 Tablet
                sehari) dikarenakan adanya perbedaan regulasi di negara Jepang
                dan Indonesia.
              </p>
            </div>
          );
        })(),
      list: [
        "Untuk Diet secara Sehat.",
        "Untuk Membantu Mempertahankan Tubuh Ramping.",
      ],
      fyi: "Lemak berlebih dapat disebabkan karena asupan makanan yang tinggi kalori dan menumpuk dalam tubuh sehingga menimbulkan berbagai masalah kesehatan seperti obesitas, penyakit jantung, kerusakan dinding arteri karena tingginya kolesterol, sembelit hingga pemicu kanker. Mari jalankan pola hidup sehat dengan banyak berolahraga, kurangi makanan berlemak tidak baik dan kurangi makanan berminyak tidak sehat.",
      badge: [
        {
          badge_name: "No.1 Market Share",
          badge_img: "/images/universal/no-1-market-share.png",
          badge_description:
            "Market Share No. 1 di segmen Diet. \n[H.B Foods Marketing Handbook 2023 No.1] Fuji Keizai. \n(based on total sales of products category in 2021)",
        },
        {
          badge_name: "Sold More than 44 Million",
          badge_img: "/images/universal/44-million.svg",
          badge_description: "Terjual lebih dari 44 juta produk",
        },
      ],
      usage: [
        {
          id: 1,
          usage_title: "7 hari",
        },
        {
          id: 2,
          usage_title: "diet",
        },
      ],
      icon: [
        {
          img: "/images/universal/bpom.png",
          text: "BPOM Certified",
        },
        {
          img: "/images/universal/japan.png",
          text: "Made In Japan",
        },
      ],
      shop_list: [
        {
          id: 1,
          shop_name: "Shopee",
          shop_img: "/images/partners/shopee.png",
          shop_url:
            "https://shopee.co.id/DHC-Forskohlii-i.697830791.21272715698?sp_atk=82d66f53-052f-4ec0-a0c7-e75fcb18fd73&xptdk=82d66f53-052f-4ec0-a0c7-e75fcb18fd73",
        },
        {
          id: 2,
          shop_name: "Lazada",
          shop_img: "/images/partners/lazada.png",
          shop_url:
            "https://www.lazada.co.id/products/dhc-forskohlii-i7275864111-s13629338525.html?spm=a2o4j.8553159.0.0.50ee1c53JS5XDD&priceCompare=skuId%3A13629338525%3Bsource%3Alzd-shop-center%3Bsn%3A715672f7-82eb-448d-befa-fdfe2cf717bf%3BoriginPrice%3A10922000%3BvoucherPrice%3A10922000%3Btimestamp%3A1676271860601&search=store",
        },
        {
          id: 3,
          shop_name: "Tokopedia",
          shop_img: "/images/partners/tokopedia.png",
          shop_url:
            "https://www.tokopedia.com/dhcsupplement/dhc-forskohlii?extParam=whid%3D13208526",
        },
        {
          id: 4,
          shop_name: "Tiktok Shop",
          shop_img: "/images/partners/tiktok-shop.png",
          shop_url:
            "https://shop.tiktok.com/view/product/1729588450451490918?region=ID&locale=en ",
        },
        {
          id: 5,
          shop_name: "Halodoc",
          shop_img: "/images/partners/halodoc.png",
          shop_url:
            "https://www.halodoc.com/obat-dan-vitamin/dhc-forskohlii-b1-b2-and-b6-28-tablet",
        },
        // {
        //   id: 2,
        //   shop_name: 'Kalcare',
        //   shop_img: '/images/partners/kalcare.png',
        //   shop_url: '',
        // },
        // {
        //   id: 3,
        //   shop_name: 'Kimia Farma',
        //   shop_img: '/images/partners/kimia-farma.png',
        //   shop_url: '',
        // },
        // {
        //   id: 6,
        //   shop_name: 'Ranch Market',
        //   shop_img: '/images/partners/ranch-market.png',
        //   shop_url: '',
        // },
        // {
        //   id: 7,
        //   shop_name: 'Apotek Roxy',
        //   shop_img: '/images/partners/apotek-roxy.png',
        //   shop_url: '',
        // },
        // {
        //   id: 8,
        //   shop_name: 'Century',
        //   shop_img: '/images/partners/century.png',
        //   shop_url: '',
        // },
      ],
      accordion: [
        {
          id: 1,
          title: "Forskohlii",
          icon: IconForskohlii,
          content: () => {
            return (
              <p>
                Dari awal 1970, ilmuwan sudah mulai melakukan penelitian untuk
                kemungkinan penggunaan Forskolin di bidang kesehatan dari
                berbagai perspektif, dan menemukan bahwa Forskolin efektif untuk
                diet. Forskolin mendapat popularitas karena kemampuannya dalam
                membantu membakar lemak tubuh secara efektif. Coleus Forskohlii
                memiliki senyama bioaktif utama yang disebut Forskolin. Senyawa
                Forskolin meningkatkan siklik adenosin monofosfat (cAMP)
                berkaitan dengan peningkatan kehilangan lemak sehingga dapat
                meningkatkan efek pembakaran lemak pada tubuh. Forskolin juga
                dapat meningkatkan testosteron dan melindungi dari kanker dan
                peradangan.
              </p>
            );
          },
        },
        {
          id: 2,
          title: "Aturan Pakai",
          icon: IconRules,
          content: () => {
            return (
              <p>
                2-3 Tablet sehari.
                <br />
                Dapat diminum dengan air biasa atau air hangat.
              </p>
            );
          },
        },
        {
          id: 3,
          title: "Komposisi",
          icon: IconComposition,
          content: () => {
            return (
              <>
                <p>2-3 Tablet mengandung:</p>
                <ul>
                  <li>Coleus Forskohli Extract 500-750mg.</li>
                  <li>Vitamin B1 Mononitrate 0.8-1.2mg.</li>
                  <li>Vitamin B2 (Riboflavin) 1.0-1.5mg.</li>
                  <li>Vitamin B6 HCL 1.2-1.8mg.</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 4,
          title: "Direkomendasikan mengkonsumsi Forskohlii",
          icon: IconRisk,
          content: () => {
            return (
              <ul>
                <li>Orang yang ingin membentuk LBM ideal.</li>
                <li>Orang yang ingin mempertahankan bentuk tubuh ramping.</li>
                <li>Orang yang sering konsumsi makanan tinggi lemak.</li>
                <li>
                  Orang dengan nutrisi tidak seimbang serta tinggi kalori.
                </li>
                <li>
                  Orang dengan kondisi medis khusus sehingga sulit membakar
                  lemak tubuh.
                </li>
                <li>Orang yang kurang aktifitas fisik.</li>
                <li>Orang yang susah melakukan diet makanan.</li>
              </ul>
            );
          },
        },
        {
          id: 5,
          title: "Peringatan",
          icon: IconWarning,
          content: () => {
            return (
              <ul>
                <li>
                  Diare dapat terjadi pada kondisi dan jenis tubuh tertentu.
                </li>
                <li>
                  Dianjurkan untuk mulai dari dosis kecil dan ditingkatkan
                  sesuai kondisi tubuh.
                </li>
                <li>
                  Jangan dikonsumsi melebihi aturan pakai yang telah ditetapkan.
                </li>
                <li>
                  Hentikan pemakaian bila timbul reaksi alergi yang tidak
                  diinginkan.
                </li>
                <li>
                  Jangan konsumsi jika memiliki alergi terhadap bahan-bahan yang
                  tertera di komposisi.
                </li>
                <li>
                  Konsultasikan dengan dokter sebelum penggunaan, jika sedang
                  dalam masa pengobatan, kondisi perawatan atau ibu hamil.
                </li>
                <li>
                  Konsultasikan dengan dokter untuk Batas Atas Asupan dan
                  Interaksi Suplemen jika mengkonsumsi lebih dari satu jenis
                  Suplemen.
                </li>
                <li>
                  Tutup rapat setelah dibuka, konsumsi segera setelah dibuka
                  dengan mengikuti aturan pakai yang telah ditetapkan.
                </li>
              </ul>
            );
          },
        },
      ],
    },
  },
  {
    id: 5,
    name_product: "Collagen",
    slug: "collagen",
    subtitle:
      "Mengandung 2,050mg Fish Collagen Peptide, dengan Collagen Peptide yang memiliki struktur lebih kecil dibandingkan Collagen biasa sehingga lebih mudah diserap tubuh. Untuk membantu Meningkatkan Elastisitas Kulit dan Mempertahankan Keremajaan Kulit.",
    img: "/images/products/collagen.png",
    img2: "/images/our-product/collagen.png",
    img_banner: "/images/products/collagen-banner.png",
    img_banner_sm: "/images/products/collagen-banner-sm.png",
    color: "#F0649C",
    homepages: "yes",
    bg_img: "/images/products/collagen-bg.svg",
    bg_img_sm: "/images/products/collagen-bg-sm.svg",
    content: {
      img_front: "/images/products/collagen.png",
      img_back: "/images/products/collagen-back.png",
      img_object: "/images/products/collagen-object.png",
      description:
        "DHC Collagen + B1 and B2 mengandung 2,050mg Fish Collagen Peptide, dengan Collagen Peptide yang memiliki struktur lebih kecil sehingga lebih mudah diserap tubuh dibandingkan dengan Collagen biasa. Dilengkapi dengan Vitamin B1 dan B2, mampu bekerja sinergi menjaga kulit tetap kencang, kenyal, tekstur yang halus dan segar. Uji klinis membuktikan bahwa suplementasi Collagen Peptide dapat meningkatkan hidrasi kulit, elastisitas dan kepadatan kulit sehingga penampilan kulit dapat meningkat secara signifikan.",
      list: [
        "Untuk Meningkatkan Elastisitas Kulit.",
        "Untuk Mempertahankan Keremajaan Kulit.",
      ],
      fyi: "Collagen merupakan protein paling melimpah dalam tubuh yang membentuk 90% jaringan ikat, 90% massa tulang organik dan 70% kulit. Namun seiiring dengan bertambahnya usia, produksi kolagen dalam tubuh menurun sehingga menyebabkan berbagai masalah kesehatan seperti elastisitas kulit menurun, muncul tanda penuaan dini, kulit kering dan tampak keriput, nyeri sendi, rambut rontok dan menipis, muncul selulit dan sebagainya.",
      badge: [
        {
          badge_name: "No.1 Market Share",
          badge_img: "/images/universal/no-1-market-share.png",
          badge_description:
            "Market Share No. 1 di segmen Beauty Result. \n[H.B Foods Marketing Handbook 2023 No.1] Fuji Keizai. \n(based on total sales of product category in 2021)",
        },
        {
          badge_name: "Collagen Peptide",
          badge_img: "/images/universal/collagen-peptide.png",
          badge_description: "Collagen Peptide yang berasal dari Ikan.",
        },
      ],
      usage: [
        {
          id: 1,
          usage_title: "7 hari",
        },
      ],
      icon: [
        {
          img: "/images/universal/bpom.png",
          text: "BPOM Certified",
        },
        {
          img: "/images/universal/japan.png",
          text: "Made In Japan",
        },
      ],
      shop_list: [
        {
          id: 1,
          shop_name: "Shopee",
          shop_img: "/images/partners/shopee.png",
          shop_url:
            "https://shopee.co.id/DHC-Collagen-i.697830791.14957186828?sp_atk=09dab4db-864e-4ae9-baba-c334d62c2f94&xptdk=09dab4db-864e-4ae9-baba-c334d62c2f94",
        },
        {
          id: 2,
          shop_name: "Lazada",
          shop_img: "/images/partners/lazada.png",
          shop_url:
            "https://www.lazada.co.id/products/dhc-collagen-i6256036302-s11883984435.html?spm=a2o4j.8553159.0.0.50ee1c53JS5XDD&priceCompare=skuId%3A11883984435%3Bsource%3Alzd-shop-center%3Bsn%3A96201f6e-a634-4b7b-bdd8-0c2d6cc44b46%3BoriginPrice%3A5160000%3BvoucherPrice%3A5160000%3Btimestamp%3A1676271860601&search=store",
        },
        {
          id: 3,
          shop_name: "Tokopedia",
          shop_img: "/images/partners/tokopedia.png",
          shop_url:
            "https://www.tokopedia.com/dhcsupplement/dhc-collagen?extParam=whid%3D13208526",
        },
        {
          id: 4,
          shop_name: "Tiktok Shop",
          shop_img: "/images/partners/tiktok-shop.png",
          shop_url: "https://vt.tiktok.com/ZS8fsTJjD/",
        },
        {
          id: 5,
          shop_name: "Halodoc",
          shop_img: "/images/partners/halodoc.png",
          shop_url:
            "https://www.halodoc.com/obat-dan-vitamin/dhc-collagen-b1-and-b2-42-tablet",
        },
        // {
        //   id: 2,
        //   shop_name: 'Kalcare',
        //   shop_img: '/images/partners/kalcare.png',
        //   shop_url: '',
        // },
        // {
        //   id: 3,
        //   shop_name: 'Kimia Farma',
        //   shop_img: '/images/partners/kimia-farma.png',
        //   shop_url: '',
        // },
        // {
        //   id: 6,
        //   shop_name: 'Ranch Market',
        //   shop_img: '/images/partners/ranch-market.png',
        //   shop_url: '',
        // },
        // {
        //   id: 7,
        //   shop_name: 'Apotek Roxy',
        //   shop_img: '/images/partners/apotek-roxy.png',
        //   shop_url: '',
        // },
        // {
        //   id: 8,
        //   shop_name: 'Century',
        //   shop_img: '/images/partners/century.png',
        //   shop_url: '',
        // },
      ],
      accordion: [
        {
          id: 1,
          title: "Collagen Peptide",
          icon: IconCollagen,
          content: () => {
            return (
              <div className="flex flex-col space-y-5 text-justify">
                <p>
                  Collagen Peptide merupakan kolagen terhidrolisis atau kolagen
                  hidrolisat, dengan bentuk yang lebih kecil. Rantai asam amino
                  peptida lebih pendek, dan memiliki bobot molekul lebih rendah
                  sehingga mudah larut dalam air panas atau dingin, lebih mudah
                  dicerna, serta cepat dan efektif diserap ke dalam aliran
                  darah.
                </p>
                <p>
                  Selain air, 70% dari komponen penyusun kulit adalah kolagen.
                  Kolagen berfungsi menjaga elastisitas dan kekencangan kulit
                  pada jaringan kulit. Penggunaan Collagen Peptide penting
                  karena dapat membantu mencegah efek buruk yang disebabkan
                  karena produksi kolagen dalam tubuh yang mulai berkurang saat
                  usia diatas 20 tahun-an, dan mencegah kulit kering serta
                  tampak keriput yang disebabkan karena kekurangan kolagen.
                </p>
              </div>
            );
          },
        },
        {
          id: 2,
          title: "Aturan Pakai",
          icon: IconRules,
          content: () => {
            return (
              <p>
                6 Tablet sehari.
                <br />
                Dapat diminum dengan air biasa atau air hangat.
              </p>
            );
          },
        },
        {
          id: 3,
          title: "Komposisi",
          icon: IconComposition,
          content: () => {
            return (
              <>
                <p>Per 6 Tablet mengandung:</p>
                <ul>
                  <li>Collagen Peptide (Fish-derived) 2,050mg.</li>
                  <li>Vitamin B1 (Thiamine-Hydrochloride) 14mg.</li>
                  <li>Vitamin B2 (Riboflavin) 2mg.</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 4,
          title: "Kelompok resiko tinggi kekurangan Collagen",
          icon: IconRisk,
          content: () => {
            return (
              <ul>
                <li>
                  Kelompok usia di atas 20 tahun yang peduli dengan kesehatan
                  kulit.
                </li>
                <li>Orang yang sering terpapar sinar UV.</li>
                <li>Orang yang mengkonsumsi alkohol.</li>
                <li>
                  Orang yang konsumsi tinggi gula dan makanan olahan denaturasi.
                </li>
                <li>Perokok aktif dan atau yang sering terpapar asap rokok.</li>
              </ul>
            );
          },
        },
        {
          id: 5,
          title: "Peringatan",
          icon: IconWarning,
          content: () => {
            return (
              <ul>
                <li>
                  Jangan dikonsumsi melebihi aturan pakai yang telah ditetapkan.
                </li>
                <li>
                  Hentikan pemakaian bila timbul reaksi alergi yang tidak
                  diinginkan.
                </li>
                <li>
                  Jangan konsumsi jika memiliki alergi terhadap bahan-bahan yang
                  tertera di komposisi.
                </li>
                <li>
                  Konsultasikan dengan dokter sebelum penggunaan jika sedang
                  dalam masa pengobatan, kondisi perawatan atau ibu hamil.
                </li>
                <li>
                  Konsultasikan dengan dokter untuk Batas Atas Asupan dan
                  Interaksi Suplemen jika mengkonsumsi lebih dari satu jenis
                  Suplemen.
                </li>
                <li>
                  Tutup rapat setelah dibuka, konsumsi segera setelah dibuka
                  dengan mengikuti aturan pakai yang telah ditetapkan.
                </li>
              </ul>
            );
          },
        },
      ],
    },
  },
  {
    id: 6,
    name_product: "Multi Vitamins",
    slug: "multi-vitamins",
    subtitle:
      "Mengandung 11 jenis Vitamins, Bioflavonoid dan β-Carotene dalam 1 kapsul sehingga praktis dikonsumsi untuk memenuhi asupan nutrisi harian tubuh serta efektif menjaga imunitas tubuh agar tidak mudah sakit.",
    img: "/images/products/multi-vit.png",
    img2: "/images/our-product/multi-vit.png",
    img_banner: "/images/products/multi-vit-banner.png",
    img_banner_sm: "/images/products/multi-vit-banner-sm.png",
    color: "#F68A50",
    homepages: "yes",
    bg_img: "/images/products/multi-vit-bg.svg",
    bg_img_sm: "/images/products/multi-vit-bg-sm.svg",
    content: {
      img_front: "/images/products/multi-vit.png",
      img_back: "/images/products/multi-vit-back.png",
      img_object: "/images/products/multi-vit-object.png",
      description:
        "DHC Multi Vitamins mengandung 11 jenis Vitamins, Bioflavonoid dan β-Carotene dalam 1 kapsul sehingga praktis dikonsumsi untuk memenuhi asupan nutrisi harian tubuh yang tidak tercukupi dari pola makan sehari-hari dan efektif menjaga imunitas tubuh agar tidak mudah sakit.",
      list: [
        "Untuk Memenuhi Kebutuhan Vitamin Harian.",
        "11 Vitamin + Bioflavonoid + β-Carotene di dalam 1 Kapsul.",
      ],
      fyi: "Bioflavonoid / Fitonutrien adalah zat alami yang terkandung pada tanaman yang memiliki komponen fenolik yang bersifat antioksidan, antiinflamasi, dan antikarsinogenik. Sifat Bioflavonoid tersebut bermanfaat untuk menurunkan resiko penyakit jantung dan diabetes, mencegah Alzheimer dan parkinson, serta mencegah penuaan dini.",
      badge: [
        {
          badge_name: "No.1 Market Share",
          badge_img: "/images/universal/no-1-market-share.png",
          badge_description:
            "Market Share No. 1 di segmen Vitamin & Mineral, Category Multi Vitamins. \n[H.B Foods Marketing Handbook 2023 No.1] Fuji Keizai. \n(based on total sales in 2021)",
        },
      ],
      usage: [
        {
          id: 1,
          usage_title: "7 hari",
        },
      ],
      icon: [
        {
          img: "/images/universal/bpom.png",
          text: "BPOM Certified",
        },
        {
          img: "/images/universal/japan.png",
          text: "Made In Japan",
        },
      ],
      shop_list: [
        {
          id: 1,
          shop_name: "Shopee",
          shop_img: "/images/partners/shopee.png",
          shop_url:
            "https://shopee.co.id/DHC-Multi-Vitamins-i.697830791.14857348510?sp_atk=fe6cfeeb-0aa2-4b59-b03a-f491658b5253&xptdk=fe6cfeeb-0aa2-4b59-b03a-f491658b5253",
        },
        {
          id: 2,
          shop_name: "Lazada",
          shop_img: "/images/partners/lazada.png",
          shop_url:
            "https://www.lazada.co.id/products/dhc-multi-vitamins-i6245218117-s11865574908.html?spm=a2o4j.8553159.0.0.50ee1c53JS5XDD&priceCompare=skuId%3A11865574908%3Bsource%3Alzd-shop-center%3Bsn%3A287605cc-6656-4e52-9ba0-6b5ad34c8a4e%3BoriginPrice%3A2666000%3BvoucherPrice%3A2666000%3Btimestamp%3A1676271860601&search=store",
        },
        {
          id: 3,
          shop_name: "Tokopedia",
          shop_img: "/images/partners/tokopedia.png",
          shop_url:
            "https://www.tokopedia.com/dhcsupplement/dhc-multi-vitamins?extParam=whid%3D13208526",
        },
        {
          id: 4,
          shop_name: "Tiktok Shop",
          shop_img: "/images/partners/tiktok-shop.png",
          shop_url: "https://vt.tiktok.com/ZS8fswSAV/",
        },
        {
          id: 5,
          shop_name: "Halodoc",
          shop_img: "/images/partners/halodoc.png",
          shop_url:
            "https://www.halodoc.com/obat-dan-vitamin/dhc-multi-vitamins-7-kapsul-lunak",
        },
        // {
        //   id: 2,
        //   shop_name: 'Kalcare',
        //   shop_img: '/images/partners/kalcare.png',
        //   shop_url: '',
        // },
        // {
        //   id: 3,
        //   shop_name: 'Kimia Farma',
        //   shop_img: '/images/partners/kimia-farma.png',
        //   shop_url: '',
        // },
        // {
        //   id: 6,
        //   shop_name: 'Ranch Market',
        //   shop_img: '/images/partners/ranch-market.png',
        //   shop_url: '',
        // },
        // {
        //   id: 7,
        //   shop_name: 'Apotek Roxy',
        //   shop_img: '/images/partners/apotek-roxy.png',
        //   shop_url: '',
        // },
        // {
        //   id: 8,
        //   shop_name: 'Century',
        //   shop_img: '/images/partners/century.png',
        //   shop_url: '',
        // },
      ],
      accordion: [
        {
          id: 1,
          title: "Aturan Pakai",
          icon: IconRules,
          content: () => {
            return (
              <p>
                1 Kapsul sehari.
                <br />
                Dapat diminum dengan air biasa atau air hangat.
              </p>
            );
          },
        },
        {
          id: 2,
          title: "Komposisi",
          icon: IconComposition,
          content: () => {
            return (
              <>
                <p>1 Kapsul mengandung:</p>
                <ul>
                  <li>Niacinamide 15mg.</li>
                  <li>Biotin 45μg.</li>
                  <li>Vitamin B1 Mononitrate 2.2mg.</li>
                  <li>Vitamin B2 (Riboflavin) 2.4mg.</li>
                  <li>Vitamin B6 (Pyridoxine HCL) 3.2mg.</li>
                  <li>Vitamin C 100mg.</li>
                  <li>Vitamin E (D-α-Tocopherol) 10mg.</li>
                  <li>Folic Acid 200μg.</li>
                  <li>Vitamin D 5μg (200 IU).</li>
                  <li>Calcium Pantothenate 9.2mg.</li>
                  <li>Vitamin B12 (Cyanocobalamin) 6μg.</li>
                  <li>Hesperidin 10mg.</li>
                  <li>Rutin 25mg.</li>
                  <li>β-Carotene 5,400μg.</li>
                </ul>
              </>
            );
          },
        },
        {
          id: 3,
          title: "Direkomendasikan mengkonsumsi Multi Vitamins",
          icon: IconRisk,
          content: () => {
            return (
              <div className="flex flex-col space-y-5 text-justify">
                <ul>
                  <li>
                    Orang yang ingin mendapatkan beberapa jenis Vitamin dalam
                    sekali minum.
                  </li>
                  <li>Kelompok dengan kondisi avitaminosis.</li>
                  <li>
                    Orang dengan aktifitas padat serta sering terpapar radikal
                    bebas.
                  </li>
                  <li>Orang yang mengkonsumsi alkohol.</li>
                  <li>Orang dengan diet ketat atau nutrisi tidak seimbang.</li>
                  <li>Orang yang mengkonsumsi obat-obatan jangka panjang.</li>
                  <li>
                    Orang dengan kondisi medis khusus sehingga sulit mencerna
                    dan menyerap makanan.
                  </li>
                  <li>
                    Ibu hamil dan menyusui yang membutuhkan jumlah vitamin lebih
                    tinggi.
                  </li>
                  <li>
                    Orang yang ingin menjaga kesehatan dan kebugaran tubuh.
                  </li>
                </ul>
                <p className="text-xs xl:text-sm">
                  *Salah satu kandungan dari DHC Multi Vitamins adalah Rutin,
                  yang merupakan turunan senyawa Bioflavonoid, dan Bioflavonoid
                  banyak ditemukan pada bahan makanan yang berasal dari
                  tumbuhan, termasuk buah-buahan. Walaupun Kandungan Rutin
                  memiliki manfaat dan peranan besar bagi kesehatan tubuh, ibu
                  hamil atau menyusui disarankan untuk dikonsultasikan dengan
                  dokter terlebih dahulu demi mencegah adanya kondisi medis
                  tertentu.
                </p>
              </div>
            );
          },
        },
        {
          id: 4,
          title: "Peringatan",
          icon: IconWarning,
          content: () => {
            return (
              <ul>
                <li>
                  Jangan dikonsumsi melebihi aturan pakai yang telah ditetapkan.
                </li>
                <li>
                  Hentikan pemakaian bila timbul reaksi alergi yang tidak
                  diinginkan.
                </li>
                <li>
                  Jangan konsumsi jika memiliki alergi terhadap bahan-bahan yang
                  tertera di komposisi.
                </li>
                <li>
                  Konsultasikan dengan dokter sebelum penggunaan jika sedang
                  dalam masa pengobatan, kondisi perawatan atau ibu hamil.
                </li>
                <li>
                  Konsultasikan dengan dokter untuk Batas Atas Asupan dan
                  Interaksi Suplemen jika mengkonsumsi lebih dari satu jenis
                  Suplemen.
                </li>
                <li>
                  Tutup rapat setelah dibuka, konsumsi segera setelah dibuka
                  dengan mengikuti aturan pakai yang telah ditetapkan.
                </li>
              </ul>
            );
          },
        },
      ],
    },
  },
  // {
  //   id: 7,
  //   name_product: 'Perfect Vegetables',
  //   slug: 'perfect-vegetables',
  //   subtitle: 'Mengandung 1000mg Vitamin C yang setara dengan kandungan Vitamin C 50 buah Lemon Jepang. Membantu meningkatkan imun tubuh, tinggi antioksidan dan mencegah tanda penuaan dini pada kulit.',
  //   img: '/images/products/perfect-veg.png',
  //   img2: '/images/our-product/perfect-veg.png',
  //   img_banner: '/images/products/vit-c-banner.png',
  // color: '#50a831',
  // bg_img: '/images/products/-bg.svg',
  // bg_img_sm: '/images/products/-bg-sm.svg',//
  //   content: {
  //     img_front: '/images/products/perfect-veg.png',
  //     img_back: '/images/products/folic-acid-back.png',
  //     img_object: '/images/products/folic-acid-object.png',
  //     p1: 'Kekurangan Vitamin C berdampak buruk bagi kesehatan jaringan tubuh seperti gusi berdarah, sariawan, muncul tanda penuaan kulit, imun tubuh lemah sehingga mudah sakit, penyembuhan luka yang lama serta mudah lelah',
  //     p2: 'DHC Vitamin C + B2 mengandung 1000mg Vitamin C setara dengan vit. C pada 33 buah Lemon dilengkapi dengan vitamin B2 terbukti mampu mencukupi kebutuhan harian Vitamin C pada tubuh anda.',
  // p3: 'Kelompok resiko tinggi kekurangan Vitamin C:',
  // fyi:
  //   ""
  // ],//
  //     list: [
  //       'Orang dengan aktifitas padat serta sering terpapar radikal bebas',
  //       'Orang yang mengkonsumsi alkohol',
  //       'Orang dengan diet ketat atau nutrisi tidak seimbang',
  //       'Orang yang mengkonsumsi obat-obatan jangka panjang',
  //       'Orang dengan kondisi medis khusus sehingga sulit mencerna dan menyerap makanan',
  //       'Ibu hamil dan menyusui yang membutuhkan jumlah vitamin lebih tinggi',
  //     ],
  // badge: [],
  // icon: [],
  //       {
  //     img: '/images/universal/bpom.png',
  //         text: 'BPOM Certified',
  //       },
  //       {
  //     img: '/images/universal/japan.png',
  //         text: 'Made In Japan',
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 8,
  //   name_product: 'Chitosan',
  //   slug: 'chitosan',
  //   subtitle: 'Mengandung 1000mg Vitamin C yang setara dengan kandungan Vitamin C 50 buah Lemon Jepang. Membantu meningkatkan imun tubuh, tinggi antioksidan dan mencegah tanda penuaan dini pada kulit.',
  //   img: '/images/products/chitosan.png',
  //   img2: '/images/our-product/chitosan.png',
  //   img_banner: '/images/products/multi-vit-banner.png',
  // color: '#f16047',
  // bg_img: '/images/products/-bg.svg',
  // bg_img_sm: '/images/products/-bg-sm.svg',//
  //   content: {
  //     img_front: '/images/products/chitosan.png',
  //     img_back: '/images/products/collagen-back.png',
  //     img_object: '/images/products/collagen-object.png',
  //     p1: 'Collagen merupakan protein paling melimpah dalam tubuh yang membentuk 90% jaringan ikat, 90% massa tulang organik dan 70% kulit. Namun seiiring bertambahnya usia produksi kolagen dalam tubuh menurun sehingga menyebabkan berbagai masalah kesehatan seperti elastisitas kulit menurun, muncul tanda penuaan dini, kulit kering dan tampak keriput, nyeri sendi, rambut rontok dan menipis, muncul selulit dan sebagainya.',
  //     p2: 'DHC Collagen + B1 and B2 mengandung Fish Collagen Peptide yang memiliki bentuk lebih kecil sehingga lebih mudah diserap tubuh dibandingkan dengan Collagen biasa dan dilengkapi dengan Vitamin B1 dan B2 mampu bekerja sinergi menjaga kesehatan kulit, syaraf, mata dan sel darah merah.  Uji klinis membuktikan bahwa suplementasi Collagen Peptide dapat meningkatkan hidrasi, elastisitas dan kepadatan kulit sehingga penampilan kulit dapat meningkat secara signifikan dan dampak buruk lainnya karena kekurangan Collagen dapat dihindari.',
  // p3: 'Kelompok resiko tinggi kekurangan Collagen:',
  //   fyi:
  //     ""
  //   ],//
  //     list: [
  //       'Usia di atas 25 tahun cenderung kehilangan 1,5% simpanan kolagen tiap tahunnya',
  //       'Orang yang sering terpapar sinar UV',
  //       'Orang yang mengkonsumsi alkohol',
  //       'Orang yang konsumsi tinggi gula dan makanan olahan denaturasi',
  //       'Perokok aktif dan atau yang sering terpapar asap rokok',
  //     ],
  // badge: [],
  // icon: []
  //       {
  //     img: '/images/universal/bpom.png',
  //         text: 'BPOM Certified',
  //       },
  //       {
  //     img: '/images/universal/japan.png',
  //         text: 'Made In Japan',
  //       },
  //     ],
  //   },
  // },
];

export const partnersData = [
  {
    id: 1,
    name_partner: "Ranch Market",
    img1: "/images/partners/ranch-market.png",
    img2: "/images/partners/ranch-market-bw.png",
    category: "partner",
    link_url: null,
    location: [
      {
        id: 1,
        location_title: "Jakarta",
        location_list: [
          "Puri Kembangan, Jakarta Barat, DKI Jakarta 10560",
          "Cilandak, Jakarta Selatan, DKI Jakarta, 12620.",
        ],
      },
    ],
  },
  {
    id: 2,
    name_partner: "Century",
    img1: "/images/partners/century.png",
    img2: "/images/partners/century-bw.png",
    category: "partner",
    link_url: null,
    location: [
      {
        id: 1,
        location_title: "Jakarta",
        location_list: [
          "Puri Kembangan, Jakarta Barat, DKI Jakarta 10560",
          "Cilandak, Jakarta Selatan, DKI Jakarta, 12620.",
        ],
      },
      {
        id: 2,
        location_title: "Bogor",
        location_list: ["Sentul, Bogor Kota, Jawa Barat 16810"],
      },
    ],
  },
  // {
  //   id: 4,
  //   name_partner: 'Kimia Farma',
  //   img1: '/images/partners/kimia-farma.png',
  // },
  {
    id: 4,
    name_partner: "Apotek Roxy",
    img1: "/images/partners/apotek-roxy.png",
    img2: "/images/partners/apotek-roxy-bw.png",
    category: "partner",
    link_url: null,
    location: [
      {
        id: 1,
        location_title: "Jakarta",
        location_list: [
          "Puri Kembangan, Jakarta Barat, DKI Jakarta 10560",
          "Cilandak, Jakarta Selatan, DKI Jakarta, 12620.",
        ],
      },
    ],
  },
  // {
  //   id: 6,
  //   name_partner: 'Kalcare',
  //   img1: '/images/partners/kalcare.png',
  //   img2: '/images/partners/kalcare-bw.png',
  // },
  {
    id: 6,
    name_partner: "Halodoc",
    img1: "/images/partners/halodoc.png",
    img2: "/images/partners/halodoc-bw.png",
    category: "marketplace",
    link_url: "https://www.halodoc.com/obat-dan-vitamin/search/DHC",
    location: null,
  },
  {
    id: 7,
    name_partner: "Shopee",
    img1: "/images/partners/shopee-n-logo.png",
    img2: "/images/partners/shopee-n-logo-bw.png",
    category: "marketplace",
    link_url: "https://shopee.co.id/dhcsupplement",
    location: null,
  },
  {
    id: 8,
    name_partner: "Tokopedia",
    img1: "/images/partners/tokopedia-n-logo.png",
    img2: "/images/partners/tokopedia-n-logo-bw.png",
    category: "marketplace",
    link_url: "https://www.tokopedia.com/dhcsupplement",
    location: null,
  },
  {
    id: 9,
    name_partner: "Lazada",
    img1: "/images/partners/lazada-n-logo.png",
    img2: "/images/partners/lazada-n-logo-bw.png",
    category: "marketplace",
    link_url: "https://www.lazada.co.id/shop/dhc-supplement-official-store",
    location: null,
  },
  {
    id: 10,
    name_partner: "Tiktok Shop",
    img1: "/images/partners/tiktok-shop.png",
    img2: "/images/partners/tiktok-shop-bw.png",
    category: "marketplace",
    link_url: "https://tiktok.com",
    location: null,
  },
];

export const DataFAQ = [
  {
    id: 1,
    question: "DHC produk dari mana?",
    answer: "DHC Corporation berasal dari Jepang.",
  },
  {
    id: 2,
    question: "Apakah Produk DHC sudah terdaftar di BPOM?",
    answer:
      "Seluruh produk Suplemen Kesehatan DHC yang kami pasarkan sudah resmi terdaftar di BPOM.",
  },
  {
    id: 3,
    question: "Apa keunggulan DHC Suplemen?",
    answer:
      "Semua produk DHC adalah hasil dari serangkaian riset panjang dari Team Internal DHC sebelum produk release di pasaran. DHC hanya menggunakan bahan baku yang sudah teruji aman dan berkualitas tinggi, di produksi di pabrik yang telah terstandard GMP (Good Manufacturing Practice) di Jepang, dan hasilnya adalah produk kami menjadi Suplemen Kesehatan No.1 di Jepang dan Suplemen Kesehatan yang dicintai di berbagai negara.",
  },
  {
    id: 4,
    question: "Apa itu DHC Suplemen?",
    answer:
      "DHC Suplemen adalah produk Suplemen Kesehatan dari Jepang yang di produksi oleh DHC Corporation. DHC Suplemen merupakan Suplemen Kesehatan No.1 di Jepang, terbuat dari bahan baku yang berkualitas, aman, mengikuti standard [DHC Safety Standards] dan telah lulus kajian Quality Control secara menyeluruh oleh DHC Corporation.",
  },
  {
    id: 5,
    question: "Di negara mana saja Produk DHC dipasarkan?",
    answer:
      "Saat ini produk DHC telah dipasarkan diberbagai negara dan terus berkembang :\nJapan, China, Taiwan, Hongkong, Thailand, Cambodia, Vietnam, Malaysia, Singapore, Indonesia, UAE, Saudi Arabia, Oman, Qatar, Kuwait, Bahrain, Jordan, Lebanon, Egypt, United States, Canada, United Kingdom, France, Belgium, Netherlands, Germany dan Georgia.\n*Berbagai macam produk DHC, tidak terbatas kepada produk Suplemen Kesehatan",
  },
  {
    id: 6,
    question: "Apakah Produk Suplemen Kesehatan DHC sudah terdaftar di BPOM?",
    answer:
      "Seluruh produk Suplemen Kesehatan DHC yang kami pasarkan telah terdaftar di BPOM. ",
  },
  {
    id: 7,
    question: "DHC produk dari mana?",
    answer: "DHC Corporation berasal dari Jepang. ",
  },
  {
    id: 8,
    question:
      "Apakah Produk DHC yang di Indonesia berbeda dengan yang dari luar negeri?",
    answer:
      "Produk DHC Indonesia telah diformulasikan sesuai Standard DHC Corporation. Semua produk resmi di Indonesia sudah melalui kajian dan sesuai dengan peraturan BPOM.",
  },
  {
    id: 9,
    question: "Kapsul DHC terbuat dari apa?",
    answer: "Gelatin Bovine (Sapi)",
  },
];

export const DataAllFAQ = [
  {
    id: 1,
    faq_type: "Produk",
    faq_type_icon: IconProductFill,
    faq_list: [
      {
        id: 1,
        question: "DHC produk dari mana?",
        answer: "DHC Corporation berasal dari Jepang.",
        icon: IconProductOutline,
      },
      {
        id: 2,
        question: "Produk apa saja yang dijual DHC?",
        answer:
          "Produk DHC yang kami sediakan berfokus pada produk Suplemen Kesehatan dengan variasi produk yang beragam dan akan terus bertambah seiring dengan perkembangan kebutuhan pasar di Indonesia.",
        icon: IconProductOutline,
      },
      {
        id: 3,
        question: "Apa itu DHC Suplemen?",
        answer:
          "DHC Suplemen adalah produk Suplemen Kesehatan dari Jepang yang di produksi oleh DHC Corporation. DHC Suplemen merupakan Suplemen Kesehatan No.1 di Jepang, terbuat dari bahan baku yang berkualitas, aman, mengikuti standard [DHC Safety Standards] dan telah lulus kajian Quality Control secara menyeluruh oleh DHC Corporation.",
        icon: IconProductOutline,
      },
      {
        id: 4,
        question: "Di negara mana saja Produk DHC dipasarkan?",
        answer:
          "Saat ini produk DHC telah dipasarkan diberbagai negara dan terus berkembang :\nJapan, China, Taiwan, Hongkong, Thailand, Cambodia, Vietnam, Malaysia, Singapore, Indonesia, UAE, Saudi Arabia, Oman, Qatar, Kuwait, Bahrain, Jordan, Lebanon, Egypt, United States, Canada, United Kingdom, France, Belgium, Netherlands, Germany dan Georgia.\n*Berbagai macam produk DHC, tidak terbatas kepada produk Suplemen Kesehatan.",
        icon: IconProductOutline,
      },
      {
        id: 5,
        question: "Apa keunggulan DHC Suplemen?",
        answer:
          "Semua produk DHC adalah hasil dari serangkaian riset panjang dari Team Internal DHC sebelum produk release di pasaran. DHC hanya menggunakan bahan baku yang sudah teruji aman dan berkualitas tinggi, di produksi di pabrik yang telah terstandard GMP (Good Manufacturing Practice) di Jepang, dan hasilnya adalah produk kami menjadi Suplemen Kesehatan No.1 di Jepang dan Suplemen Kesehatan yang dicintai di berbagai negara.",
        icon: IconProductOutline,
      },
      {
        id: 6,
        question: "Bagaimana mengetahui Produk DHC yang resmi di Indonesia?",
        answer:
          "Produk DHC yang resmi di Indonesia memiliki penandaan berbahasa Indonesia pada kemasannya, dilengkapi dengan nomor ijin edar BPOM dan QR Code yang dapat di scan di aplikasi BPOM.",
        icon: IconProductOutline,
      },
      {
        id: 7,
        question: "Dimana saya bisa membeli Produk DHC?",
        answer:
          "Produk Suplemen Kesehatan DHC yang resmi dapat diperoleh di berbagai Offline Store dan Online Store dari Toko Official DHC Suplemen di berbagai marketplace.",
        icon: IconProductOutline,
      },
    ],
  },
  {
    id: 2,
    faq_type: "Kandungan",
    faq_type_icon: IconMedicine,
    faq_list: [
      {
        id: 8,
        question:
          "Apakah Produk DHC yang di Indonesia berbeda dengan yang dari luar negeri?",
        answer:
          "Produk DHC Indonesia telah diformulasikan sesuai Standard DHC Corporation.\nSemua produk resmi di Indonesia sudah melalui kajian dan sesuai dengan peraturan BPOM.",
        icon: IconMedicine,
      },
      {
        id: 9,
        question:
          "Apakah Produk DHC menggunakan gelatin? Apa jenis & sumber gelatin yang digunakan?",
        answer:
          "Produk DHC Indonesia hanya menggunakan gelatin dari Bovine (sapi). Gelatin sapi yang Produk DHC Indonesia gunakan sesuai dengan ketentuan halal dan Produk DHC Indonesia tidak tidak menggunakan gelatin yang bersumber dari Porcine (babi).",
        icon: IconMedicine,
      },
      {
        id: 10,
        question: "Apakah Produk DHC mengandung alcohol?",
        answer:
          "Tidak ada Produk Suplemen Kesehatan DHC Indonesia yang mengandung alcohol.",
        icon: IconMedicine,
      },
    ],
  },
  {
    id: 3,
    faq_type: "Keamanan",
    faq_type_icon: IconSafety,
    faq_list: [
      {
        id: 11,
        question: "Apakah Produk DHC sudah terdaftar di BPOM?",
        answer:
          "Seluruh produk Suplemen Kesehatan DHC yang kami pasarkan sudah resmi terdaftar di BPOM.",
        icon: IconSafety,
      },
      {
        id: 12,
        question: "Apakah aman untuk Ibu Hamil dan Menyusui?",
        answer:
          "Secara umum Suplemen Kesehatan DHC aman dikonsumsi oleh seluruh anggota keluarga termasuk Ibu Hamil dan Menyusui. Untuk lebih jelasnya, silahkan baca keterangan yang tertera di belakang kemasan masing-masing jenis produk atau di halaman Our Product.",
        icon: IconSafety,
      },
      {
        id: 13,
        question: "Apakah saya perlu konsumsi Suplemen Kesehatan?",
        answer:
          "Suplemen Kesehatan DHC bersifat sebagai suplementasi, jika asupan nutrisi anda sudah tercukupi dengan baik dan terukur dengan jelas maka tidak perlu mengkonsumsi suplemen. \n\nNamun pada umumnya karena gaya hidup modern yang mendorong orang-orang untuk bergerak lebih sedikit dan mengonsumsi lebih banyak makanan yang tidak memiliki nutrisi seimbang, ditambah dengan kondisi tubuh yang padat aktifitas, terpapar sinar matahari seharian, lelah bekerja seharian dan asupan nutrisi harian yang kurang terkontrol dengan baik maka tubuh rentan kekurangan nutrisi; baik vitamin, mineral maupun serat yang sangat dibutuhkan oleh tubuh, sehingga mengkonsumsi Suplemen Kesehatan adalah salah satu solusi terbaik untuk tetap dapat mencukupi kebutuhan tubuh.",
        icon: IconSafety,
      },
      {
        id: 14,
        question:
          "Apakah saya boleh langsung mengkonsumsi dosis harian dalam sekali minum?",
        answer:
          "Sebaiknya dalam dosis terbagi atau sesuai dengan aturan pakai yang tercantum pada kemasan produk.",
        icon: IconSafety,
      },
      {
        id: 15,
        question:
          "Mengapa ada produk yang dosis harian terbagi menjadi beberapa tablet atau kapsul?",
        answer:
          "(1). Ukuran tablet atau kapsul yang lebih kecil sehingga memudahkan konsumsi, tanpa khawatir 'Nyangkut' atau Tersedak (Stress Free).\n(2). Dosis suplemen DHC telah didesain dan diformulasikan sedemikian rupa oleh RnD DHC agar kamu dapat memperoleh efektifitas dari suplemen semaksimal mungkin saat dikonsumsi sesuai dengan aturan pakai.\n(3). Dosis terbagi diharapkan dapat meminimalisir terjadinya interaksi saat dikonsumsi bersamaan dengan suplemen lainnya dan atau saat dikonsumsi bersamaan dengan makanan.\n(4). Beberapa jenis vitamin, misalnya vitamin yang larut dalam air cenderung cepat diserap dan dilepaskan oleh tubuh, sehingga pemberian dalam dosis terbagi diharapkan dapat terserap optimal oleh tubuh.",
        icon: IconSafety,
      },
      {
        id: 16,
        question: "Bagaimana cara menyimpan Produk DHC yang benar?",
        answer:
          "Tutup rapat setelah dibuka, Simpan di tempat kering, terhindar dari sinar matahari langsung dengan suhu dibawah 30oC",
        icon: IconSafety,
      },
      {
        id: 17,
        question: "Apakah ada efek samping pada pemakaian yang terus menerus?",
        answer:
          "Suplemen Kesehatan DHC sudah diformulasikan sesuai dengan kebutuhan harian tubuh sehingga aman pada penggunaan jangka panjang sekalipun. Tetap konsumsi Suplemen Kesehatan DHC sesuai dengan aturan pakai yang tertera dikemasan atau konsultasikan pada dokter terkait kebutuhan dan kondisi anda.",
        icon: IconSafety,
      },
      {
        id: 18,
        question: "Apakah Suplemen Kesehatan DHC menyebabkan ketergantungan?",
        answer:
          "Tentu tidak. Vitamin dan mineral tidak dapat diproduksi sendiri oleh tubuh sehingga manusia harus mendapat asupan vitamin dan mineral dari luar tubuh. Jika seseorang yang konsumsi suplemen secara rutin merasa tubuh kurang fit saat tidak mengkonsumsi, itu bukan disebabkan oleh ketergantungan. Itu salah satu indikasi kondisi tubuh kekurangan vitamin dan mineral yang tidak tercukupi oleh diet / nutrisi sehari-hari sehingga perlu dibantu oleh suplemen.",
        icon: IconSafety,
      },
    ],
  },
  {
    id: 4,
    faq_type: "Kemasan",
    faq_type_icon: IconPackagingFill,
    faq_list: [
      {
        id: 19,
        question:
          "Kenapa Gambar/Visual di kemasan Bahasa Jepang dan Bahasa Indonesia sedikit berbeda?",
        answer:
          "Gambar/Visual di kemasan Bahasa Jepang dan Bahasa Indonesia sedikit berbeda dikarenakan adanya perbedaan regulasi di negara Jepang dan Indonesia.",
        icon: IconPackagingOutline,
      },
      {
        id: 20,
        question: "Kenapa Produk DHC memakai kemasan pouch?",
        answer:
          "Kelebihan dari kemasan pouch DHC adalah spesifikasinya yang tahan terhadap air, kelembaban dan cahaya, sehingga membuat isi dari produk DHC tersimpan dengan baik sampai dengan masa expire-nya. Selain itu, ukurannya yang kecil dan praktis membuat kemasan pouch cocok untuk disimpan di rumah maupun dibawa ketika anda bepergian. Jika tanpa sengaja terjatuh juga tidak mengakibatkan kerusakan pada kemasan.",
        icon: IconPackagingOutline,
      },
    ],
  },
];

export const DataMenu = [
  {
    id: 1,
    name: "Home",
    slug: "home",
    menu_header: true,
  },
  {
    id: 2,
    name: "About Us",
    slug: "about-us",
    menu_header: true,
  },
  {
    id: 3,
    name: "Our Product",
    slug: "our-product",
    menu_header: true,
  },
  {
    id: 4,
    name: "Article",
    slug: "article",
    menu_header: true,
  },
  {
    id: 5,
    name: "Activity",
    slug: "activity",
    menu_header: true,
  },
  {
    id: 6,
    name: "Contact Us",
    slug: "contact-us",
    menu_header: true,
  },
  {
    id: 7,
    name: "FAQ",
    slug: "faq",
    menu_header: false,
  },
];

export const DataTestimonial = [
  {
    id: 1,
    name: "Monica Wijaya",
    image: "/images/products/forskohlii-model.png",
    description:
      "Suplemen yang sering aku bawa kemana-mana karena kemasannya praktis, cute, tablet nya tidak besar jadi mudah dikonsumi dan udah BPOM jadi tenang, Suplemen ini tuh berguna bgt buat aku yang suka makan makanan tinggi lemak tapi harus jaga penampilan tetap ramping karena bisa bantu membakar lemak tubuh lebih efektif.",
    desc_long: true,
    product_name: "DHC Forskohlii + B1, B2 and B6",
  },
  {
    id: 2,
    name: "Naura Khairunnisa",
    image: "/images/products/vit-c-model.png",
    description:
      "Aku cocok banget sama Vitamin C dan Multi Vitamins dari DHC ini, selama puasa selalu minum Vitamin C dan aman di lambung aku. Ada 11 jenis Vitamin dan Bioflavonoid dalam 1 kapsul DHC Multi Vitamins jadi lengkap bgt, praktis dan bikin badan tetap fit meskipun seharian padat aktifitas.",
    desc_long: false,
    product_name: "DHC Vitamin C & DHC Multivitamins",
  },
  {
    id: 3,
    name: "Marliana Tan",
    image: "/images/products/folic-acid-model.png",
    description:
      "Bagus buat bumil dan Ibu menyusui, setiap hari aku minum, ngga bikin mual, tabletnya juga kecil jadi nggak stress minumnya. Bagus juga buat daya ingat jadi aku rekomen juga buat orang tua.",
    desc_long: false,
    product_name: "DHC Folic Acid + Vit B",
  },
  {
    id: 4,
    name: "Fiony Stacey",
    image: "/images/products/calcium-model.png",
    description:
      "Tabletnya bisa dikunyah dan rasa susu jadi nyaman dikonsumsi, ada tambahan CBP nya juga jadi tidak hanya bagus untuk pertumbuhan tapi juga bisa menguatkan dan meningkatkan kepadatan tulang terutama untuk usia saya yang tidak muda lagi.",
    desc_long: true,
    product_name: "DHC Calcium + CBP",
  },
  {
    id: 5,
    name: "Allysa Prameswari",
    image: "/images/products/folic-acid-model-2.png",
    description:
      "Aku rutin konsumsi DHC Folic Acid, selain bagus untuk jaga kesehatan janin juga mencegah anemia serta cegah mual muntah pada masa kehamilan aku. Semua suplemen DHC yang aku dan keluarga ku konsumsi juga udah BPOM jadi tenang, dijamin aman.",
    desc_long: true,
    product_name: "DHC Folic Acid + Vit B",
  },
];

export const DataReviewMarketplace = [
  {
    id: 1,
    image: "/images/review-marketplace/review-1.png",
  },
  {
    id: 2,
    image: "/images/review-marketplace/review-2.png",
  },
  {
    id: 3,
    image: "/images/review-marketplace/review-3.png",
  },
  {
    id: 4,
    image: "/images/review-marketplace/review-4.png",
  },
  {
    id: 5,
    image: "/images/review-marketplace/review-5.png",
  },
  {
    id: 6,
    image: "/images/review-marketplace/review-6.png",
  },
];
