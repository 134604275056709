import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollUp({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [pathname]);

  return <div className="overflow-hidden">{children}</div>;
}

export default ScrollUp;
