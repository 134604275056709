const sendBtnVariants = {
  Kirim: {
    x: 0,
    rotateZ: 0,
    transition: { duration: 1.25 },
  },
  Mengirim: {
    x: 60,
    opacity: 1,
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 1.2,
    },
  },
  'Mengirim.': {
    x: 1000,
    transition: {
      duration: 1.5,
    },
  },
  'Berhasil Terkirim': {
    x: 1000,
  },
  'Gagal Terkirim': {
    x: 1000,
  },
  'Berhasil Terkirim.': {
    x: 1000,
    rotateZ: 180,
  },
  'Berhasil Terkirim..': {
    x: 0,
    rotateZ: 180,
    transition: {
      duration: 1.25,
    },
  },
};

const textSendBtnVariants = {
  Kirim: {
    y: 0,
  },
  KirimIcon: {
    y: 50,
    display: 'none',
  },
  Mengirim: {
    y: 0,
  },
  MengirimIcon: {
    y: 50,
    display: 'none',
  },
  'Mengirim.': {
    y: 50,
  },
  'Mengirim.Icon': {
    y: 50,
    display: 'none',
  },
  'Berhasil Terkirim': {
    y: 0,
  },
  'Berhasil TerkirimIcon': {
    y: 0,
    display: 'block',
  },
  'Berhasil Terkirim.': {
    y: 50,
  },
  'Berhasil Terkirim.Icon': {
    y: 50,
  },
  'Berhasil Terkirim..': {
    y: 50,
  },
  'Berhasil Terkirim..Icon': {
    y: 50,
    display: 'none',
  },
};

const subsBtnVariants = {
  Subscribe: {
    x: 0,
    rotateZ: 0,
    transition: { duration: 1.25 },
  },
  Loading: {
    x: 20,
    opacity: 1,
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 1.2,
    },
  },
  'Loading.': {
    x: 800,
    transition: {
      duration: 2,
    },
  },
  Selesai: {
    x: 800,
    rotateZ: 180,
  },
  'Selesai.': {
    x: 0,
    rotateZ: 180,
    transition: {
      duration: 1.25,
    },
  },
};

const textSubsBtnVariants = {
  Subscribe: {
    y: 0,
  },
  Loading: {
    y: 0,
  },
  'Loading.': {
    y: 50,
  },
  Selesai: {
    y: 0,
  },
  'Selesai.': {
    y: 50,
  },
  'Selesai..': {
    y: 50,
  },
};

export {
  sendBtnVariants,
  textSendBtnVariants,
  subsBtnVariants,
  textSubsBtnVariants,
};
