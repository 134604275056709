import React from 'react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { ease: 'easeInOut', duration: 1 },
  },
};

const IconRibbon = (props) => (
  <motion.svg
    viewBox="0 0 184 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    initial="hidden"
    animate={props.isHovered === props.bdgIndex + 1 ? 'visible' : 'hidden'}
    onMouseEnter={() => props.hoverEnter(props.bdgIndex, props.badgeName)}
    onMouseLeave={props.hoverLeave}
  >
    <motion.path
      d="M43.5784 107.88C42.0774 102.241 37.2019 93.0829 29.7073 101.568C20.3391 112.174 30.1427 117.086 34.8457 113.603C39.7339 109.983 28.6491 85.7131 13.6426 108.947C11.6602 112.457 7.19531 120.377 5.19531 123.977"
      stroke="#A7CAFC"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
    <motion.path
      d="M43.7736 80.7193C41.2661 75.4498 34.7972 67.3389 28.9817 77.0518C21.7123 89.1929 32.2492 92.2285 36.2355 87.9438C40.3788 83.4903 25.0403 61.6585 14.5385 87.2458C13.1923 89.9972 10.4 96.4 10 100"
      stroke="#F3D673"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
    <motion.path
      d="M52.7301 67.8264C52.435 63.389 49.3547 54.2215 42.462 56.3024C33.846 58.9036 39.3118 67.7691 43.5808 66.9326C48.5119 65.9663 53.2261 50.4411 39.9589 49.7459C26.6918 49.0507 25.7978 58.1995 30.16 60.5385C34.5222 62.8776 41.4401 47.732 27.5498 46.7026C22.3012 46.2614 10.404 47.0791 4.80402 53.8791"
      stroke="#E79E82"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
    <motion.path
      d="M140.552 107.88C142.053 102.241 146.929 93.0829 154.424 101.568C163.792 112.174 153.988 117.086 149.285 113.603C144.397 109.983 155.482 85.7131 170.488 108.947C172.471 112.457 176.936 120.377 178.936 123.977"
      stroke="#F3D673"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
    <motion.path
      d="M140.357 80.7193C142.865 75.4498 149.334 67.3389 155.149 77.0518C162.419 89.1929 151.882 92.2285 147.895 87.9438C143.752 83.4903 159.091 61.6585 169.592 87.2458C170.939 89.9972 173.731 96.4 174.131 100"
      stroke="#E79E82"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
    <motion.path
      d="M131.401 67.8264C131.696 63.389 134.776 54.2215 141.669 56.3024C150.285 58.9036 144.819 67.7691 140.55 66.9326C135.619 65.9663 130.905 50.4411 144.172 49.7459C157.439 49.0507 158.333 58.1995 153.971 60.5385C149.609 62.8776 142.691 47.732 156.581 46.7026C161.83 46.2614 173.727 47.0791 179.327 53.8791"
      stroke="#A7CAFC"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
  </motion.svg>
);

export default IconRibbon;
