import React from 'react';

function IconCheck(props) {
  return (
    <svg
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.828 3H5a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-6.325"
        stroke="#003994"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9.857l5.918 6.34a1 1 0 001.478-.017L26 2"
        stroke="#003994"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconCheck;
