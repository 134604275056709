import * as React from 'react';

function Close(props) {
  return (
    <svg
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.28 19.5a17.22 17.22 0 1134.439 0 17.22 17.22 0 01-34.44 0zM19.5 4.75a14.75 14.75 0 100 29.5 14.75 14.75 0 000-29.5zm6.12 8.632a1.3 1.3 0 010 1.838l-4.283 4.28 4.282 4.28a1.302 1.302 0 01-1.84 1.84l-4.28-4.282-4.28 4.282a1.303 1.303 0 01-2.122-1.418c.065-.158.16-.302.282-.422l4.282-4.28-4.282-4.28a1.302 1.302 0 011.84-1.84l4.28 4.282 4.28-4.282a1.3 1.3 0 011.84 0v.002z"
        fill={
          props.color ? props.color : 'url(#prefix__paint0_linear_1716_878)'
        }
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_1716_878"
          x1={2.279}
          y1={19.501}
          x2={36.719}
          y2={19.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#285DAA" />
          <stop offset={0.24} stopColor="#2759A3" />
          <stop offset={0.58} stopColor="#244E91" />
          <stop offset={0.97} stopColor="#203C74" />
          <stop offset={0.99} stopColor="#203C73" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Close;
