import React from 'react';
import { motion } from 'framer-motion';

const IconMenu = ({ className, open, handleOpen }) => (
  <motion.svg
    viewBox="0 0 61 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={handleOpen}
  >
    <motion.path
      d="M57 0H4C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8H57C59.2091 8 61 6.20914 61 4C61 1.79086 59.2091 0 57 0Z"
      fill="white"
      initial={{ opacity: 1 }}
      animate={open ? { opacity: 1, x: -80 } : { opacity: 1, x: 0 }}
    />
    <motion.path
      d="M57 36H4C1.79086 36 0 37.7909 0 40C0 42.2091 1.79086 44 4 44H57C59.2091 44 61 42.2091 61 40C61 37.7909 59.2091 36 57 36Z"
      fill="white"
      initial={{ opacity: 1 }}
      animate={open ? { opacity: 1, x: 80 } : { opacity: 1, x: 0 }}
    />
    <motion.path
      d="M57 18H4C1.79086 18 0 19.7909 0 22C0 24.2091 1.79086 26 4 26H57C59.2091 26 61 24.2091 61 22C61 19.7909 59.2091 18 57 18Z"
      fill="white"
      initial={{ rotate: 0 }}
      animate={open ? { rotate: 45 } : { rotate: 0 }}
    />
    <motion.path
      d="M57 18H4C1.79086 18 0 19.7909 0 22C0 24.2091 1.79086 26 4 26H57C59.2091 26 61 24.2091 61 22C61 19.7909 59.2091 18 57 18Z"
      fill="white"
      initial={{ rotate: 0 }}
      animate={open ? { rotate: -45 } : { rotate: 0 }}
    />
  </motion.svg>
);

export default IconMenu;
