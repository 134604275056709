import React, { useState } from 'react';
import './Accordion.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { HiChevronDown } from 'react-icons/hi';

// Framer Motion Variants
const collapseVariants = {
  open: { height: 'auto', zIndex: 10 },
  collapsed: { height: 0, zIndex: -10 },
};

const arrowVariants = {
  enter: { scale: 1.1, boxShadow: 'rgba(17, 17, 26, 0.32) 0px 0px 16px' },
  leave: { scale: 1, boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px' },
};

function Accordion({
  id,
  handleAccordion,
  accordion,
  title,
  children,
  lastChild,
  Icon,
}) {
  const [isHovered, setHovered] = useState(false);

  const hoverEnter = () => {
    setHovered(true);
  };

  const hoverLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={`accordion w-full flex flex-col ${
        accordion === id ? 'pb-3' : ''
      }`}
    >
      <button
        onMouseEnter={hoverEnter}
        onMouseLeave={hoverLeave}
        onClick={() => handleAccordion(id)}
        className={`flex items-center justify-between px-2 py-3 border-t  ${
          lastChild ? 'border-b' : ''
        } ${
          accordion === id ? 'accordion-active mb-3' : 'border-grayborder'
        } hover:bg-[#E9ECF1]`}
      >
        <div className="w-full flex items-center space-x-5 overflow-hidden">
          {/* <Icon className="w-11 h-11 xl:w-12 xl:h-12 shrink-0" /> */}
          <img
            src={Icon}
            alt={title}
            className="w-11 h-11 xl:w-12 xl:h-12 shrink-0"
          />
          <h5 className="max-w-[84%] text-lg xl:text-xl text-left font-bold text-ellipsis overflow-hidden whitespace-nowrap">
            {title}
          </h5>
        </div>
        <motion.div
          className={`shrink-0 rounded-full flex shadow-xl p-2 transition-all duration-200 ease-out ${
            accordion === id ? 'border border-grayoutline' : 'border-0'
          }`}
          style={{
            background: accordion === id ? 'transparent' : '#003994',
          }}
          variants={arrowVariants}
          initial="leave"
          animate={isHovered ? 'enter' : 'leave'}
        >
          <motion.span
            className={`w-4 h-4 xl:w-5 xl:h-5`}
            animate={accordion === id ? { rotate: -180 } : { rotate: 0 }}
          >
            <span
              className={`w-full h-auto ${
                accordion === id ? 'text-primary' : 'text-white'
              } transition-all duration-200 ease-out`}
            >
              <HiChevronDown className={`w-full h-auto`} />
            </span>
          </motion.span>
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {accordion === id && (
          <motion.div
            key="content"
            className="relative text-sm xl:text-base text-primary px-2 whitespace-pre-wrap"
            variants={collapseVariants}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children ? children : ''}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Accordion;
