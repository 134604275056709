import React, { useState } from 'react';
import { motion } from 'framer-motion';

const transition = {
  type: 'tween',
  duration: 0.2,
  ease: [0.7, -0.2, 0.4, 1.2],
};

function ArrowRight({ className, onClick, color }) {
  const [isHovered, setHovered] = useState(false);

  return (
    <svg
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle
        cx={75}
        cy={75}
        r={74}
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <motion.circle
        cx={75}
        cy={75}
        r={74}
        fill="#fff"
        fillOpacity={0}
        initial={{ scale: 0.9 }}
        animate={
          isHovered
            ? { fillOpacity: 1, scale: 1 }
            : { fillOpacity: 0, scale: 0.9 }
        }
        transition={transition}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.14 74.758l.26-.248-.26-.272v.52zm-.278.265L87.428 94.53l.69.723 21.334-20.365.362-.345-.346-.362-21.334-22.304-.722.691 20.523 21.456H40.258v1h67.604z"
        fill="#000000"
        animate={isHovered ? { fill: color } : { fill: '#000000' }}
        transition={transition}
      />
      <motion.path
        d="M108.4 74.51l.722-.692a.999.999 0 01-.032 1.415l-.69-.723zm-.26.248l.69.723a1 1 0 01-1.69-.723h1zm0-.52h-1a1 1 0 011.722-.692l-.722.692zm-.278.785v-1a1 1 0 01.69 1.724l-.69-.724zM87.428 94.53l-.724.69a1 1 0 01.033-1.414l.69.724zm.69.723l.69.723a1 1 0 01-1.413-.033l.723-.69zm21.334-20.365l-.69-.723.69.723zm.362-.345l.723-.691a1.002 1.002 0 01-.032 1.414l-.691-.723zm-.346-.362l-.722.691.722-.69zM88.134 51.876l-.691-.723a1 1 0 011.414.032l-.723.691zm-.722.691l-.723.691a1 1 0 01.031-1.413l.692.722zm20.523 21.456l.722-.691a1 1 0 01-.722 1.691v-1zm-67.677 0h-1a1 1 0 011-1v1zm0 1v1a1 1 0 01-1-1h1zm68.832.21l-.26.248-1.381-1.446.26-.249 1.381 1.447zm-.228-1.687l.26.272-1.445 1.383-.26-.272 1.445-1.383zm-1.722 1.212v-.52h2v.52h-2zm1.412.989L88.118 95.252l-1.38-1.447L107.17 74.3l1.381 1.447zM88.151 93.838l.69.724-1.446 1.38-.69-.723 1.446-1.38zm-.723.69l21.334-20.364 1.381 1.447-21.335 20.364-1.38-1.446zm21.334-20.364l.362-.346 1.381 1.447-.362.346-1.381-1.447zm.33 1.069l-.346-.362 1.445-1.382.346.362-1.445 1.382zm-.346-.362L87.412 52.567l1.445-1.382 21.334 22.304-1.445 1.382zM88.826 52.6l-.723.69-1.383-1.444.723-.692 1.382 1.446zm-.692-.723l20.523 21.456-1.445 1.383-20.523-21.457 1.445-1.382zm19.801 23.147H40.258v-2h67.677v2zm-66.677-1v1h-2v-1h2zm-1 0h67.604v2H40.258v-2z"
        fill="#000000"
        animate={isHovered ? { fill: color } : { fill: '#000000' }}
        transition={transition}
      />
    </svg>
  );
}

export default ArrowRight;
