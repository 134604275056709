import React, { useContext, useEffect, useState } from "react";
import "./ListActivity.scss";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";

import Layout from "../../components/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import mainContext from "../../context/MainContext";
import { MdSearch } from "react-icons/md";
import moment from "moment";

const activityLinks = [
  {
    page: "Home",
    link: "/",
  },
  {
    page: "Activity",
    link: "/activity",
  },
];

function ListActivity() {
  const { getActivity, assetsUrl2, searchActivity } = useContext(mainContext);
  const location = useLocation();

  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");
  const [year, setYear] = useState("all");

  const handleSearch = (e) => {
    e.preventDefault();

    searchActivity(setData, { src: search });
  };

  useEffect(() => {
    if (data === null) {
      getActivity(setData);
    }
  }, [data, getActivity]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://dhcsupplement.co.id/activity" />
        <title>Activity - DHC Supplement</title>
        <meta
          name="description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          name="keywords"
          content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
        />
        <meta property="og:title" content="Activity - DHC Supplement" />
        <meta
          property="og:description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          property="og:url"
          content="https://dhcsupplement.co.id/activity"
        />
      </Helmet>

      <Layout activeNav="activity">
        <main className="activity layout-gap">
          <Breadcrumb links={activityLinks} />

          <div className="activity__list py-8 sm:py-12 font-nunito-sans">
            <div className="w-11/12 lg:w-10/12 flex flex-col space-y-6 sm:space-y-10 mx-auto">
              <div className="activity__list_search w-full flex flex-col space-y-6 px-0 sm:px-3">
                <h5 className="w-full text-primary text-xl sm:text-3xl xl:text-3.1xl font-bold">
                  Our Activities
                </h5>

                <div className="w-full flex space-x-5">
                  <form onSubmit={handleSearch} className="w-full flex">
                    <input
                      className="w-full p-2 rounded-l-md border border-primary"
                      type="text"
                      placeholder="Cari aktifitas berdasarkan judul"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        searchActivity(setData, { src: e.target.value });

                        if (e.target.value === "") {
                          getActivity(setData);
                        }
                      }}
                      value={search}
                    />

                    <button
                      className="w-auto flex space-x-1 items-center px-4 py-2 bg-primary hover:bg-light-primary border border-primary text-white shrink-0 rounded-r-md transition-hover"
                      type="submit"
                    >
                      <MdSearch className="text-white w-5 h-5" />
                      <span>Cari</span>
                    </button>
                  </form>

                  <div className="relative w-48 flex items-center border border-primary bg-white rounded-md">
                    <select
                      className="w-full rounded-md text-center focus:outline-none"
                      name="activity_year"
                      id="activity_year"
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                      value={year}
                    >
                      <option value="all">Semua Tahun</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                    </select>
                  </div>
                </div>
              </div>

              {data === null || data?.status === "error" ? (
                <div className="w-full h-[10vh] flex justify-center items-center">
                  <h5 className="text-lg text-primary font-semibold">
                    {data?.msg || "Data tidak ditemukan"}
                  </h5>
                </div>
              ) : (
                <div className="w-full flex flex-col sm:flex-row sm:flex-wrap">
                  {data?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={`${location.pathname}/${item?.slug}`}
                        className="activityCard w-full sm:w-[48%] lg:w-[31.3%] sm:mx-[1%] mb-6 flex flex-col border border-slate-200 rounded-lg shadow-md cursor-pointer overflow-hidden"
                      >
                        <div className="w-full rounded-t-lg">
                          <img
                            src={`${assetsUrl2}/${item?.thumbnail}`}
                            alt={item?.title}
                            className="w-full h-auto rounded-t-lg"
                          />
                        </div>

                        <div className="flex flex-col space-y-3 p-3">
                          <div className="flex flex-col">
                            <h4 className="h-11 line-clamp-2 leading-tight text-base font-semibold">
                              {item?.title}
                            </h4>

                            <p className="w-full flex text-xxs font-medium">
                              {moment(item?.date)?.format("dddd, DD MMMM YYYY")}
                            </p>
                          </div>

                          <div
                            className="activityCard__subtitle text-xs mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item?.content,
                            }}
                          ></div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

export default ListActivity;
