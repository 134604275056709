import React from 'react';

function IconDate(props) {
  return (
    <svg
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 90c-2.75 0-5.105-.88-7.065-2.641C.978 85.595 0 83.475 0 81V18c0-2.475.978-4.593 2.935-6.354C4.895 9.882 7.25 9 10 9h5V0h10v9h40V0h10v9h5c2.75 0 5.105.882 7.065 2.646C89.022 13.407 90 15.525 90 18v63c0 2.475-.978 4.594-2.935 6.359C85.105 89.119 82.75 90 80 90H10zm0-9h70V36H10v45zm0-54h70v-9H10v9zm0 0v-9 9zm35 27c-1.417 0-2.603-.432-3.56-1.296-.96-.861-1.44-1.929-1.44-3.204 0-1.275.48-2.344 1.44-3.209C42.397 45.431 43.583 45 45 45c1.417 0 2.605.43 3.565 1.291.957.865 1.435 1.934 1.435 3.209 0 1.275-.478 2.343-1.435 3.204C47.605 53.568 46.417 54 45 54zm-20 0c-1.417 0-2.605-.432-3.565-1.296C20.478 51.843 20 50.775 20 49.5c0-1.275.478-2.344 1.435-3.209C22.395 45.431 23.583 45 25 45c1.417 0 2.605.43 3.565 1.291.957.865 1.435 1.934 1.435 3.209 0 1.275-.478 2.343-1.435 3.204C27.605 53.568 26.417 54 25 54zm40 0c-1.417 0-2.603-.432-3.56-1.296-.96-.861-1.44-1.929-1.44-3.204 0-1.275.48-2.344 1.44-3.209C62.397 45.431 63.583 45 65 45c1.417 0 2.603.43 3.56 1.291.96.865 1.44 1.934 1.44 3.209 0 1.275-.48 2.343-1.44 3.204C67.603 53.568 66.417 54 65 54zM45 72c-1.417 0-2.603-.432-3.56-1.296-.96-.861-1.44-1.929-1.44-3.204 0-1.275.48-2.343 1.44-3.204C42.397 63.432 43.583 63 45 63c1.417 0 2.605.432 3.565 1.296.957.861 1.435 1.929 1.435 3.204 0 1.275-.478 2.343-1.435 3.204C47.605 71.568 46.417 72 45 72zm-20 0c-1.417 0-2.605-.432-3.565-1.296C20.478 69.843 20 68.775 20 67.5c0-1.275.478-2.343 1.435-3.204C22.395 63.432 23.583 63 25 63c1.417 0 2.605.432 3.565 1.296.957.861 1.435 1.929 1.435 3.204 0 1.275-.478 2.343-1.435 3.204C27.605 71.568 26.417 72 25 72zm40 0c-1.417 0-2.603-.432-3.56-1.296-.96-.861-1.44-1.929-1.44-3.204 0-1.275.48-2.343 1.44-3.204C62.397 63.432 63.583 63 65 63c1.417 0 2.603.432 3.56 1.296.96.861 1.44 1.929 1.44 3.204 0 1.275-.48 2.343-1.44 3.204C67.603 71.568 66.417 72 65 72z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconDate;
