import React from 'react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { ease: 'easeInOut', duration: 0.6 },
  },
};

const IconLine = (props) => (
  <motion.svg
    viewBox="0 0 138 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    initial="hidden"
    animate={props.isHovered === 99 + props.idx ? 'visible' : 'hidden'}
    onMouseEnter={() => props.hoverEnter(99 + props.idx, props.badgeName)}
    onMouseLeave={props.hoverLeave}
  >
    <motion.path
      d="M50.4991 54.0002C38.9991 54.0002 -9.5009 37.5002 4.4991 21.5002C18.4991 5.50021 103 -8.49979 128.5 13.0002C154 34.5002 113 61 56 67.5"
      stroke="#F5F08E"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
      variants={pathVariants}
    />
  </motion.svg>
);

export default IconLine;
