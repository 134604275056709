import React from "react";

const Badge = ({ className, children, ...otherProps }) => {
  return (
    <div
      className={`badge px-2 py-1 lg:px-[10px] lg:py-[6px] bg-[#E6EDF9] rounded-md text-primary capitalize whitespace-pre-wrap ${
        className || ""
      }`}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Badge;
