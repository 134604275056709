import React from 'react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { ease: 'easeInOut', duration: 0.65 },
  },
};

const IconWater = (props) => (
  <motion.svg
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    variants={pathVariants}
    initial="hidden"
    animate={props.isHovered === props.bdgIndex + 1 ? 'visible' : 'hidden'}
    onMouseEnter={() => props.hoverEnter(props.bdgIndex, props.badgeName)}
    onMouseLeave={props.hoverLeave}
  >
    <g>
      <path
        d="M103.651 94.6243C103.651 94.6243 102.926 87.7692 108.366 83.6197C113.806 79.4701 116.572 81.1098 116.706 82.7178C116.888 84.8825 113.805 85.2435 111.086 86.1453C108.366 87.0472 105.646 89.3924 103.651 94.6243V94.6243Z"
        fill="#51B7EF"
      />
      <path
        d="M108.365 83.619C106.46 85.0718 105.313 86.8555 104.625 88.5611C106.489 86.6215 108.365 85.6708 110.147 85.321C113.071 84.7472 116.267 84.8366 116.467 82.3873C116.478 82.2574 116.473 82.1257 116.456 81.9934C115.656 80.7411 112.968 80.108 108.365 83.6184L108.365 83.619Z"
        fill="#73C3EF"
      />
      <path
        opacity={0.5}
        d="M113.99 81.8435C114.083 81.586 114.541 81.5142 115.012 81.6836C115.483 81.8529 115.789 82.1992 115.695 82.4567C115.602 82.7142 115.144 82.7859 114.673 82.6166C114.202 82.4473 113.896 82.101 113.99 81.8435Z"
        fill="white"
      />
    </g>
    <g>
      <path
        d="M46.0636 94.6243C46.0636 94.6243 46.7886 87.7692 41.3493 83.6197C35.9093 79.4701 33.1432 81.1098 33.0085 82.7178C32.8271 84.8825 35.9099 85.2435 38.6293 86.1453C41.3493 87.0472 44.0692 89.3924 46.0636 94.6243V94.6243Z"
        fill="#51B7EF"
      />
      <path
        d="M41.3499 83.619C43.2545 85.0718 44.4014 86.8555 45.0898 88.5611C43.2261 86.6215 41.3493 85.6708 39.5677 85.321C36.6439 84.7472 33.4482 84.8366 33.2479 82.3873C33.2373 82.2574 33.242 82.1257 33.2585 81.9934C34.0586 80.7411 36.7467 80.108 41.3493 83.6184L41.3499 83.619Z"
        fill="#73C3EF"
      />
      <path
        opacity={0.5}
        d="M35.7252 81.8435C35.6319 81.586 35.1739 81.5142 34.7029 81.6836C34.232 81.8529 33.9259 82.1992 34.0198 82.4567C34.1132 82.7142 34.5712 82.7859 35.0421 82.6166C35.5131 82.4473 35.8192 82.101 35.7252 81.8435Z"
        fill="white"
      />
    </g>
    <g>
      <path
        d="M92.6617 101.616C92.6617 101.616 92.236 97.6389 93.5158 96.078C94.7964 94.5171 97.0373 93.1126 96.7169 91.5526C96.3965 89.9917 94.9566 88.7437 93.1963 90.7722C91.436 92.8006 92.8759 94.6735 90.7952 95.6095C88.7145 96.5455 87.2746 96.078 86.3134 94.9855C85.3531 93.8931 84.8726 93.1126 82.6326 93.7375C80.3917 94.3615 77.1905 97.1704 75.27 96.2344C73.3495 95.2984 73.0291 94.0495 73.3495 93.4255C73.6699 92.8015 75.27 92.0211 74.7903 90.4602C74.3098 88.8993 73.03 88.1197 71.7494 89.8362C70.4688 91.5526 72.3893 92.4886 71.429 93.5811C70.4688 94.6735 69.9891 94.9855 68.8686 94.0495C67.7482 93.1135 66.4675 94.206 65.348 94.6735C64.2275 95.142 63.2673 96.2344 61.1866 94.6735C59.1059 93.1126 57.5058 92.3331 57.3456 91.0842C57.1854 89.8362 57.1854 89.0557 55.4251 88.8993C53.6647 88.7428 53.5046 91.8646 54.9445 92.1766C56.3844 92.4886 57.6651 93.4246 57.9854 94.6735C58.3058 95.9215 57.9323 97.3781 56.4384 97.4303C54.9445 97.4824 58.6253 101.539 58.6253 101.539L92.6617 101.615V101.616Z"
        fill="#AAD6EF"
      />
      <path
        opacity={0.5}
        d="M72.2285 90.5798C72.2285 90.1985 72.5372 89.8901 72.9188 89.8901C73.3004 89.8901 73.6091 90.1985 73.6091 90.5798C73.6091 90.961 73.3004 91.2694 72.9188 91.2694C72.5372 91.2694 72.2285 90.961 72.2285 90.5798Z"
        fill="white"
      />
      <path
        opacity={0.5}
        d="M66.5048 96.0095C66.7487 95.7254 67.2509 95.6643 67.6001 95.7146C67.7306 95.7335 67.7981 95.5078 67.8224 95.4251C67.8827 95.2237 67.862 95.0376 67.8224 94.8353C67.7999 94.7193 67.7315 94.5655 67.6001 94.5458C66.9845 94.4558 66.5381 94.8811 66.3005 95.408C66.2339 95.5555 66.2321 95.7596 66.287 95.9106C66.323 96.0104 66.4058 96.1246 66.5048 96.0095V96.0095Z"
        fill="white"
      />
      <path
        opacity={0.5}
        d="M93.4326 93.0391C93.4758 92.7541 93.6468 92.4718 93.8583 92.2785C93.9653 92.1804 94.0949 92.104 94.2146 92.0222C94.4171 91.8837 94.4576 91.7489 94.5692 91.5331C94.6655 91.3478 94.6934 90.847 94.4747 90.722C94.4225 90.6969 94.3748 90.6645 94.3316 90.6258C94.2785 90.5935 94.2254 90.5935 94.1723 90.6258C93.4173 91.0637 93.1041 92.1157 93.0726 92.9285C93.069 93.0301 93.0942 93.3125 93.231 93.3367C93.3759 93.3619 93.4164 93.1389 93.4317 93.0391H93.4326Z"
        fill="white"
      />
    </g>
    <path
      d="M38.0235 107.004C38.2552 106.96 38.3837 107.257 38.1825 107.375C37.4299 107.817 36.6836 108.622 36.9818 110.037C37.3976 112.007 43.7352 115.16 46.8775 116.612C47.1092 116.719 46.9844 117.051 46.7347 116.993C43.1066 116.157 35.4641 114.024 35.0429 110.825C34.689 108.138 36.6001 107.277 38.0235 107.004V107.004Z"
      fill="#AAD6EF"
    />
    <path
      d="M65.4793 119.005C70.4196 119.775 80.1083 120.839 88.5082 119.118C89.0085 119.015 89.2038 119.79 88.7267 119.984C86.3105 120.969 82.4531 122 76.8921 122C71.8323 122 67.8188 120.867 65.276 119.876C64.8033 119.692 64.9817 118.928 65.4793 119.005V119.005Z"
      fill="#AAD6EF"
    />
    <path
      d="M101.139 117.512C105.405 115.231 114.683 109.775 112.84 106.38C112.717 106.154 112.971 105.906 113.191 106.036C114.561 106.846 116.339 108.645 113.442 111.803C110.491 115.019 104.569 117.062 101.328 117.989C101.032 118.074 100.866 117.657 101.138 117.512H101.139Z"
      fill="#AAD6EF"
    />
    <path
      d="M87.2668 117.999C86.9546 118.022 86.895 117.578 87.2027 117.523C93.5742 116.381 104.931 113.645 106.671 108.435C107.867 104.855 104.993 103.214 102.464 102.471C102.174 102.386 102.267 101.965 102.568 102.002C105.997 102.437 110.518 104.044 108.501 109.674C106.301 115.814 93.9887 117.522 87.2659 117.998L87.2668 117.999Z"
      fill="#73C3EF"
    />
    <path
      d="M99.9309 108.887C100.151 108.589 99.8009 108.198 99.5149 108.421C96.9854 110.397 91.1975 114.095 82.9214 114.724C71.9105 115.56 61.2448 114.821 55.3296 111.659C48.9275 108.236 48.5679 107.632 49.2413 108.175C49.2413 108.175 53.9048 116.118 75.4084 116.953C91.4531 117.577 97.7619 111.839 99.9309 108.887V108.887Z"
      fill="#51B7EF"
    />
    <path
      d="M48.3995 103.007C48.7249 102.937 48.884 103.393 48.5869 103.545C46.3801 104.675 43.4155 106.712 44.8549 108.992C47.5136 113.206 56.8105 116.221 65.7521 117.428C66.1057 117.475 66.0704 118.005 65.714 118C59.9555 117.923 47.7638 116.525 42.8567 111.47C37.6879 106.146 44.5498 103.838 48.4004 103.006L48.3995 103.007Z"
      fill="#73C3EF"
    />
    <g>
      <path
        d="M98.9425 90.2863C97.8491 92.9387 100.036 93.5636 98.9425 95.1236C97.8491 96.6837 95.8188 99.1806 95.3499 100.117C94.881 101.053 93.6319 97.9326 90.6638 99.649C87.6958 101.365 88.9458 98.2446 87.5401 97.4641C86.1343 96.6837 85.0409 98.4001 83.7917 97.9326C82.5426 97.4641 83.4795 95.7477 83.4795 94.4996C83.4795 93.2516 82.5426 92.3147 81.6048 92.3147C80.6671 92.3147 79.418 93.0952 79.418 94.6552C79.418 96.2152 80.5114 96.3717 80.3548 97.9326C80.1982 99.4935 78.7925 101.053 76.9188 99.8055C75.0442 98.5575 71.9663 96.0794 70.9836 98.401C68.6788 103.843 67.5682 98.33 66.6314 97.706C65.6945 97.082 63.3303 98.5566 63.486 97.3086C63.6426 96.0606 65.5163 92.7832 62.2369 91.8472C58.9565 90.9112 59.1131 95.7485 59.8943 97.465C60.6754 99.1815 58.1484 100.921 56.9271 97.3086C56.5032 96.0543 54.8968 96.6846 54.4279 97.3086C53.9591 97.9326 51.0684 94.7451 51.693 93.4971C52.3175 92.2491 52.5533 90.2863 51.3042 89.8187C49.525 89.1525 46.6181 91.8472 48.9616 94.6561C51.3042 97.465 53.1788 99.4935 53.4911 101.366C53.8034 103.239 52.2419 106.048 53.0222 106.828C53.8034 107.608 59.114 108.701 60.9877 108.701C62.8614 108.701 70.359 109.793 73.9516 110.105C77.5442 110.417 83.0106 109.949 85.1975 109.949C87.3844 109.949 93.163 109.013 94.1008 108.076L96.1923 104.214C96.3489 103.121 97.3811 100.741 98.1623 99.8046C98.9434 98.8686 102.535 94.3432 102.692 91.6898C102.848 89.0374 100.037 87.6329 98.9434 90.2854L98.9425 90.2863Z"
        fill="#51B7EF"
      />
      <path
        d="M98.9239 90.2864C97.8305 92.9388 100.017 93.5637 98.9239 95.1237C97.8305 96.6837 95.8001 99.1806 95.3313 100.118C94.8624 101.054 93.6133 97.9326 90.6452 99.6491C87.6771 101.366 88.9272 98.2446 87.5214 97.4642C86.1157 96.6837 85.0223 98.4002 83.7731 97.9326C82.524 97.4642 83.4608 95.7477 83.4608 94.4997C83.4608 93.2517 82.524 92.3148 81.5862 92.3148C80.6485 92.3148 79.3993 93.0953 79.3993 94.6553C79.3993 96.2153 80.4928 96.3717 80.3362 97.9326C80.1796 99.4935 78.7748 101.054 76.9001 99.8055C75.0255 98.5575 71.9477 96.0795 70.9649 98.4011C68.6601 103.844 67.5496 98.3301 66.6127 97.7061C65.6759 97.082 63.3117 98.5566 63.4674 97.3086C63.624 96.0606 65.4977 92.7833 62.2182 91.8473C58.9379 90.9113 59.0945 95.7486 59.8757 97.4651C60.6568 99.1815 58.1297 100.921 56.9076 97.3086C56.4837 96.0543 54.8773 96.6846 54.4084 97.3086C53.9395 97.9326 51.2064 94.3316 51.8309 93.0827C52.4555 91.8347 52.5338 90.2864 51.2847 89.8179C49.5054 89.1516 46.5986 91.8464 48.9421 94.6553C51.2847 97.4642 53.1593 99.4926 53.4716 101.366C53.7577 103.079 52.4789 105.573 52.8578 106.581H52.7831C52.7831 106.581 52.8218 106.617 52.9019 106.685C52.9307 106.737 52.9613 106.786 53.0027 106.827C53.099 106.923 53.2655 107.025 53.4851 107.128C55.519 108.56 62.1777 112.316 76.1127 112.316C88.9362 112.316 94.776 109.238 96.9224 107.668C96.9224 107.668 96.8621 104.928 96.9818 104.017C97.1258 102.923 97.3607 100.742 98.1409 99.8055C98.9221 98.8695 102.514 94.3442 102.67 91.6908C102.827 89.0384 100.016 87.6339 98.9221 90.2864H98.9239Z"
        fill="#51B7EF"
      />
      <path
        d="M82.5355 93.8949C82.5355 93.8949 80.6896 91.9788 80.4134 94.4623C80.1371 96.9457 82.9998 96.087 82.5355 93.8949Z"
        fill="#73C3EF"
      />
      <path
        d="M100.571 89.9105C99.7795 89.7622 99.19 92.1179 100.018 92.9451C100.847 93.7732 102.78 90.3241 100.571 89.9105Z"
        fill="#73C3EF"
      />
      <path
        d="M61.863 92.8108C60.8056 92.2057 59.7148 93.9518 60.3862 96.0918C61.0576 98.2317 63.609 93.8088 61.863 92.8108V92.8108Z"
        fill="#73C3EF"
      />
      <path
        d="M50.7256 90.6C50.0272 90.0767 47.9645 91.4281 48.7925 92.5314C49.6214 93.6346 51.8299 91.4281 50.7256 90.6Z"
        fill="#73C3EF"
      />
      <path
        opacity={0.5}
        d="M100.572 92.5314C100.572 92.1502 100.882 91.8418 101.263 91.8418C101.643 91.8418 101.953 92.1502 101.953 92.5314C101.953 92.9127 101.643 93.2211 101.263 93.2211C100.882 93.2211 100.572 92.9127 100.572 92.5314Z"
        fill="white"
      />
      <path
        opacity={0.5}
        d="M81.3789 93.4969C81.3789 93.1921 81.6264 92.9448 81.9315 92.9448C82.2366 92.9448 82.4841 93.1921 82.4841 93.4969C82.4841 93.8017 82.2366 94.049 81.9315 94.049C81.6264 94.049 81.3789 93.8017 81.3789 93.4969Z"
        fill="white"
      />
      <path
        d="M67.7119 95.7216C67.7266 95.7216 67.7257 95.6875 67.7119 95.6875C67.6981 95.6875 67.6971 95.7216 67.7119 95.7216Z"
        fill="white"
      />
      <path
        d="M84.3743 111.824C84.637 111.563 84.8026 111.225 84.7684 110.793C84.637 109.148 83.1872 109.674 82.1991 108.818C81.2109 107.962 83.1215 107.041 83.1872 105.001C83.2529 102.961 81.0129 103.223 80.2884 103.816C79.564 104.408 76.7309 103.75 76.1378 101.841C75.5448 99.9323 73.8978 100.656 73.6999 101.578C73.5019 102.499 72.1186 104.408 68.6268 103.157C65.135 101.907 63.8831 107.106 61.0501 107.765C58.217 108.423 58.7444 103.552 55.977 102.631C53.5498 101.822 52.9216 105.473 52.8046 106.381C52.8163 106.453 52.8325 106.52 52.8559 106.582H52.7812C52.7812 106.582 52.8199 106.618 52.9 106.686C52.9288 106.738 52.9594 106.787 53.0008 106.828C53.0971 106.924 53.2636 107.026 53.4832 107.129C55.5171 108.561 62.1759 112.317 76.1108 112.317C79.2733 112.317 82.0101 112.13 84.3734 111.824H84.3743Z"
        fill="#73C3EF"
      />
      <path
        opacity={0.5}
        d="M61.3145 93.5461C61.3145 93.0372 61.7275 92.6245 62.2369 92.6245C62.7463 92.6245 63.1594 93.0372 63.1594 93.5461C63.1594 94.055 62.7463 94.4677 62.2369 94.4677C61.7275 94.4677 61.3145 94.055 61.3145 93.5461Z"
        fill="white"
      />
      <path
        opacity={0.5}
        d="M48.793 92.8882C48.793 92.4503 49.1485 92.0942 49.5876 92.0942C50.0268 92.0942 50.3823 92.4494 50.3823 92.8882C50.3823 93.327 50.0268 93.6821 49.5876 93.6821C49.1485 93.6821 48.793 93.327 48.793 92.8882Z"
        fill="white"
      />
      <path
        d="M93.7212 103.622C91.1518 104.709 93.3261 106.881 91.2508 107.571C89.1755 108.263 90.3617 105.202 87.1002 106.288C83.8388 107.375 87.5943 108.559 83.4437 109.349C79.2931 110.138 82.1595 105.795 76.3286 105.499C70.4978 105.203 73.6603 109.942 69.1146 108.56C64.5689 107.178 69.6087 104.808 69.5097 103.13C69.4107 101.451 62.2956 101.55 64.4699 103.426C66.6442 105.301 60.5173 106.19 59.134 105.301C57.7508 104.413 61.1104 103.622 59.233 102.24C57.4187 100.905 54.9501 101.57 53.5048 102.16C53.4175 103.777 52.5329 105.72 52.8559 106.582H52.7812C52.7812 106.582 52.8199 106.618 52.9 106.686C52.9288 106.738 52.9594 106.787 53.0008 106.828C53.0971 106.924 53.2636 107.026 53.4832 107.129C55.5171 108.561 62.1759 112.317 76.1108 112.317C88.9343 112.317 94.7742 109.239 96.9206 107.669C96.9206 107.669 96.2888 102.537 93.7194 103.622H93.7212Z"
        fill="#73C3EF"
      />
      <path
        d="M82.0606 110.632C80.1833 110.632 79.0962 109.447 79.0962 107.867V107.859C79.0998 106.702 78.2466 105.715 77.0982 105.565C76.8607 105.534 76.6042 105.511 76.3297 105.497C70.4989 105.201 73.1672 110.73 69.9067 109.84C67.5695 109.204 68.9275 105.632 68.0339 104.894C66.2016 103.382 66.0072 107.382 62.4955 108.163C58.938 108.952 56.9617 106.188 57.0607 104.115C57.1372 102.514 54.5642 102.855 53.3862 103.094C53.1297 104.467 52.5951 105.882 52.8579 106.582H52.7832C52.7832 106.582 52.8219 106.618 52.902 106.685C52.9308 106.738 52.9614 106.787 53.0028 106.827C53.0991 106.924 53.2656 107.025 53.4852 107.129C55.5191 108.561 62.1779 112.317 76.1128 112.317C79.1367 112.317 81.7717 112.146 84.063 111.864C83.6859 111.266 83.0533 110.632 82.0615 110.632H82.0606Z"
        fill="#AAD6EF"
      />
    </g>
  </motion.svg>
);

export default IconWater;
