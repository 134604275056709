import React, { useContext, useRef, useState } from "react";
import "./ContactPage.scss";
import Breadcrumb from "../components/Breadcrumb";
import { SendButton } from "../components/Button";
import Layout from "../components/Layout";
import {
  BubbleArigato,
  BubbleArigatoOutline,
  IconClock,
  IconDate,
} from "../components/svg";
import DoctorArigato from "../components/svg/DoctorArigato";
import DoctorArigatoBows from "../components/svg/DoctorArigatoBows";
import mainContext from "../context/MainContext";
import { Helmet } from "react-helmet-async";
import { motion, useInView } from "framer-motion";
import { isValidEmail } from "../utils/Utils";
import { MdOutlineErrorOutline } from "react-icons/md";
import { screenMobile } from "../utils/Breakpoints";

const contactUsLinks = [
  {
    page: "Home",
    link: "/",
  },
  {
    page: "Contact Us",
    link: "/contact-us",
  },
];

const animation = (isInView, delay) => {
  let style;

  if (window.innerWidth > screenMobile) {
    style = {
      transform: isInView ? "none" : "translateX(100px)",
      WebkitTransform: isInView ? "none" : "translateX(100px)",
      MozTransform: isInView ? "none" : "translateX(100px)",
      msTransform: isInView ? "none" : "translateX(100px)",
      OTransform: isInView ? "none" : "translateX(100px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 1.5}s`,
    };
  } else {
    style = {};
  }
  return style;
};

function ContactPage() {
  const { postContactUs, contactStatus, setContactStatus, contactError } =
    useContext(mainContext);

  const [isHovered, setHovered] = useState(false);
  // FORM STATE
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [telp, setTelp] = useState("");
  const [message, setMessage] = useState("");
  const [isInView2, setInView2] = useState(false);
  const [error, setError] = useState({
    email: "",
    telp: "",
  });

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleSend = () => {
    if (contactStatus === "Kirim") {
      setContactStatus("Mengirim");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      name: name,
      company: companyName,
      phone: Number(telp),
      email: email,
      message: message,
      action: "inbox",
    };

    if (
      name !== "" &&
      companyName !== "" &&
      telp !== "" &&
      email !== "" &&
      message !== ""
    ) {
      postContactUs(body);
      handleSend();
      setName("");
      setCompanyName("");
      setEmail("");
      setTelp("");
      setMessage("");
    }
  };

  const handleEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError({ ...error, email: "Email anda tidak valid." });
    } else {
      setError({ ...error, email: "" });
    }

    setEmail(e.target.value);
  };

  return (
    <>
      <Layout activeNav="contact-us">
        <Helmet>
          <link rel="canonical" href="https://dhcsupplement.co.id/contact-us" />
          <title>Contact Us - DHC Supplement</title>
          <meta
            name="description"
            content="Punya pertanyaan seputar produk suplemen DHC? Keep in touch with us, kami akan segera menghubungi anda kembali."
          />
          <meta
            name="keywords"
            content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
          />
          <meta property="og:title" content="Contact Us - DHC Supplement" />
          <meta
            property="og:description"
            content="Punya pertanyaan seputar produk suplemen DHC? Keep in touch with us, kami akan segera menghubungi anda kembali."
          />
          <meta
            property="og:url"
            content="https://dhcsupplement.co.id/contact-us"
          />
        </Helmet>
        <main className="contactUsPage layout-gap">
          <Breadcrumb links={contactUsLinks} />

          <motion.div
            whileInView={() => setInView2(true)}
            viewport={{ once: true }}
            ref={ref}
            className="contactUsPage__main relative w-full flex flex-col sm:flex-row justify-between items-start px-0 sm:px-12 pt-0 sm:pt-12 pb-20 sm:pb-8 xl:pt-14 2xl:pt-16 2xl:pb-12"
          >
            <DoctorArigato
              className="hidden sm:block sm:w-32 xl:w-[8.5rem] 3xl:w-[8.65rem] 4xl:w-36 5xl:w-[9.5rem] h-auto absolute -bottom-14 sm:-bottom-28 right-[20vw] sm:right-[28vw] z-0"
              isHovered={isHovered}
              setHovered={setHovered}
              isInView={isInView2}
            />
            <DoctorArigatoBows
              className="hidden sm:block sm:w-32 xl:w-[8.5rem] 3xl:w-[8.65rem] 4xl:w-36 5xl:w-[9.5rem] h-auto absolute -bottom-14 sm:-bottom-28 right-[20vw] sm:right-[28vw] z-0"
              isHovered={isHovered}
              setHovered={setHovered}
              mobile={false}
            />
            <BubbleArigato
              className="hidden sm:block sm:w-32 xl:w-[8.5rem] 3xl:w-[8.65rem] 4xl:w-36 5xl:w-[9.5rem] h-auto absolute bottom-12 xl:bottom-14 3xl:bottom-16 right-[15vw] xl:right-[17vw] 3xl:right-[18vw] 4xl:right-[18.6vw] 5xl:right-[20vw] z-0"
              isHovered={isHovered}
              setHovered={setHovered}
            />

            {/* Mobile Screen */}
            <DoctorArigatoBows
              className="block sm:hidden w-20 h-auto absolute -bottom-14 right-[20vw] z-0"
              isHovered={isHovered}
              setHovered={setHovered}
              mobile={true}
            />
            <BubbleArigatoOutline className="block sm:hidden w-16 h-auto absolute bottom-12 right-3 z-0" />
            <div className="w-full sm:w-[45%]" style={animation(isInView, 0)}>
              <form
                onSubmit={(e) => handleSubmit(e)}
                className="contactUsPage__main-form w-full flex flex-col space-y-5 sm:space-y-8 px-5 py-7 text-primary font-nunito-sans"
              >
                <h3 className="text-xl sm:text-3.2xl xl:text-4xl font-semibold sm:font-bold">
                  Keep In Touch With Us
                </h3>
                <span className="w-full border-b border-gray-300"></span>
                <p className="w-full xl:w-10/12 2xl:w-9/12 text-base xl:text-lg text-graylight">
                  Punya pertanyaan seputar produk kami?
                  <br />
                  Silahkan lengkapi form di bawah ini dan kami akan membalas
                  Anda secepatnya.
                </p>
                <div className="flex flex-col space-y-6 text-base xl:text-lg text-graylight font-nunito-sans">
                  <input
                    className="placeholder:text-[#a6a6a6] placeholder:text-base xl:placeholder:text-lg"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Nama Lengkap"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                  <input
                    className="placeholder:text-[#a6a6a6] placeholder:text-base xl:placeholder:text-lg"
                    type="text"
                    id="companyName"
                    name="companyName"
                    placeholder="Nama Perusahaan / Organisasi"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    required
                  />
                  {error.email !== "" && (
                    <div className="py-2 flex items-center space-x-1 text-sm xl:text-base text-red-500">
                      <MdOutlineErrorOutline className="" />
                      <p>{error.email}</p>
                    </div>
                  )}
                  <input
                    className="placeholder:text-[#a6a6a6] placeholder:text-base xl:placeholder:text-lg"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleEmail(e)}
                    value={email}
                    required
                  />
                  <input
                    className="placeholder:text-[#a6a6a6] placeholder:text-base xl:placeholder:text-lg"
                    type="number"
                    id="telp"
                    name="telp"
                    placeholder="Nomor Whatsapp"
                    onChange={(e) => setTelp(e.target.value)}
                    value={telp}
                    required
                    autoComplete="off"
                  />
                  <textarea
                    className="placeholder:text-[#a6a6a6] placeholder:text-base xl:placeholder:text-lg"
                    id="message"
                    name="message"
                    placeholder="Pesan"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    required
                    rows={4}
                  />
                  <div className="w-full inline-flex pt-3 pb-6 sm:pb-0">
                    <SendButton
                      className="sendButton w-full"
                      type="submit"
                      status={contactStatus}
                    >
                      {contactError === ""
                        ? contactStatus
                        : `${contactStatus}, ${contactError}`}
                    </SendButton>
                  </div>
                </div>
              </form>
            </div>

            <div className="w-full sm:w-6/12" style={animation(isInView, 0.4)}>
              <div className="w-full pl-0 pr-0 sm:pl-4 xl:pl-8 sm:pr-2 flex">
                <div className="contactUsPage__main-info w-full sm:w-11/12 ml-auto flex flex-col space-y-8 px-5 py-7 text-white font-nunito-sans text-base xl:text-lg">
                  <h3 className="text-xl sm:text-2.1xl xl:text-3.1xl font-semibold sm:font-bold">
                    Layanan Operasional Kami
                  </h3>
                  <span className="w-full border-b border-b-white opacity-80"></span>
                  <p className="text-base xl:text-lg">
                    Terima kasih telah mengunjungi website DHC Suplemen
                    Indonesia. Tetap kunjungi website kami untuk info terbaru
                    dan menarik lainnya.
                  </p>
                  <div className="flex items-center space-x-4">
                    <IconDate className="w-6 h-6 xl:w-7 xl:h-7" />
                    <h6>: Senin - Jumat</h6>
                  </div>
                  <div className="flex items-center space-x-4">
                    <IconClock className="w-6 h-6 xl:w-7 xl:h-7" />
                    <h6>: 08.30 - 18.00</h6>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </main>
      </Layout>
    </>
  );
}

export default ContactPage;
