import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollUp from "./components/ScrollUp";
import { MainContextProvider } from "./context/MainContext";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MotionContextProvider } from "./context/MotionContext";
// import Snowfall from "react-snowfall";

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <MainContextProvider>
          <MotionContextProvider>
            <ScrollUp>
              {/* <Snowfall
                style={{
                  position: "fixed",
                  zIndex: 100000,
                  width: "100vw",
                  height: "100vh",
                }}
              /> */}
              <App />
              <ToastContainer />
            </ScrollUp>
          </MotionContextProvider>
        </MainContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
