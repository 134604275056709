import React, { useContext, useState } from 'react';

import { SubscribeButton } from '../components/Button';
import mainContext from '../context/MainContext';
import { isValidEmail } from '../utils/Utils';
import { MdClose, MdOutlineErrorOutline } from 'react-icons/md';

function ContactUs() {
  const {
    postSubscribe,
    // subsData,
    subsStatus,
    setSubsStatus,
  } = useContext(mainContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleStatus = () => {
    if (subsStatus === 'Subscribe') {
      setSubsStatus('Loading');
    }
  };

  const handleSubscribe = (e) => {
    e.preventDefault();

    let body = {
      email: email,
      action: 'subscribe',
    };

    if (isValidEmail(email) && subsStatus === 'Subscribe') {
      postSubscribe(body);
      handleStatus();
      setEmail('');
    }
  };

  const handleEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError('Email anda tidak valid.');
    } else {
      setError('');
    }

    setEmail(e.target.value);
  };

  return (
    <div className="contactUs flex flex-col space-y-6 sm:space-y-10 lg:space-y-16 pt-6 sm:pt-0 pb-[10vh] lg:pt-[5vh] lg:pb-[15vh]">
      <div className="w-full sm:w-8/12 flex flex-col mx-auto items-center text-center space-y-4 lg:space-y-3 font-nunito-sans">
        <h5 className="text-graylight text-xl sm:text-2.2xl lg:text-3xl xl:text-3.1xl font-bold px-5 sm:px-0">
          Subscribe untuk info terbaru dan menarik dari DHC.
        </h5>
        <p className="text-sm px-5 text-[#979797] text-justify sm:text-center">
          Kami tidak akan membagi informasi kamu kepada pihak manapun — lihat
          Privacy Policy kami— kamu juga bisa unsubscribe kapan saja dengan klik
          link yang ada di bawah email dari kami.
        </p>
      </div>

      <div className="w-full sm:w-8/12 flex flex-wrap space-y-5 items-end mx-auto font-nunito-sans px-5 sm:px-0">
        <div
          className={`flex space-x-14 rounded-full overflow-hidden transition-all duration-200 ease-out ${
            error !== ''
              ? 'p-2 border border-red-400 h-auto'
              : 'p-0 border-0 h-0'
          }`}
        >
          <div className="flex items-center space-x-2 text-sm xl:text-base text-red-500">
            <MdOutlineErrorOutline className="text-xl" />
            <p className="leading-tight">{error}</p>
          </div>
          <MdClose
            onClick={() => setError('')}
            className="text-xl text-red-500 cursor-pointer"
          />
        </div>

        <form
          onSubmit={(e) => subsStatus === 'Subscribe' && handleSubscribe(e)}
          className="w-full flex"
        >
          <input
            className="w-full px-3 py-2 sm:px-8 sm:py-3 text-sm border-y-2 border-l-2 border-grayborder"
            type="text"
            placeholder="Enter email..."
            onChange={(e) => handleEmail(e)}
            value={email}
            required
          />
          <SubscribeButton status={subsStatus}>{subsStatus}</SubscribeButton>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
