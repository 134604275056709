import React from "react";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb";
import { ButtonSimple } from "../components/Button";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <Layout>
      <main className="notFound layout-gap">
        <Breadcrumb links={[]} />

        <div className="flex flex-col justify-center items-center space-y-5 px-20 py-44 bg-white font-nunito-sans">
          <p className="text-2.1xl text-primary font-bold">
            Maaf, halaman ini tidak bisa kami temukan.
          </p>
          <Link to="/">
            <ButtonSimple>Kembali ke Home</ButtonSimple>
          </Link>
        </div>
      </main>
    </Layout>
  );
}

export default NotFound;
