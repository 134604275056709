import React from 'react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { ease: 'easeInOut', duration: 0.7 },
  },
};

const pathVariants2 = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { ease: 'easeInOut', duration: 0.7, delay: 0.1 },
  },
};

const IconThumbs = (props) => (
  <motion.svg
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    initial="hidden"
    animate={props.isHovered === props.bdgIndex + 1 ? 'visible' : 'hidden'}
    onMouseEnter={() => props.hoverEnter(props.bdgIndex, props.badgeName)}
    onMouseLeave={props.hoverLeave}
  >
    <g>
      <motion.path
        d="M69.7029 29.5712C69.7029 26.891 69.704 24.6643 69.7019 22.4365C69.7019 22.0636 69.6678 21.6949 69.5986 21.3252C69.4943 20.7685 69.8114 20.3068 70.0293 19.8379C70.3908 19.0623 70.9124 18.4075 71.5486 17.8198C72.8004 16.6661 73.6989 15.2336 74.5355 13.768C76.1229 10.9856 77.4005 8.05963 78.5428 5.07064C78.6781 4.71535 78.6461 4.35903 78.6007 4.00374C78.5356 3.48836 78.5501 2.97712 78.6182 2.46484C78.6792 2.0104 78.7639 1.9474 79.21 2.03209C80.6973 2.31301 81.5318 3.27973 81.6516 4.83206C81.7446 6.03117 81.5391 7.18999 81.2096 8.33229C80.7727 9.84847 80.2387 11.3388 79.9805 12.9036C79.8463 13.7195 79.7802 14.5333 79.934 15.3575C80.1478 16.505 81.0908 17.1309 82.2331 16.8809C84.4857 16.3893 86.7476 15.9628 89.0714 16.0836C89.9596 16.1301 90.8417 16.2241 91.6803 16.5473C93.1758 17.1226 93.7212 18.4746 92.9744 19.8854C92.6078 20.5774 92.6749 21.1021 93.1965 21.6918C94.2789 22.9147 94.2624 24.1974 93.1738 25.4296C92.6698 25.9997 92.5541 26.5378 92.8846 27.2494C93.5187 28.6107 93.1862 30.0081 91.9819 30.9903C91.3663 31.4923 91.1267 31.9994 91.2806 32.8153C91.576 34.3821 90.8127 35.5244 89.1406 36.2515C87.6978 36.8784 86.164 37.1046 84.62 37.0778C80.876 37.0127 77.1268 37.0457 73.3922 36.6987C72.2137 36.5892 71.0384 36.4488 69.861 36.33C69.704 36.3145 69.643 36.2835 69.6513 36.1048C69.7649 33.7758 69.6616 31.4447 69.7009 29.5681L69.7029 29.5712Z"
        fill="#FFEBB6"
        variants={pathVariants}
      />
      <motion.path
        d="M60.0011 28.8502C60.0011 26.6875 60.0011 24.5247 60.0011 22.362C60.0011 21.3726 60.4473 20.9212 61.4336 20.9202C62.9787 20.9171 64.5228 20.9161 66.0679 20.9202C67.0057 20.9233 67.4684 21.3829 67.4694 22.3197C67.4725 26.6709 67.4725 31.0222 67.4694 35.3735C67.4694 36.3051 66.9923 36.7792 66.0679 36.7812C64.4846 36.7843 62.9013 36.7833 61.318 36.7812C60.5 36.7812 60.0042 36.2844 60.0021 35.4541C59.998 33.2531 60.0011 31.0511 60.0011 28.8502Z"
        fill="#FFEBB6"
        variants={pathVariants}
      />
    </g>
    <motion.path
      d="M64.0288 11.2289C64.4897 9.84728 65.2274 8.58012 66.4351 7.5544L64.0288 11.2289ZM64.0288 11.2289C63.7463 10.3517 63.3385 9.51769 62.7735 8.74411C62.2949 8.08581 61.7223 7.48346 60.976 7.07751C61.4656 6.81349 61.898 6.45422 62.2861 6.04001L62.287 6.03901C63.1107 5.15495 63.6644 4.14279 64.0426 3.05905C64.1649 3.39839 64.3089 3.72916 64.475 4.05216L64.4751 4.05233C65.0974 5.26151 65.8778 6.43876 67.131 7.06528C66.8836 7.20404 66.6522 7.36986 66.4352 7.55429L64.0288 11.2289Z"
      stroke="#FFA4A4"
      variants={pathVariants}
    />
    <motion.path
      d="M56.2001 18.7198C54.8427 19.9424 54.0301 21.4603 53.5308 23.1165C53.2217 22.0476 52.7643 21.033 52.1183 20.095C51.5669 19.2906 50.9013 18.5583 50.0164 18.09C50.6107 17.779 51.1284 17.3326 51.588 16.8122L51.5889 16.8112C52.5188 15.7527 53.133 14.5357 53.5462 13.2309C53.6886 13.6699 53.8602 14.0966 54.0617 14.5122L54.0618 14.5124C54.7645 15.9607 55.6499 17.3688 57.1049 18.0717C56.7792 18.2473 56.4786 18.4688 56.2001 18.7198Z"
      stroke="#FFC5A4"
      variants={pathVariants2}
    />
    <motion.path
      d="M105.758 23.8702C104.083 25.3293 103.113 27.1622 102.534 29.1652C102.171 27.8377 101.609 26.5817 100.788 25.4287C100.114 24.4773 99.3006 23.6241 98.2126 23.1025C98.9534 22.7524 99.5962 22.2261 100.167 21.6014L100.168 21.6004C101.326 20.3257 102.068 18.8463 102.554 17.2561C102.737 17.8531 102.968 18.4298 103.249 18.9888L103.249 18.989C104.101 20.688 105.169 22.3111 106.935 23.0784C106.51 23.2837 106.119 23.5556 105.758 23.8702Z"
      stroke="#FFFBA4"
      variants={pathVariants2}
    />
    <motion.circle
      cx={93}
      cy={7}
      r={3}
      stroke="#FFF0A4"
      strokeWidth={2}
      variants={pathVariants}
    />
  </motion.svg>
);

export default IconThumbs;
