import React, { useState } from 'react';
import { motion } from 'framer-motion';

const transition = {
  duration: 0.15,
  ease: 'easeInOut',
};

function ArrowLeft({ className, onClick, color }) {
  const [isHovered, setHovered] = useState(false);

  return (
    <svg
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle
        cx={75}
        cy={75}
        r={74}
        stroke="#fff"
        strokeOpacity={0.5}
        strokeWidth={2}
      />
      <motion.circle
        cx={75}
        cy={75}
        r={74}
        fill="#fff"
        fillOpacity={0}
        initial={{ scale: 0.9 }}
        animate={
          isHovered
            ? { fillOpacity: 1, scale: 1 }
            : { fillOpacity: 0, scale: 0.9 }
        }
        transition={transition}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.86 74.758l-.26-.248.26-.272v.52zm.278.265L62.572 94.53l-.69.723-21.334-20.365-.362-.345.346-.362 21.334-22.304.723.691-20.524 21.456h67.677v1H42.138z"
        fill="#000"
        animate={
          isHovered
            ? { fill: color === false ? '#000000' : color }
            : { fill: '#000' }
        }
        transition={transition}
      />
      <motion.path
        d="M41.6 74.51l-.722-.692a1 1 0 00.032 1.415l.69-.723zm.26.248l-.69.723a1 1 0 001.69-.723h-1zm0-.52h1a1 1 0 00-1.722-.692l.722.692zm.278.785v-1a1 1 0 00-.69 1.724l.69-.724zM62.572 94.53l.724.69a1 1 0 00-.033-1.414l-.69.724zm-.69.723l-.69.723a1 1 0 001.413-.033l-.723-.69zM40.548 74.887l.69-.723-.69.723zm-.362-.345l-.723-.691a1 1 0 00.032 1.414l.69-.723zm.346-.362l.722.691-.722-.69zm21.334-22.304l.691-.723a1 1 0 00-1.414.032l.723.691zm.723.691l.722.691a1 1 0 00-.031-1.413l-.691.722zM42.065 74.023l-.722-.691a1 1 0 00.722 1.691v-1zm67.677 0h1a1 1 0 00-1-1v1zm0 1v1a1 1 0 001-1h-1zm-68.832.21l.26.248 1.38-1.446-.26-.249-1.38 1.447zm.228-1.687l-.26.272 1.445 1.383.26-.272-1.445-1.383zm1.722 1.212v-.52h-2v.52h2zm-1.412.989l20.434 19.505 1.38-1.447L42.83 74.3l-1.381 1.447zm20.401 18.091l-.69.724 1.446 1.38.69-.723-1.446-1.38zm.723.69L41.238 74.165l-1.38 1.447 21.334 20.364 1.38-1.446zM41.238 74.165l-.362-.346-1.38 1.447.361.346 1.381-1.447zm-.33 1.069l.346-.362-1.445-1.382-.346.362 1.445 1.382zm.346-.362L62.59 52.567l-1.446-1.382L39.81 73.489l1.445 1.382zM61.174 52.6l.723.69 1.383-1.444-.723-.692-1.382 1.446zm.692-.723L41.343 73.332l1.445 1.383 20.523-21.457-1.445-1.382zm-19.8 23.147h67.676v-2H42.065v2zm66.676-1v1h2v-1h-2zm1 0H42.138v2h67.604v-2z"
        fill="#000"
        animate={
          isHovered
            ? { fill: color === false ? '#000000' : color }
            : { fill: '#000' }
        }
        transition={transition}
      />
    </svg>
  );
}

export default ArrowLeft;
