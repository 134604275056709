import React, { useContext } from 'react';
import './ForYourInfo.scss';
import {
  BubbleText,
  DoctorFyi,
  DoctorFyiHand,
  IconHover,
} from '../components/svg';
import { motion } from 'framer-motion';
import mainContext from '../context/MainContext';
import { HiX } from 'react-icons/hi';

const variants = {
  hidden: {
    scale: 0.7,
    opacity: 0,
    x: -150,
    y: 100,
    zIndex: -1,
  },
  popup: {
    scale: 1,
    opacity: 1,
    x: 0,
    y: 0,
    zIndex: 25,
  },
};

function ForYourInfo({ children }) {
  const { isFyiHovered, setFyiHovered } = useContext(mainContext);

  return (
    <>
      <div className="forYourInfo hidden sm:flex p-14 border-t border-[#787878] font-nunito-sans">
        <h3 className="text-3.2xl xl:text-4xl font-extrabold z-10">
          Tahukah Anda?
        </h3>
        <div className="forYourInfo-img">
          <div
            className="w-full h-full z-20"
            onMouseEnter={() => setFyiHovered(true)}
            onMouseLeave={() => setFyiHovered(false)}
          >
            <DoctorFyi
              className={`forYourInfo-img-doc select-none z-10`}
              draggable="false"
            />
            <DoctorFyiHand
              className="forYourInfo-img-doc-hand select-none z-20"
              draggable="false"
            />
            <IconHover className="forYourInfo-img-hover select-none z-10" />
          </div>
        </div>
        <motion.div
          className="forYourInfo-bubble scrollbar-custom"
          animate={isFyiHovered ? { zIndex: 20 } : { zIndex: -1 }}
          onMouseEnter={() => setFyiHovered(true)}
          onMouseLeave={() => setFyiHovered(false)}
        >
          <motion.div
            className="w-full h-full"
            variants={variants}
            initial="hidden"
            animate={isFyiHovered ? 'popup' : 'hidden'}
          >
            <BubbleText className="forYourInfo-bubble-img" />
            <div className="forYourInfo-bubble-text">
              <div className="text-sm xl:text-lg font-nunito-sans text-primary">
                {children}
              </div>
            </div>
            <HiX className="forYourInfo-bubble-close w-8 h-auto hidden" />
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}

export default ForYourInfo;
