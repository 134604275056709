import React, { useContext, useEffect, useState } from 'react';
import './ProductSliderMobile.scss';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import mainContext from '../../context/MainContext';

function ProductSliderMobile({ img_front, img_back, img_object, slug }) {
  const [faqScroll, setFaqScroll] = useState(0);
  const [faqScrollerSize, setFaqScrollerSize] = useState(null);
  const maxScroll = 2;
  const { assetsUrl } = useContext(mainContext);

  const scrollToNextPage = () => {
    const faqScroller = document.querySelector(`.productSliderMobile__slider`);
    const faqItemSize = faqScroller.querySelector(
      '.productSliderMobile__slider-image'
    ).clientWidth;

    if (faqScroll !== faqScrollerSize * maxScroll) {
      setFaqScroll(faqScroll + faqScrollerSize);
    }

    faqScroller.scrollTo(faqScroller.scrollLeft + faqItemSize, 0);
  };

  const scrollToPrevPage = () => {
    const faqScroller = document.querySelector(`.productSliderMobile__slider`);
    const faqItemSize = faqScroller.querySelector(
      '.productSliderMobile__slider-image'
    ).clientWidth;

    if (faqScroll !== 0) {
      setFaqScroll(faqScroll - faqScrollerSize);
    }

    faqScroller.scrollTo(faqScroller.scrollLeft - faqItemSize, 0);
  };

  const handleScroll = (e) => {
    setFaqScroll(Math.floor(e.currentTarget.scrollLeft));
  };

  useEffect(() => {
    if (faqScrollerSize === null) {
      setFaqScrollerSize(
        document.querySelector(`.productSliderMobile__slider`).clientWidth
      );
    }
  }, [faqScrollerSize]);

  return (
    <div className="productSliderMobile relative flex sm:hidden w-10/12 mx-auto">
      <div
        className="productSliderMobile__slider w-full flex flex-nowrap mx-auto space-x-5"
        onScroll={(e) => handleScroll(e)}
      >
        <img
          className="productSliderMobile__slider-image w-full h-auto"
          src={`${assetsUrl}/product/${slug}/${img_front}`}
          alt="Product"
        />
        <img
          className="productSliderMobile__slider-image w-full h-auto"
          src={`${assetsUrl}/product/${slug}/${img_back}`}
          alt="Product"
        />
        <img
          className="productSliderMobile__slider-image w-full h-auto"
          src={`${assetsUrl}/product/${slug}/${img_object}`}
          alt="Product"
        />
      </div>
      <button
        className={`absolute absolute-y-center -left-10 rounded-full p-[6px] bg-gray-200 shadow-lg ${
          faqScroll === 0 ? 'opacity-60' : 'opacity-100'
        } transition-all duration-200 ease-out`}
        onClick={() => scrollToPrevPage()}
      >
        <VscChevronLeft
          className={`w-6 h-6 text-primary ${
            faqScroll === 0 ? 'opacity-60' : 'opacity-100'
          } transition-all duration-200 ease-out`}
        />
      </button>
      <button
        className={`absolute absolute-y-center -right-10 rounded-full p-[6px] bg-gray-200 shadow-lg ${
          faqScroll >= maxScroll * (faqScrollerSize + 20)
            ? 'opacity-60'
            : 'opacity-100'
        } transition-all duration-200 ease-out`}
        onClick={() => scrollToNextPage()}
      >
        <VscChevronRight
          className={`w-6 h-6 text-primary ${
            faqScroll >= maxScroll * (faqScrollerSize + 20)
              ? 'opacity-60'
              : 'opacity-100'
          } transition-all duration-200 ease-out`}
        />
      </button>
    </div>
  );
}

export default ProductSliderMobile;
