import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const marketplaceLink = {
  shopee: "https://shope.ee/7zfWA2u15c",
  tokopedia: "https://www.tokopedia.com/dhcsupplement",
  lazada: "https://s.lazada.co.id/l.1gtf",
  aladin: "https://aladinmall.id/dhcsupplement",
};

const DirectPromo = () => {
  const { marketplace } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (marketplace) {
      window.open(marketplaceLink[marketplace], "_blank");

      setTimeout(() => {
        navigate("/");
      }, 1200);
    }
  }, [marketplace, navigate]);

  return <div></div>;
};

export default DirectPromo;
