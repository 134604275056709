import { Route, Routes } from "react-router-dom";

import "./App.scss";

import AboutUs from "./pages/AboutUs";
import ContactPage from "./pages/ContactPage";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import OurProduct from "./pages/OurProduct";
import ProductDetails from "./pages/ProductDetails";
import FAQPage from "./pages/FAQPage";
import LinkTree from "./pages/LinkTree";
import DirectMarketplace from "./pages/DIrectMarketplace";
import DirectPromo from "./pages/DirectPromo";
import Article from "./pages/article/Article";
import ArticleDetails from "./pages/article/ArticleDetails";
import moment from "moment";
import "moment/locale/id";
import ArticleCategory from "./pages/article/ArticleCategory";
import LinkStore from "./pages/LinkStore";
import ListActivity from "./pages/activity/ListActivity";
import ActivityDetails from "./pages/activity/ActivityDetails";

function App() {
  moment.locale("id");

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/our-product" element={<OurProduct />} />
      <Route path="/our-product/:name_product" element={<ProductDetails />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/link" element={<LinkTree />} />
      <Route path="/link-store" element={<LinkStore />} />
      <Route path="/link/:marketplace" element={<DirectMarketplace />} />
      <Route path="/link-promo/:marketplace" element={<DirectPromo />} />
      <Route path="/article" element={<Article />} />
      <Route path="/article/c/:category" element={<ArticleCategory />} />
      <Route path="/article/c/:category/:slug" element={<ArticleDetails />} />
      <Route path="/article/:slug" element={<ArticleDetails />} />
      <Route path="/activity" element={<ListActivity />} />
      <Route path="/activity/:slug" element={<ActivityDetails />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
