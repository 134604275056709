import React, { useState } from "react";
import "./Footer.scss";
import { CgFacebook } from "react-icons/cg";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { IconTokopedia, IconShopee, IconLazada } from "./svg";
import SocmedFooterMobile from "./mobile/SocmedFooterMobile";
import { DataMenu } from "../utils/dummyData";

function Footer({ activeNav }) {
  const [isHovered, setHovered] = useState(false);
  let currentYear = new Date().getFullYear();

  return (
    <footer className="relative footer flex flex-col w-full text-grayfooter font-sans z-[2]">
      <div className="w-full sm:w-10/12 flex flex-col items-center justify-center mx-auto space-y-4 xl:space-y-5 p-4 xl:p-5 text-center">
        <img
          className="w-3/12 sm:w-[12%]"
          src="/images/universal/logo-light.png"
          alt="Logo"
        />
        <p className="w-full sm:w-8/12 text-lg font-serif">
          Made In Japan. Loved Worldwide.
        </p>
        <a
          href="https://top.dhc.co.jp/contents/en/worldwide/"
          target="__blank"
          referrerPolicy="no-referrer"
          className="flex items-center space-x-2"
        >
          <img
            src="/images/universal/globe.png"
            alt="Globe"
            className="text-white w-5 h-5 sm:w-4 sm:h-4 rounded-full"
          />
          <p className="font-serif text-base sm:text-sm">Visit DHC Worldwide</p>
        </a>
      </div>
      <SocmedFooterMobile isHovered={isHovered} setHovered={setHovered} />
      <nav className="w-full justify-center flex items-center pb-5 px-5">
        <div className="w-[35%] hidden lg:flex justify-end items-center space-x-4 sm:space-x-7 xl:space-x-10 pr-12 xl:pr-16 2xl:pr-20">
          <a
            href="https://www.tokopedia.com/dhcsupplement"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[10]"
              animate={isHovered === 1 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <IconTokopedia
                className={`w-[55%] h-auto bg-transparent transition-all duration-200 ease-out`}
                color={isHovered === 1 ? "#ffffff" : "#003994"}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#3FAF48] rounded-full absolute top-0"
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 1 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
          <a
            href="https://shopee.co.id/dhcsupplement"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(2)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[1]"
              animate={isHovered === 2 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <IconShopee
                className={`w-[60%] h-auto bg-transparent transition-all duration-200 ease-out`}
                color={isHovered === 2 ? "#ffffff" : "#003994"}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#E74B2E] rounded-full absolute top-0"
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 2 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
          <a
            href="https://s.lazada.co.id/l.1gtf"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(3)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[1]"
              animate={isHovered === 3 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <IconLazada
                className={`w-[65%] h-auto bg-transparent transition-all duration-200 ease-out`}
                color={isHovered === 3 ? "#ffffff" : "#003994"}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 rounded-full absolute top-0"
              style={{
                background:
                  "linear-gradient(90deg, #fead00 0%, #fc9f00 7.14%, #fb9100 14.29%, #fa8200 21.43%, #fb7000 28.57%, #fe5900 35.71%, #fe003c 50%, #fe0062 57.14%, #fe007b 64.29%, #fe008e 71.43%, #fe009f 78.57%, #fe00af 85.71%, #fe00cc 100%)",
              }}
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 3 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
        </div>

        <div className="w-11/12 sm:w-8/12 lg:w-auto mx-auto shrink-0 flex flex-wrap lg:flex-nowrap justify-start space-x-0 lg:space-x-7 xl:space-x-9 text-sm font-nunito-sans">
          {DataMenu.map((menu, index) => {
            return (
              <Link
                key={index}
                to={menu.slug === "home" ? "/" : `/${menu.slug}`}
                className={`w-6/12 lg:w-auto ${
                  activeNav === menu.slug ? "text-secondary" : ""
                }`}
              >
                {menu.name}
              </Link>
            );
          })}
        </div>

        <div className="w-[35%] hidden lg:flex space-x-4 sm:space-x-7 xl:space-x-10 pl-12 xl:pl-16 2xl:pl-20">
          <a
            href="https://www.facebook.com/profile.php?id=100090106659567"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(4)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[1]"
              animate={isHovered === 4 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <CgFacebook
                className={`w-[75%] h-auto bg-transparent ${
                  isHovered === 4 ? "text-white" : "text-primary"
                } transition-all duration-200 ease-out`}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#395693] rounded-full absolute top-0"
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 4 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
          <a
            href="https://www.instagram.com/dhcsupplement.id/"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(5)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[1]"
              animate={isHovered === 5 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <FaInstagram
                className={`w-[75%] h-auto bg-transparent ${
                  isHovered === 5 ? "text-white" : "text-primary"
                } transition-all duration-200 ease-out`}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 rounded-full absolute top-0"
              style={{
                background:
                  "linear-gradient(163deg, #4964da 0%, rgba(146, 73, 221, 0.8) 11.8%, rgba(176, 60, 196, 0.6) 23.6%, rgba(192, 55, 173, 0.4) 35.4%, rgba(217, 46, 116, 0) 59%), radial-gradient(100% 100% at 10% 100%, #fdd575 0%, #fcc06a 14.29%, #fbaa63 28.57%, #f99262 42.86%, #f77868 57.14%, #f55973 71.43%, #d22e8c 100%)",
              }}
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 5 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
          <a
            href="https://twitter.com/DhcsupplementId"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(6)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[1]"
              animate={isHovered === 6 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <AiOutlineTwitter
                className={`w-[75%] h-auto bg-transparent ${
                  isHovered === 6 ? "text-white" : "text-primary"
                } transition-all duration-200 ease-out`}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#55ACEE] rounded-full absolute top-0"
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 6 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
          <a
            href="https://www.tiktok.com/@dhcsupplement.id"
            target="__blank"
            referrerPolicy="no-referrer"
            className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
            onMouseEnter={() => setHovered(7)}
            onMouseLeave={() => setHovered(false)}
          >
            <motion.div
              className="w-full h-full flex justify-center items-center z-[1]"
              animate={isHovered === 7 ? { rotateY: 360 } : { rotateY: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            >
              <FaTiktok
                className={`w-[60%] h-auto bg-transparent ${
                  isHovered === 7 ? "text-white" : "text-primary"
                } transition-all duration-200 ease-out`}
              />
            </motion.div>
            <motion.div
              className="w-8 h-8 xl:w-9 xl:h-9 bg-[#000000] rounded-full absolute top-0"
              initial={{ y: 36, scale: 0.9 }}
              animate={isHovered === 7 ? { y: 0 } : { y: 36 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
            ></motion.div>
          </a>
        </div>
      </nav>

      <div className="copyright-border py-4">
        <h1 className="text-center text-sm xl:text-base font-nunito-sans text-[#809cca]">
          Copyright &copy; {currentYear} DHC Corporation. All Rights Reserved.
        </h1>
      </div>
    </footer>
  );
}

export default Footer;
