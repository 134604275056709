import React, { useState } from 'react';
import './ShareModal.scss';

import Close from './svg/Close';
import { motion } from 'framer-motion';
import { CgFacebook } from 'react-icons/cg';
import { AiOutlineTwitter } from 'react-icons/ai';
import { SiLine, SiLinkedin } from 'react-icons/si';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { HiOutlineLink } from 'react-icons/hi';
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils/Utils';
import { screenMobile } from '../utils/Breakpoints';

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: 'backOut', duration: 0.25 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: 'backOut', duration: 0.25 },
  },
};

const variants2 = {
  hidden: {
    scale: 0.6,
    transition: { ease: 'easeInOut', duration: 0.25 },
  },
  show: {
    scale: 1,
    transition: { ease: 'easeInOut', duration: 0.25 },
  },
};

function ShareModal({
  showShareModal,
  setShowShareModal,
  toggleShareModal,
  product,
}) {
  const [isHovered, setHovered] = useState(false);
  const currentUrl = window.location.href;

  const handleCopied = () => {
    toast.success('Tautan berhasil disalin!', toastConfig);
    toggleShareModal();
  };

  return (
    <motion.div
      className="shareModal scrollbar-custom"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={showShareModal ? 'show' : 'hidden'}
      onClick={toggleShareModal}
    >
      <motion.div
        className="shareModal-card bg-white max-w-full w-[94%] sm:w-[500px] h-auto relative flex flex-col p-8 rounded-[10px] lg:rounded-[35px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={showShareModal ? 'show' : 'hidden'}
      >
        <button
          onClick={toggleShareModal}
          className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-6 sm:right-6 cursor-pointer rounded-full z-10"
        >
          <Close color="#717171" className="w-full h-auto" />
        </button>

        <div className="relative w-full flex flex-col space-y-6 justify-center items-center">
          <h2 className="text-xl sm:text-2xl text-graylight font-medium">
            Yuk Share Informasi Ini.
          </h2>
          <div className="flex flex-row space-x-3 sm:space-x-5 items-center font-nunito-sans">
            <CopyToClipboard
              text={currentUrl}
              onCopy={handleCopied}
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
            >
              <div className="min-w-[72px] flex flex-col space-y-2 justify-center items-center">
                <div
                  className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px] cursor-pointer"
                  onMouseEnter={() => setHovered(1)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <motion.div
                    className="w-full h-full flex justify-center items-center z-[1]"
                    animate={
                      isHovered === 1 ? { rotateY: 360 } : { rotateY: 0 }
                    }
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                  >
                    <HiOutlineLink
                      className={`w-[75%] h-auto bg-transparent ${
                        window.innerWidth <= screenMobile
                          ? 'text-white'
                          : isHovered === 1
                          ? 'text-white'
                          : 'text-gray-400'
                      } transition-all duration-200 ease-out`}
                    />
                  </motion.div>
                  <motion.div
                    className="w-9 h-9 xl:w-10 xl:h-10 bg-gray-800 rounded-full absolute top-0"
                    initial={{ y: 40 }}
                    animate={
                      window.innerWidth <= screenMobile
                        ? { y: 0 }
                        : isHovered === 1
                        ? { y: 0 }
                        : { y: 40 }
                    }
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                  ></motion.div>
                </div>
                <p
                  className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                    isHovered === 1 ? 'text-gray-800' : 'text-gray-400'
                  }`}
                >
                  Salin Link
                </p>
              </div>
            </CopyToClipboard>

            <FacebookShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              quote={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
              hashtag="#suplemenkesehatan #dhcsupplement #vitamin"
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(2)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 2 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <CgFacebook
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 2
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#395693] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 2
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 2 ? 'text-[#395693]' : 'text-gray-400'
                }`}
              >
                Facebook
              </p>
            </FacebookShareButton>

            <WhatsappShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              title={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(3)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 3 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <FaWhatsapp
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 3
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#26D366] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 3
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 3 ? 'text-[#26D366]' : 'text-gray-400'
                }`}
              >
                WhatsApp
              </p>
            </WhatsappShareButton>

            <TwitterShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              title={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
              hashtags={['suplemenkesehatan', 'dhcindonesia']}
              via="DhcsupplementId"
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(4)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 4 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <AiOutlineTwitter
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 4
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#55ACEE] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 4
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 4 ? 'text-[#55ACEE]' : 'text-gray-400'
                }`}
              >
                Twitter
              </p>
            </TwitterShareButton>
          </div>

          <div className="flex flex-row space-x-3 sm:space-x-5 items-center font-nunito-sans">
            <LinkedinShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              title={`DHC Suplemen Kesehatan Jepang`}
              summary={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
              source="dhcsupplement.co.id"
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(5)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 5 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <SiLinkedin
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 5
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#0077B7] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 5
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 5 ? 'text-[#0077B7]' : 'text-gray-400'
                }`}
              >
                Linked In
              </p>
            </LinkedinShareButton>

            <LineShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              title={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(6)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 6 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <SiLine
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 6
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#00C854] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 6
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 6 ? 'text-[#00C854]' : 'text-gray-400'
                }`}
              >
                Line
              </p>
            </LineShareButton>

            <TelegramShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              title={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(7)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 7 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <FaTelegramPlane
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 7
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#34AADF] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 7
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 7 ? 'text-[#34AADF]' : 'text-gray-400'
                }`}
              >
                Telegram
              </p>
            </TelegramShareButton>

            <EmailShareButton
              className="min-w-[72px] flex flex-col space-y-2 justify-center items-center"
              url={currentUrl}
              subject={`DHC Suplemen Kesehatan Jepang`}
              body={`DHC ${product.name_product}, Suplemen Kesehatan Jepang.`}
            >
              <div
                className="w-9 h-9 xl:w-10 xl:h-10 relative flex justify-center items-center bg-white rounded-full overflow-hidden border border-gray-300 p-[2px]"
                onMouseEnter={() => setHovered(8)}
                onMouseLeave={() => setHovered(false)}
              >
                <motion.div
                  className="w-full h-full flex justify-center items-center z-[1]"
                  animate={isHovered === 8 ? { rotateY: 360 } : { rotateY: 0 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <MdOutlineEmail
                    className={`w-[75%] h-auto bg-transparent ${
                      window.innerWidth <= screenMobile
                        ? 'text-white'
                        : isHovered === 8
                        ? 'text-white'
                        : 'text-gray-400'
                    } transition-all duration-200 ease-out`}
                  />
                </motion.div>
                <motion.div
                  className="w-9 h-9 xl:w-10 xl:h-10 bg-[#D44638] rounded-full absolute top-0"
                  initial={{ y: 40 }}
                  animate={
                    window.innerWidth <= screenMobile
                      ? { y: 0 }
                      : isHovered === 8
                      ? { y: 0 }
                      : { y: 40 }
                  }
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                ></motion.div>
              </div>
              <p
                className={`transition-all duration-200 ease-out text-sm sm:text-base ${
                  isHovered === 8 ? 'text-[#D44638]' : 'text-gray-400'
                }`}
              >
                E-mail
              </p>
            </EmailShareButton>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default ShareModal;
