import React from "react";
import "./LinkTree.scss";

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const LinkTree = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href={`https://dhcsupplement.co.id/link`} />
        <title>Links - DHC Supplement</title>
        <meta
          name="description"
          content="Link sosial media dan Official E-Commerce DHC Supplement. Ikuti sosial media dan Official E-Commerce kami agar kalian selalu update informasi terbaru dari produk-produk DHC."
        />
        <meta
          name="keywords"
          content="dhc supplement, suplemen dhc, suplemen kesehatan, link suplemen dhc, social media suplemen dhc, official e-commerce dhc supplement"
        />
        <meta property="og:title" content={`Links - DHC Supplement`} />
        <meta
          property="og:description"
          content="Link sosial media dan Official E-Commerce DHC Supplement. Ikuti sosial media dan Official E-Commerce kami agar kalian selalu update informasi terbaru dari produk-produk DHC."
        />
        <meta property="og:url" content="https://dhcsupplement.co.id/link" />
      </Helmet>

      <div className="linkTree">
        <div className="linkTree__body mx-auto w-full h-full py-10 flex flex-col space-y-6 font-nunito-sans text-[#252525]">
          <div className="flex items-center justify-center w-24 h-24 md:w-24 md:h-24 lg:w-28 lg:h-28 mx-auto bg-primary rounded-full shrink-0">
            <img
              src="/images/universal/logo-light.png"
              alt="Logo"
              className="w-8/12 h-auto object-cover"
            />
          </div>
          <div className="flex flex-col space-y-1 justify-center items-center text-center text-white">
            <h1 className="text-xl sm:text-2.1xl font-bold">
              DHC Supplement Indonesia
            </h1>
            <p className="text-sm sm:text-lg font-semibold">
              Made In Japan, Loved Worldwide{" "}
              <strong className="font-semibold">#ActiveWithDHC</strong>
            </p>
          </div>
          <div className="w-[82%] sm:w-8/12 md:w-6/12 lg:w-4/12 flex flex-col space-y-5 mx-auto pb-5">
            <a
              href="https://linktr.ee/dhcsupplement_id"
              target="_blank"
              rel="noreferrer"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/outlets.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p>Outlets</p>
              </div>
            </a>

            <a
              href="https://dhcsupplement.co.id"
              target="_blank"
              rel="noreferrer"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/dhc.svg"
                    alt="Store"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p>Website</p>
              </div>
            </a>

            <Link
              to="/link/shopee"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/shopee.svg"
                    alt="Store"
                    className="w-auto h-[94%] object-contain"
                  />
                </div>

                <p>Shopee</p>
              </div>
            </Link>

            <Link
              to="/link/tokopedia"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/tokopedia.svg"
                    alt="Store"
                    className="w-auto h-[94%] object-contain"
                  />
                </div>

                <p>Tokopedia</p>
              </div>
            </Link>

            <Link
              to="/link/lazada"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/lazada.svg"
                    alt="Store"
                    className="w-auto h-[94%] object-contain"
                  />
                </div>

                <p>Lazada</p>
              </div>
            </Link>

            <Link
              to="/link/aladin"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/aladin.svg"
                    alt="Store"
                    className="w-auto h-[94%] object-contain"
                  />
                </div>

                <p>Aladin Mall</p>
              </div>
            </Link>

            <Link
              to="/link/kalcare"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/kalcare.svg"
                    alt="Store"
                    className="w-auto h-[94%] object-contain"
                  />
                </div>

                <p>Kalstore</p>
              </div>
            </Link>

            <Link
              to="/link/tiktok"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/tiktok.svg"
                    alt="Store"
                    className="w-auto h-[94%] object-contain"
                  />
                </div>

                <p>Tiktok</p>
              </div>
            </Link>

            <a
              href="https://www.instagram.com/dhcsupplement.id/"
              target="_blank"
              rel="noreferrer"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/instagram.svg"
                    alt="Store"
                    className="w-auto h-[90%] object-contain"
                  />
                </div>

                <p>Instagram</p>
              </div>
            </a>

            <a
              href="https://wa.me/6281229996511"
              target="_blank"
              rel="noreferrer"
              className="relative flex w-full rounded-full"
            >
              <div className="linkCard relative flex items-center justify-center w-full p-4 text-[#5a421e] text-sm sm:text-base font-bold transition-hover">
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-11 sm:w-11 rounded-full left-[6px] absolute-y-center border-2 border-[#5a421e]">
                  <img
                    src="/images/link-icon/whatsapp.svg"
                    alt="Store"
                    className="w-auto h-[90%] object-contain"
                  />
                </div>

                <p>Whatsapp</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkTree;
