import React, { useContext } from "react";
import "./PartnerModal.scss";

import { motion } from "framer-motion";
import Close from "./svg/Close";
import mainContext from "../context/MainContext";

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: "backOut", duration: 0.25 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: "backOut", duration: 0.25 },
  },
};

const variants2 = {
  hidden: {
    scale: 0.6,
    transition: { ease: "easeInOut", duration: 0.25 },
  },
  show: {
    scale: 1,
    transition: { ease: "easeInOut", duration: 0.25 },
  },
};

function PartnerModal({ product }) {
  const { showPartnerModal, partnerList, setShowPartnerModal, assetsUrl } =
    useContext(mainContext);

  const togglePartnerModal = () => {
    setShowPartnerModal(!showPartnerModal);
  };

  return (
    <motion.div
      className="partnerModal scrollbar-custom"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={showPartnerModal ? "show" : "hidden"}
      onClick={togglePartnerModal}
    >
      <motion.div
        className="partnerModal-card bg-white max-w-full w-[95%] sm:w-[60%] h-auto max-h-[95vh] relative flex flex-col rounded-[10px] lg:rounded-[35px] overflow-hidden"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={showPartnerModal ? "show" : "hidden"}
      >
        <div className="w-full h-full flex relative overflow-y-auto p-4 lg:p-8">
          <button
            onClick={togglePartnerModal}
            className="w-8 h-8 2xl:w-9 2xl:h-9 absolute top-4 right-4 sm:top-6 sm:right-6 cursor-pointer rounded-full z-10"
          >
            <Close color="#717171" className="w-full h-auto" />
          </button>

          <div className="relative w-full h-full flex flex-col space-y-5 justify-center items-center font-nunito-sans">
            <div className="w-8/12 lg:w-4/12 h-auto">
              {partnerList !== null && (
                <img
                  className="w-full object-contain"
                  src={`${assetsUrl}/partner/${partnerList.img}`}
                  alt="Logo"
                />
              )}
            </div>
            {partnerList !== null && partnerList.name_partner === "century" ? (
              <h2 className="w-full text-lg lg:text-xl text-graylight text-center font-semibold whitespace-pre-wrap">
                {`DHC sudah tersedia di Century di Mall-mall wilayah Jakarta.\nHubungi Century terdekat anda untuk info ketersediaan produk.`}
              </h2>
            ) : (
              <h2 className="w-full text-lg lg:text-xl text-graylight text-center font-semibold whitespace-pre-wrap">
                {/* {partnerList !== null && partnerList.location.length === 0
                ? `DHC sudah tersedia di seluruh `
                : `DHC sudah tersedia di `}
              <span className="capitalize">
                {partnerList !== null && partnerList.name_partner}
              </span>
              {`\nTemukan produk kami di `}
              <span className="capitalize">
                {partnerList !== null && partnerList.name_partner}
              </span>
              {` terdekat anda `} */}
                {partnerList !== null && partnerList.description}{" "}
                {partnerList !== null &&
                partnerList.link_url !== null &&
                partnerList.link_url !== "" ? (
                  <a
                    className="underline italic font-bold hover:text-graydark transition-all duration-150"
                    href={partnerList.link_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    click
                  </a>
                ) : (
                  ""
                )}
              </h2>
            )}

            <div className="w-full flex flex-col space-y-4">
              {partnerList !== null &&
                partnerList.length !== 0 &&
                partnerList.location.map((loc, locIndex) => {
                  return (
                    <div
                      key={locIndex}
                      className="flex flex-col space-y-1 text-graylight"
                    >
                      <h5 className="text-base lg:text-xl font-semibold capitalize">
                        {loc.location_title}
                      </h5>
                      <ul className="w-full text-sm lg:text-lg list-outside list-disc ml-4">
                        {loc.location_list.map((item, index) => {
                          return (
                            <li key={index} className="">
                              {/* <GoPrimitiveDot /> */}
                              <span className="capitalize">
                                {partnerList.name_partner + " "}
                              </span>
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default PartnerModal;
