// Laptop Screen
const screenLaptopXs = 1024;
const screenLaptopSm = 1280;
const screenLaptopMd = 1440;
const screenLaptopLg = 1536;
const screenLaptopXl = 1920;

// Mobile Screen
const screenMobile = 640;

export {
  screenLaptopXs,
  screenLaptopSm,
  screenLaptopMd,
  screenLaptopLg,
  screenLaptopXl,
  screenMobile,
};
