import React from "react";
import { Helmet } from "react-helmet-async";

const MetaKeywords = ({ slug, path, keyword, description, title }) => {
  //   const { getMeta } = useContext(mainContext);
  //   const location = useLocation();

  //   const [dataMeta, setDataMeta] = useState(null);
  //   const [meta, setMeta] = useState(null);

  //   useEffect(() => {
  //     if (dataMeta === null) {
  //       getMeta(setDataMeta);
  //     }
  //     if (dataMeta !== null && dataMeta?.status !== "error" && meta === null) {
  //       if (dataMeta?.find((item) => item?.slug === location?.pathname)) {
  //         const currMeta = dataMeta?.find(
  //           (item) => item?.slug === location?.pathname
  //         );

  //         setMeta(currMeta);
  //       } else {
  //         setMeta({
  //           slug: location?.pathname,
  //           title: "DHC Supplement",
  //           keyword:
  //             "suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen",
  //           description:
  //             "DHC Supplement Made in Japan. Loved Worldwide produk berkualitas tinggi dan inovatif dari jepang yang dicintai di seluruh penjuru dunia",
  //         });
  //       }
  //     }
  //   }, [dataMeta, getMeta, location?.pathname, meta]);

  return (
    <Helmet prioritizeSeoTags>
      <link rel="canonical" href={`https://dhcsupplement.co.id${path}`} />
      <title>{`${title} - DHC Supplement`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword} />
      <meta property="og:title" content={`${title} - DHC Supplement`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://dhcsupplement.co.id${path}`} />
    </Helmet>
  );
};

export default MetaKeywords;
