import { createContext, useEffect, useState } from "react";
import axios from "axios";

const mainContext = createContext({
  assetsUrl: "https://api.dhcsupplement.co.id/assets/upload",
  assetsUrl2: "https://dhcsupplement.co.id/assets/upload",
  navbar: false,
  isFyiHovered: false,
  showFyiModal: false,
  showSubsModal: false,
  subsStatus: "Subscribe",
  subsData: {
    status: "",
    msg: "",
  },
  contactStatus: "Kirim",
  contactError: "",
  showPartnerModal: false,
  partnerList: null,
  products: null,
  setNavbar: () => {},
  setFyiHovered: () => {},
  setShowFyiModal: () => {},
  setShowSubsModal: () => {},
  setSubsStatus: () => {},
  setSubsData: () => {},
  setContactStatus: () => {},
  setContactError: () => {},
  setShowPartnerModal: () => {},
  setPartnerList: () => {},
  setProducts: () => {},
  postContactUs: () => {},
  postSubscribe: () => {},
  getPartners: () => {},
  getFaq: () => {},
  getListProduct: () => {},
  getDetailProduct: () => {},
  getTestimonial: () => {},
  getReviewMarketplace: () => {},
  getArticle: (setData) => {},
  getArticleDetails: (setData, { id }) => {},
  getSideArticle: (setData, { category }) => {},
  searchArticle: (setData, { src }) => {},
  getAdsArticle: (setData) => {},
  getActivity: (setData) => {},
  getActivityDetails: (setData, { id }) => {},
  getSideActivity: (setData, { category }) => {},
  searchActivity: (setData, { src }) => {},
  getAdsActivity: (setData) => {},
  getMeta: (setData) => {},
  getListStore: (setData) => {},
});

export const MainContextProvider = ({ children }) => {
  const [navbar, setNavbar] = useState(true);
  const [isFyiHovered, setFyiHovered] = useState(false);
  const [showFyiModal, setShowFyiModal] = useState(false);

  // Subscribe
  const [showSubsModal, setShowSubsModal] = useState(false);
  const [subsStatus, setSubsStatus] = useState("Subscribe");
  const [subsData, setSubsData] = useState({
    status: "",
    msg: "",
  });
  // Contact Us
  const [contactStatus, setContactStatus] = useState("Kirim");
  const [contactError, setContactError] = useState("");
  // Partner
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [partnerList, setPartnerList] = useState(null);

  //Products
  const [products, setProducts] = useState(null);

  const baseUrl = "https://api.dhcsupplement.co.id/req";
  const headersConfig = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const assetsUrl = "https://api.dhcsupplement.co.id/assets/upload";
  const assetsUrl2 = "https://dhcsupplement.co.id/assets/upload";

  const postContactUs = async (body) => {
    let reqBody = {
      name: body.name,
      company: body.company,
      phone: body.phone,
      email: body.email,
      message: body.message,
      action: "inbox",
    };

    const res = await axios
      .post(`${baseUrl}/inbox`, reqBody, {
        headers: headersConfig,
      })
      .catch((err) => {
        setTimeout(() => {
          setContactStatus("Mengirim.");

          setTimeout(() => {
            setContactStatus("Gagal Terkirim");
            setContactError(err.message);
          }, 1250);
        }, 2500);
      });

    if (res.data.status === "ok") {
      //fake loading state
      setTimeout(() => {
        setContactStatus("Mengirim.");

        setTimeout(() => {
          setContactStatus("Berhasil Terkirim");
        }, 1250);
      }, 2500);
    } else {
      //fake loading state
      setTimeout(() => {
        setContactStatus("Mengirim.");

        setTimeout(() => {
          setContactStatus("Gagal Terkirim");
        }, 1250);
      }, 2500);
    }
  };

  const postSubscribe = async (body) => {
    let reqBody = {
      email: body.email,
      action: "subscribe",
    };
    setSubsStatus("Loading");

    const res = await axios.post(`${baseUrl}/subscribers`, reqBody, {
      headers: headersConfig,
    });

    if (res.data.status === "ok") {
      setSubsData({
        status: "Success",
        msg: res.data.msg,
      });

      setSubsStatus("Loading.");

      setTimeout(() => {
        setSubsStatus("Selesai");

        setTimeout(() => {
          setShowSubsModal(true);
        }, 300);
      }, 1000);
    } else {
      setSubsData({
        status: "Failed",
        msg: res.data.msg,
      });
      setSubsStatus("Loading.");

      setTimeout(() => {
        setSubsStatus("Selesai");

        setTimeout(() => {
          setShowSubsModal(true);
        }, 300);
      }, 1000);
    }
  };

  const getPartners = async (setData) => {
    const res = await axios.get(`${baseUrl}/partner?action=get_partner`);

    setData(res.data);
  };

  const getFaq = async (setData) => {
    const res = await axios.get(`${baseUrl}/faq?action=get_faq`);

    setData(res.data);
  };

  const getListProduct = async () => {
    const res = await axios.get(`${baseUrl}/product?action=get_listproduct`);

    setProducts(res.data);
  };

  const getDetailProduct = async (setData, slug, setNotfound) => {
    await axios
      .get(`${baseUrl}/product?action=get_detailproduct&slug=${slug}`)
      .then((res) => {
        setData(res.data[0]);
      })
      .catch(() => {
        setData(null);

        setTimeout(() => {
          setNotfound(true);
        }, 6000);
      });
  };

  const getTestimonial = async (setData) => {
    await axios
      .get(`${baseUrl}/testimoni?action=get_testimonial`)
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        setData(null);
      });
  };

  const getReviewMarketplace = async (setData) => {
    await axios
      .get(`${baseUrl}/testimoni?action=get_review`)
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        setData(null);
      });
  };

  // API Article
  const getArticle = async (setData) => {
    await axios
      .get(`${baseUrl}/article`, {
        params: {
          action: "get_article",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getArticleDetails = async (setData, params) => {
    await axios
      .get(`${baseUrl}/article`, {
        params: {
          action: "detail_article",
          slug: params.slug,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getSideArticle = async (setData, params) => {
    await axios
      .get(`${baseUrl}/article`, {
        params: {
          action: "sidebar_article",
          category: params?.category,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const searchArticle = async (setData, params) => {
    await axios
      .post(
        `${baseUrl}/article`,
        {
          action: "search",
          src: params?.src,
        },
        {
          headers: headersConfig,
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getAdsArticle = async (setData, params) => {
    await axios
      .get(`${baseUrl}/article`, {
        params: {
          action: "list_banner",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  // API Article
  const getActivity = async (setData) => {
    await axios
      .get(`${baseUrl}/activity`, {
        params: {
          action: "get_activity",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getActivityDetails = async (setData, params) => {
    await axios
      .get(`${baseUrl}/activity`, {
        params: {
          action: "detail_activity",
          slug: params.slug,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getSideActivity = async (setData, params) => {
    await axios
      .get(`${baseUrl}/activity`, {
        params: {
          action: "sidebar_activity",
          category: params?.category,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const searchActivity = async (setData, params) => {
    await axios
      .post(
        `${baseUrl}/activity`,
        {
          action: "search",
          src: params?.src,
        },
        {
          headers: headersConfig,
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getAdsActivity = async (setData, params) => {
    await axios
      .get(`${baseUrl}/activity`, {
        params: {
          action: "list_banner",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getMeta = async (setData) => {
    await axios
      .get(`${baseUrl}/meta`, {
        params: {
          action: "meta",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  const getListStore = async (setData) => {
    await axios
      .get(`${baseUrl}/listStore`, {
        params: {
          action: "get_list_store",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setData({
          status: "error",
          msg: err?.message || "Data tidak ditemukan",
        });
      });
  };

  useEffect(() => {
    if (products === null) {
      getListProduct();
      // getDetailProduct('', 'vitamin-c');
    }
  }, [products]);

  return (
    <mainContext.Provider
      value={{
        assetsUrl,
        assetsUrl2,
        navbar,
        isFyiHovered,
        showFyiModal,
        showSubsModal,
        subsStatus,
        subsData,
        contactStatus,
        contactError,
        showPartnerModal,
        partnerList,
        products,
        setNavbar,
        setFyiHovered,
        setShowFyiModal,
        setShowSubsModal,
        setSubsStatus,
        setSubsData,
        setContactStatus,
        setContactError,
        setShowPartnerModal,
        setPartnerList,
        setProducts,
        postContactUs,
        postSubscribe,
        getPartners,
        getFaq,
        getListProduct,
        getDetailProduct,
        getTestimonial,
        getReviewMarketplace,
        getArticle,
        getArticleDetails,
        getSideArticle,
        searchArticle,
        getAdsArticle,
        getActivity,
        getActivityDetails,
        getSideActivity,
        getAdsActivity,
        searchActivity,
        getMeta,
        getListStore,
      }}
    >
      {children}
    </mainContext.Provider>
  );
};

export default mainContext;
