const keywordProducts = {
  "vitamin-c": {
    id: 1,
    slug: "vitamin-c",
    description:
      "DHC Vitamin C + B2 mengandung 1000mg Vitamin C per 2 kapsul, setara dengan kandungan Vitamin C di dalam 50 buah Lemon Jepang, Tinggi Antioksidan dan dilengkapi dengan Vitamin B2 untuk membantu menjaga kesehatan kulit dan membrane mukosa.",
    keyword:
      "suplemen vitamin c, vitamin c obat, dhc vitamin c manfaat, vitamin c yang bagus, vitamin c tablet, suplemen dari jepang, vitamin c jepang, dhc vitamin c halal atau tidak",
  },
  "folic-acid": {
    id: 2,
    slug: "folic-acid",
    description:
      "DHC Folic Acid + Vit B mengandung Asam Folat 400μg setara dengan nutrisi 360gram bayam Jepang. Untuk memenuhi kebutuhan Asam Folat harian Ibu Hamil dan Menyusui, Meningkatkan Daya Ingat yang menurun pada lansia serta menjaga kesehatan sehari-hari.",
    keyword:
      "dhc folic acid, folic acid untuk ibu hamil, harga folic acid, folic acid supplement benefits, folic acid tablet obat apa, folic acid untuk promil, biotin",
  },
  calcium: {
    id: 3,
    slug: "calcium",
    description:
      "DHC Calcium + CBP mengandung Kalsium yang dilengkapi dengan CBP (Concentrated Bovine Milk-Whey Active Protein) yang berfungsi mengoptimalkan penyerapan Kalsium pada tubuh sehingga mampu meningkatkan Kepadatan dan Kekuatan tulang. 4 tablet setara dengan kandungan CBP dalam 8 liter susu.",
    keyword:
      "suplemen kalsium, kalsium untuk tulang, kegunaan kalsium, kalsium tablet, obat kalsium, kekurangan kalsium, dhc calcium, suplemen dhc, suplemen kalsium",
  },
  forskohlii: {
    id: 4,
    slug: "forskohlii",
    description:
      "DHC Forskohlii + B1, B2 and B6 dengan kandungan senyawa Forskolin dari Bahan Alami Tumbuhan, yang dapat membantu membakar lemak tubuh secara efektif, dan hasilnya adalah membantu membentuk LBM yang sehat.",
    keyword:
      "forskohlii weight loss, diet sehat, cara diet cepat, obat diet ampuh, obat diet alami, obat diet di apotik yang aman, obat diet yang aman, suplemen diet terbaik, suplemen diet di apotik",
  },
  collagen: {
    id: 5,
    slug: "collagen",
    description:
      "DHC Collagen + B1 and B2 mengandung 2,050mg Fish Collagen Peptide, dengan Collagen Peptide yang memiliki struktur lebih kecil sehingga lebih mudah diserap tubuh dibandingkan dengan Collagen biasa.",
    keyword:
      "suplemen kolagen, kolagen untuk wajah, kolagen untuk kulit, kolagen terbaik, manfaat dhc collagen, suplemen dhc, suplemen kulit, dhc collagen, suplemen kecantikan yang aman",
  },
  "multi-vitamins": {
    id: 6,
    slug: "multi-vitamins",
    description:
      "DHC Multi Vitamins mengandung 11 jenis Vitamins, Bioflavonoid dan β-Carotene dalam 1 kapsul sehingga praktis dikonsumsi untuk memenuhi asupan nutrisi harian tubuh yang tidak tercukupi dari pola makan sehari-hari dan efektif menjaga imunitas tubuh agar tidak mudah sakit.",
    keyword:
      "multivitamin yang bagus, multivitamin lengkap, multivitamin untuk daya tahan tubuh, dhc multivitamins, multivitamin terbaik, suplemen dhc, vitamin lengkap",
  },
};

export default keywordProducts;
