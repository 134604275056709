import React, { useContext, useEffect, useState } from "react";
import "./Article.scss";

import Layout from "../../components/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import { Helmet } from "react-helmet-async";
import Badge from "../../components/Badge";
import { Link, useLocation, useParams } from "react-router-dom";
import mainContext from "../../context/MainContext";
import unslugify from "unslugify";

function ArticleCategory() {
  const { assetsUrl2, searchArticle } = useContext(mainContext);
  const location = useLocation();
  const { category } = useParams();

  const [data, setData] = useState(null);

  const articleLinks = [
    {
      page: "Home",
      link: "/",
    },
    {
      page: "Article",
      link: "/article",
    },
    {
      page: unslugify(category) || "...",
      link: `/article/c/${category}`,
    },
  ];

  useEffect(() => {
    if (data === null) {
      searchArticle(setData, { src: unslugify(category) });
    }
  }, [data, searchArticle, category]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://dhcsupplement.co.id/about-us" />
        <title>Kategori Artikel - DHC Supplement</title>
        <meta
          name="description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          name="keywords"
          content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
        />
        <meta property="og:title" content="About Us - DHC Supplement" />
        <meta
          property="og:description"
          content="Informasi tentang Suplemen DHC, DHC Corporation dan apa itu produk DHC. DHC Bertujuan Untuk mendukung gerakan hidup sehat masyarakat Indonesia dan memberikan yang terbaik dari Jepang"
        />
        <meta
          property="og:url"
          content="https://dhcsupplement.co.id/about-us"
        />
      </Helmet>

      <Layout activeNav="article">
        <main className="article layout-gap">
          <Breadcrumb links={articleLinks} />

          <div className="article__list py-8 sm:py-12 font-nunito-sans">
            <div className="w-11/12 lg:w-10/12 flex flex-col space-y-6 sm:space-y-10 mx-auto">
              <h5 className="w-full px-3 text-primary text-xl sm:text-3xl xl:text-3.1xl font-bold">
                Artikel {unslugify(category)}
              </h5>

              {data === null || data?.status === "error" ? (
                <div className="w-full h-[10vh] flex justify-center items-center">
                  <h5 className="text-lg text-primary font-semibold">
                    {data?.msg || "Data tidak ditemukan"}
                  </h5>
                </div>
              ) : (
                <div className="w-full flex flex-col sm:flex-row">
                  {data?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={`${location.pathname}/${item?.slug}`}
                        className="articleCard w-full sm:w-[48%] lg:w-[23%] sm:mx-[1%] mb-6 flex flex-col border border-slate-200 rounded-lg shadow-md cursor-pointer overflow-hidden"
                      >
                        <div className="w-full rounded-t-lg">
                          <img
                            src={`${assetsUrl2}/${item?.thumbnail}`}
                            alt={item?.title}
                            className="w-full h-auto rounded-t-lg"
                          />
                        </div>

                        <div className="flex flex-col p-3">
                          <h4 className="h-11 line-clamp-2 text-sm font-semibold">
                            {item?.title}
                          </h4>
                          <div className="w-full flex flex-wrap text-xxs z-50">
                            {item?.category.map((cat, catIndex) => {
                              return (
                                <Badge
                                  key={catIndex}
                                  className={"!whitespace-pre z-50 mr-2 mt-2"}
                                >
                                  {cat?.title || ""}
                                </Badge>
                              );
                            })}
                          </div>
                          <div
                            className="articleCard__subtitle text-xs mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item?.content,
                            }}
                          ></div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

export default ArticleCategory;
