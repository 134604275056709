import React, { useRef } from 'react';
import './AboutDHC.scss';
import { ButtonSimple } from '../components/Button';
import { Link } from 'react-router-dom';
import { useInView } from 'framer-motion';

const animation = {
  animation1: (isInView) => {
    let style = {
      transform: isInView ? 'none' : 'translateX(-250px)',
      WebkitTransform: isInView ? 'none' : 'translateX(-250px)',
      MozTransform: isInView ? 'none' : 'translateX(-250px)',
      msTransform: isInView ? 'none' : 'translateX(-250px)',
      OTransform: isInView ? 'none' : 'translateX(-250px)',
      opacity: isInView ? 1 : 0,
      transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s',
    };
    return style;
  },
  animation2: (isInView) => {
    let style = {
      transform: isInView ? 'none' : 'translateX(250px)',
      WebkitTransform: isInView ? 'none' : 'translateX(250px)',
      MozTransform: isInView ? 'none' : 'translateX(250px)',
      msTransform: isInView ? 'none' : 'translateX(250px)',
      OTransform: isInView ? 'none' : 'translateX(250px)',
      opacity: isInView ? 1 : 0,
      transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.7s',
    };
    return style;
  },
  animation3: (isInView) => {
    let style = {
      transform: isInView ? 'none' : 'translateX(250px)',
      WebkitTransform: isInView ? 'none' : 'translateX(250px)',
      MozTransform: isInView ? 'none' : 'translateX(250px)',
      msTransform: isInView ? 'none' : 'translateX(250px)',
      OTransform: isInView ? 'none' : 'translateX(250px)',
      opacity: isInView ? 1 : 0,
      transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s',
    };
    return style;
  },
};

function AboutDHC() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      className="aboutDHC flex flex-col sm:flex-row justify-around items-center bg-white space-y-6 sm:space-y-0"
    >
      <div className="w-full sm:w-5/12" style={animation.animation1(isInView)}>
        <img
          className="w-[38%] sm:w-7/12 xl:w-6/12 h-auto mx-auto"
          src="/images/universal/logo-primary.png"
          alt="Brand"
        />
      </div>
      <div className="w-11/12 sm:w-5/12 flex flex-col space-y-6 sm:space-y-4 items-center">
        <p
          style={animation.animation2(isInView)}
          className="text-justify text-base xl:text-lg text-graylight font-nunito-sans"
        >
          DHC merupakan produk dari Jepang, dan merek No. 1 di Jepang yang
          dipercaya oleh konsumen selama lebih dari 40 tahun. Hampir semua
          proses dilakukan DHC secara in house, dimulai dari riset, pengembangan
          produk hingga proses produksi.
        </p>
        <div style={animation.animation3(isInView)}>
          <ButtonSimple>
            <Link to="/about-us">SELENGKAPNYA</Link>
          </ButtonSimple>
        </div>
      </div>
    </div>
  );
}

export default AboutDHC;
