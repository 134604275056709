import React, { useContext, useEffect, useRef, useState } from 'react';
import './ProductList.scss';

import { motion, useInView } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';

import { screenMobile } from '../utils/Breakpoints';
import mainContext from '../context/MainContext';

const animation = {
  anim1: (isInView) => {
    let style = {
      transform: isInView ? 'none' : 'translateX(250px)',
      WebkitTransform: isInView ? 'none' : 'translateX(250px)',
      MozTransform: isInView ? 'none' : 'translateX(250px)',
      msTransform: isInView ? 'none' : 'translateX(250px)',
      OTransform: isInView ? 'none' : 'translateX(250px)',
      opacity: isInView ? 1 : 0,
      transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s',
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? 'none' : 'translateX(50px)',
      WebkitTransform: isInView ? 'none' : 'translateX(50px)',
      MozTransform: isInView ? 'none' : 'translateX(50px)',
      msTransform: isInView ? 'none' : 'translateX(50px)',
      OTransform: isInView ? 'none' : 'translateX(50px)',
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.7}s`,
    };
    return style;
  },
};

const animationSm = (isInView, delay) => {
  let style = {
    transform: isInView ? 'none' : 'translateY(50px)',
    WebkitTransform: isInView ? 'none' : 'translateY(50px)',
    MozTransform: isInView ? 'none' : 'translateY(50px)',
    msTransform: isInView ? 'none' : 'translateY(50px)',
    OTransform: isInView ? 'none' : 'translateY(50px)',
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 1.5}s`,
  };
  return style;
};

function ProductList() {
  const [isHovered, setHovered] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();
  const { products, assetsUrl } = useContext(mainContext);

  const [faqScroll, setFaqScroll] = useState(0);
  const [faqScrollerSize, setFaqScrollerSize] = useState(null);
  const maxScroll = products !== null && products.length - 2;

  const scrollToNextPage = (id, faq) => {
    const faqScroller = document.querySelector(`.product-slider-mobile-list`);
    const faqItemSize = faqScroller.querySelector('.product-item').clientWidth;

    if (faqScroll !== faqScrollerSize * maxScroll) {
      setFaqScroll(faqScroll + faqScrollerSize);
    }

    faqScroller.scrollTo(faqScroller.scrollLeft + faqItemSize, 0);
  };

  const scrollToPrevPage = (id, faq) => {
    const faqScroller = document.querySelector(`.product-slider-mobile-list`);
    const faqItemSize = faqScroller.querySelector('.product-item').clientWidth;

    if (faqScroll !== 0) {
      setFaqScroll(faqScroll - faqScrollerSize);
    }

    faqScroller.scrollTo(faqScroller.scrollLeft - faqItemSize, 0);
  };

  const handleScroll = (e) => {
    setFaqScroll(Math.floor(e.currentTarget.scrollLeft));
  };

  useEffect(() => {
    if (faqScrollerSize === null) {
      setFaqScrollerSize(
        document.querySelector(`.product-slider-mobile-list`).clientWidth
      );
    }
  }, [faqScrollerSize]);

  return (
    <div className="productList">
      <div ref={ref} className="productList__main flex flex-col items-start">
        <h3
          className="w-full text-xl sm:text-2.2xl lg:text-3xl xl:text-3.1xl font-bold font-nunito-sans text-white text-center pb-3 sm:pb-9 lg:pb-12"
          style={animation.anim1(isInView)}
        >
          Suplemen Kesehatan DHC
        </h3>
        <div className="cursor-grab hidden sm:block">
          <motion.div
            drag="x"
            dragConstraints={{
              right: 0,
              left:
                products !== null
                  ? window.innerWidth > screenMobile
                    ? window.innerWidth *
                      -0.19 *
                      (products.length < 4 ? 0 : products.length - 4.5)
                    : window.innerWidth * -0.51 * (products.length - 2)
                  : 0,
            }}
            className="product-slider hidden sm:flex space-x-5 sm:space-x-7 lg:space-x-9"
          >
            {products !== null &&
              products.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`product-item relative`}
                    onMouseEnter={() => setHovered(index + 1)}
                    onMouseLeave={() => setHovered(false)}
                    style={animation.anim2(isInView, 0.3 * (index + 1))}
                  >
                    <motion.img
                      draggable="false"
                      src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                      alt={item.name_product}
                      initial={{ scale: 1 }}
                      transition={{ duration: 0.25 }}
                      animate={
                        window.innerWidth > screenMobile
                          ? { scale: isHovered === index + 1 ? 1.05 : 1 }
                          : {}
                      }
                      onClick={
                        window.innerWidth <= screenMobile
                          ? () => navigate(`/our-product/${item.slug}`)
                          : null
                      }
                    />
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={
                        isHovered === index + 1
                          ? {
                              opacity: 1,
                              y: 0,
                            }
                          : { opacity: 0, y: 20 }
                      }
                      className="product-item-card w-full h-auto hidden sm:flex justify-center items-center absolute bottom-0 left-0 text-dark text-center font-bold font-nunito-sans py-6"
                    >
                      <Link
                        className="rounded-full"
                        to={`/our-product/${item.slug}`}
                      >
                        <button
                          className={`border border-graylight px-4 py-1 lg:px-6 lg:py-2 xl:px-10 text-base xl:text-xl text-graylight font-semibold font-nunito-sans rounded-full hover-gradient-primary transition-all ease-in duration-300`}
                        >
                          Read More
                        </button>
                      </Link>
                    </motion.div>
                  </div>
                );
              })}
          </motion.div>
        </div>
        {/* Mobile Product List Mapping */}
        <div className="product-slider-mobile relative w-full sm:hidden">
          <div
            className="product-slider-mobile-list w-full flex flex-nowrap"
            onScroll={(e) => handleScroll(e)}
          >
            {products !== null &&
              products.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`product-item shrink-0 sm:shrink`}
                    style={
                      window.innerWidth > screenMobile
                        ? animation.anim2(isInView, 0.3 * (index + 1))
                        : animationSm(isInView, 0.3 * index)
                    }
                  >
                    <div className="m-3 shadow-md rounded-[10px]">
                      <img
                        src={`${assetsUrl}/product/${item.slug}/${item.img1}`}
                        alt={item.name_product}
                        onClick={() => navigate(`/our-product/${item.slug}`)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <button
            className={`absolute absolute-y-center left-0 rounded-full p-[6px] bg-gray-100 ${
              faqScroll === 0 ? 'opacity-60' : 'opacity-100'
            } transition-all duration-200 ease-out`}
            style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
            onClick={() => scrollToPrevPage()}
            disabled={faqScroll === 0 ? true : false}
          >
            <VscChevronLeft
              className={`w-[22px] h-[22px] text-primary ${
                faqScroll === 0 ? 'opacity-60' : 'opacity-100'
              } transition-all duration-200 ease-out`}
            />
          </button>
          <button
            className={`absolute absolute-y-center right-0 rounded-full p-[6px] bg-gray-100 ${
              faqScroll >= maxScroll * ((faqScrollerSize - 8) / 2)
                ? 'opacity-60'
                : 'opacity-100'
            } transition-all duration-200 ease-out`}
            style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
            onClick={() => scrollToNextPage()}
            disabled={
              faqScroll >= maxScroll * ((faqScrollerSize - 8) / 2)
                ? true
                : false
            }
          >
            <VscChevronRight
              className={`w-[22px] h-[22px] text-primary ${
                faqScroll >= maxScroll * ((faqScrollerSize - 8) / 2)
                  ? 'opacity-60'
                  : 'opacity-100'
              } transition-all duration-200 ease-out`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
