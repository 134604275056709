import React, { Fragment } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function Breadcrumb({ links }) {
  return (
    <div className="breadcrumb flex items-center space-x-2 sm:space-x-3 px-5 sm:px-9 xl:px-10 py-3 lg:py-4 bg-white font-sans text-sm lg:text-base text-primary overflow-auto focus:touch-auto">
      {links.map((item, index) => {
        return (
          <Fragment key={index}>
            <Link
              to={item.link}
              className="text-theme-light-primary whitespace-nowrap"
            >
              {item.page}
            </Link>
            {index !== links.length - 1 && (
              <HiChevronRight className="text-base sm:text-lg shrink-0" />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export default Breadcrumb;
