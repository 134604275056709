import React, { useRef } from "react";
import { useInView } from "framer-motion";
import "./ProductAdvantages.scss";

const animation = {
  anim1: (isInView) => {
    let style = {
      transform: isInView ? "none" : "translateX(250px)",
      WebkitTransform: isInView ? "none" : "translateX(250px)",
      MozTransform: isInView ? "none" : "translateX(250px)",
      msTransform: isInView ? "none" : "translateX(250px)",
      OTransform: isInView ? "none" : "translateX(250px)",
      opacity: isInView ? 1 : 0,
      transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateY(150px)",
      WebkitTransform: isInView ? "none" : "translateY(150px)",
      MozTransform: isInView ? "none" : "translateY(150px)",
      msTransform: isInView ? "none" : "translateY(150px)",
      OTransform: isInView ? "none" : "translateY(150px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay}`,
    };
    return style;
  },
};

function ProductAdvantages() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div className="productAdvantages h-auto sm:h-[520px] md:h-[520px] lg:h-[560px] xl:h-[680px] flex justify-center items-center font-nunito-sans">
      <div
        ref={ref}
        className="productAdvantages__box w-[90%] lg:w-[86%] xl:w-10/12 flex flex-col justify-around space-y-7 xl:space-y-10 px-3 py-7 sm:p-3 lg:p-5 xl:p-10"
      >
        <h3
          className="text-center text-white text-xl sm:text-2.2xl md:text-2.2xl lg:text-3xl xl:text-3.1xl font-bold"
          style={animation.anim1(isInView)}
        >
          Official DHC Supplement Indonesia
        </h3>

        <div className="flex flex-col sm:flex-row justify-around items-start space-y-9 sm:space-y-0">
          <div className="w-full sm:w-4/12 flex flex-col items-center space-y-2 sm:space-y-6 text-white text-center">
            <img
              className="w-[35%] sm:w-[46%] xl:w-[42%] h-auto"
              src="/images/universal/no-1-market-share.png"
              alt="Leaf"
              style={animation.anim2(isInView, "0.7s")}
            />
            <h5
              className="text-xl sm:text-1xl xl:text-2.1xl font-semibold pt-2"
              style={animation.anim2(isInView, "0.9s")}
            >
              No.1 Market Share
            </h5>
            <p
              className="text-base xl:text-lg px-7"
              style={animation.anim2(isInView, "1.1s")}
            >
              Berdasarkan "H.B.Foods
              <br />
              Marketing Handbook&nbsp;
              <br className="hidden sm:block" />
              2023 Vol.1",
              <br className="block sm:hidden" />
              Fuji Keizai.
            </p>
          </div>
          <div className="w-full sm:w-4/12 flex flex-col items-center space-y-2 sm:space-y-6 text-white text-center">
            <img
              className="w-[35%] sm:w-[46%] xl:w-[42%] h-auto"
              src="/images/universal/sold-million.png"
              alt="Circle"
              style={animation.anim2(isInView, "1.3s")}
            />
            <h5
              className="text-xl sm:text-1xl xl:text-2.1xl font-semibold pt-2"
              style={animation.anim2(isInView, "1.5s")}
            >
              Sold &gt; 450 Million* Pack
            </h5>
            <p
              className="text-base xl:text-lg px-7"
              style={animation.anim2(isInView, "1.7s")}
            >
              Total DHC supplement
              <br />
              sales until 2021.
            </p>
          </div>
          <div className="w-full sm:w-4/12 flex flex-col items-center space-y-2 sm:space-y-6 text-white text-center">
            <img
              className="w-[35%] sm:w-[46%] xl:w-[42%] h-auto"
              src="/images/universal/premium-quality.png"
              alt="Achieve"
              style={animation.anim2(isInView, "1.9s")}
            />
            <h5
              className="text-xl sm:text-1xl xl:text-2.1xl font-semibold pt-2"
              style={animation.anim2(isInView, "2.1s")}
            >
              Premium Quality
            </h5>
            <p
              className="text-base xl:text-lg px-7"
              style={animation.anim2(isInView, "2.3s")}
            >
              Made in Japan.
              <br />
              Delivering the safest,
              <br />
              highest-quality products.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductAdvantages;
