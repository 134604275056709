import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';

function Layout({ children, activeNav }) {
  return (
    <div className="overflow-hidden w-full layout">
      <Navbar activeNav={activeNav} />
      {children}
      <Footer activeNav={activeNav} />
    </div>
  );
}

export default Layout;
