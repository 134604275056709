import React from 'react';
import './ProductModal.scss';
import { motion } from 'framer-motion';
import Close from './svg/Close';

const variants = {
  hidden: {
    opacity: 0,
    zIndex: -1,
    transition: { ease: 'backOut', duration: 0.5 },
  },
  show: {
    opacity: 1,
    zIndex: 1000,
    transition: { ease: 'backOut', duration: 0.5 },
  },
};

const variants2 = {
  hidden: {
    scale: 0.5,
    transition: { ease: 'easeInOut', duration: 0.5 },
  },
  show: {
    scale: 1,
    transition: { ease: 'easeInOut', duration: 0.5 },
  },
};

const transition = {
  type: 'tween',
  duration: 0.5,
  ease: [0.7, -0.4, 0.4, 1.2],
};

function ProductModal({
  showProductModal,
  toggleProductModal,
  img_front,
  img_back,
  img_object,
  name_product,
  modalSlide,
  setModalSlide,
}) {
  const variantsImg = {
    hidden: { rotateY: 90, scale: 1, opacity: 0.5 },
    show: { rotateY: 0, opacity: 1, transition: { delay: 0.5 } },
    show2: {
      rotateY: 0,
      scale: 1.2,
      opacity: 1,
      transition: { delay: 0.5 },
    },
  };

  return (
    <motion.div
      className="productModal scrollbar-custom"
      initial={{ opacity: 0, zIndex: -1 }}
      variants={variants}
      animate={showProductModal ? 'show' : 'hidden'}
    >
      <motion.div
        className="bg-white max-w-full w-[55vw] max-h-[95%] h-auto relative flex flex-col p-6 rounded-[10px] overflow-auto"
        initial={{ scale: 0.5 }}
        variants={variants2}
        animate={showProductModal ? 'show' : 'hidden'}
      >
        <button
          onClick={toggleProductModal}
          className="w-10 h-10 2xl:w-11 2xl:h-11 absolute top-6 right-6 cursor-pointer rounded-full"
        >
          <Close className="w-full h-auto" />
        </button>

        <div className="w-full flex flex-row space-x-2">
          <div className="w-6/12 h-full flex items-center relative mx-10">
            <img
              className="w-full h-auto invisible"
              src={img_front}
              alt="Product Front"
            />

            <motion.img
              className="w-full h-auto absolute top-0 left-0"
              src={img_front}
              alt="Product Front"
              variants={variantsImg}
              initial={'hidden'}
              animate={modalSlide === 1 ? 'show' : 'hidden'}
              transition={transition}
              onClick={() => setModalSlide(1)}
            />

            <motion.img
              className="w-full h-auto mx-auto absolute top-0 left-0"
              src={img_back}
              alt="Product Back"
              variants={variantsImg}
              initial={'hidden'}
              animate={modalSlide === 2 ? 'show' : 'hidden'}
              transition={transition}
              onClick={() => setModalSlide(2)}
            />

            <motion.img
              className="w-full h-auto mx-auto absolute left-0"
              src={img_object}
              alt="Product Object"
              variants={variantsImg}
              initial={'hidden'}
              animate={modalSlide === 3 ? 'show' : 'hidden'}
              transition={transition}
              onClick={() => setModalSlide(3)}
            />
          </div>
          <div className="w-6/12 h-auto flex flex-col space-y-8 pt-14">
            <div className="w-full flex justify-center">
              <p className="text-2.1xl text-primary font-semibold font-lora">
                DHC {name_product}
              </p>
            </div>
            <div className="w-full flex items-center justify-around space-x-5">
              <div className="w-3/12 h-auto relative">
                <motion.img
                  className="w-full h-auto cursor-pointer"
                  src={img_front}
                  alt="Product Front"
                  animate={modalSlide === 1 ? { opacity: 1 } : { opacity: 0.5 }}
                  onClick={() => setModalSlide(1)}
                />
              </div>
              <div className="w-3/12 h-auto relative">
                <motion.img
                  className="w-full h-auto cursor-pointer"
                  src={img_back}
                  alt="Product Back"
                  animate={modalSlide === 2 ? { opacity: 1 } : { opacity: 0.5 }}
                  onClick={() => setModalSlide(2)}
                />
              </div>
              <div className="w-4/12 h-auto relative">
                <motion.img
                  className="w-full h-auto cursor-pointer"
                  src={img_object}
                  alt="Product Object"
                  animate={modalSlide === 3 ? { opacity: 1 } : { opacity: 0.5 }}
                  onClick={() => setModalSlide(3)}
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default ProductModal;
