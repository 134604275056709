import React, { useContext, useEffect, useRef, useState } from "react";
import "./Testimonial.scss";

import { FaStar } from "react-icons/fa";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import mainContext from "../context/MainContext";

const animation = {
  anim1: (isInView) => {
    let style = {
      transform: isInView ? "none" : "translateX(250px)",
      WebkitTransform: isInView ? "none" : "translateX(250px)",
      MozTransform: isInView ? "none" : "translateX(250px)",
      msTransform: isInView ? "none" : "translateX(250px)",
      OTransform: isInView ? "none" : "translateX(250px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s`,
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "translateX(50px)",
      WebkitTransform: isInView ? "none" : "translateX(50px)",
      MozTransform: isInView ? "none" : "translateX(50px)",
      msTransform: isInView ? "none" : "translateX(50px)",
      OTransform: isInView ? "none" : "translateX(50px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.7}s`,
    };
    return style;
  },
};

const Testimonial = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { getTestimonial, assetsUrl } = useContext(mainContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data === null) {
      getTestimonial(setData);
    }
  }, [getTestimonial, data]);

  return (
    <div className="testimonial flex flex-col space-y-4 sm:space-y-8 lg:space-y-12 py-12 sm:py-[10vh] lg:pt-[15vh] lg:pb-[10vh]">
      <div
        ref={ref}
        className="w-full sm:w-5/12 flex flex-col mx-auto items-center text-center"
        style={animation.anim1(isInView)}
      >
        <h5 className="text-graylight text-xl sm:text-2.2xl lg:text-3xl xl:text-3.1xl font-bold font-nunito-sans">
          Apa Kata Mereka?
        </h5>
      </div>

      {/* Laptop Screen */}
      <div className="hidden lg:block">
        <motion.div
          className="cursor-grab w-[94%] xl:w-11/12 hidden lg:flex flex-nowrap items-start mx-auto px-2 sm:px-4 py-5 lg:py-2 lg:px-0"
          drag="x"
          dragConstraints={{
            right: 0,
            left:
              data !== null
                ? window.innerWidth *
                  -0.225 *
                  (data.length < 4 ? 0 : data.length - 4)
                : 0,
          }}
        >
          {data !== null &&
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-full sm:w-6/12 lg:w-3/12 px-2 sm:px-6 lg:px-3 xl:px-4 shrink-0"
                  style={animation.anim2(isInView, 0.3 * (index + 1))}
                >
                  <div
                    className="w-full rounded-md flex flex-col space-y-4 5xl:space-y-6 bg-white text-sm 5xl:text-base text-graylight"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.12) 0px 3px 8px" }}
                  >
                    <img
                      className="w-full h-auto object-contain rounded-t-md"
                      src={`${assetsUrl}/testimonial/${item.image}`}
                      alt={item.product_name}
                      draggable="false"
                    />
                    <div
                      className={`testimonial-box w-full flex flex-col space-y-1 h-[200px] sm:h-52 lg:h-56 5xl:h-52`}
                    >
                      <div className="w-full flex justify-center items-center space-x-2 text-base 5xl:text-xl text-yellow-300">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div>
                      <p className={`px-4 pt-1 5xl:pt-3 text-justify`}>
                        {item.description}
                      </p>
                    </div>
                    <div className="w-11/12 pt-2 pb-5 5xl:pt-4 flex flex-col space-y-1 items-center mx-auto">
                      <h5 className="text-dark font-semibold capitalize">
                        {item.name}
                      </h5>
                      <h4 className="text-xs lg:text-xxs xl:text-xs 5xl:text-base">
                        {item.product_name}
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
        </motion.div>
      </div>

      {/* Mobile Screen */}
      <div className="testimonial__list w-[98%] sm:w-[96%] flex lg:hidden flex-nowrap items-start mx-auto px-2 sm:px-4 py-5">
        {data !== null &&
          data.map((item, index) => {
            return (
              <div
                key={index}
                className="testimonial__list-item w-full sm:w-6/12 px-2 sm:px-6 shrink-0"
                style={animation.anim2(isInView, 0.3 * (index + 1))}
              >
                <div
                  className="w-full rounded-md flex flex-col space-y-4 bg-white text-sm text-graylight"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.12) 0px 3px 8px" }}
                >
                  <img
                    className="w-full h-auto object-contain rounded-t-md"
                    src={`${assetsUrl}/testimonial/${item.image}`}
                    alt={item.product_name}
                    draggable="false"
                  />
                  <div
                    className={`testimonial-box w-full flex flex-col space-y-1 h-[200px] sm:h-52`}
                  >
                    <div className="w-full flex justify-center items-center space-x-2 text-base text-yellow-300">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                    <p className={`px-4 pt-1 text-justify`}>
                      {item.description}
                    </p>
                  </div>
                  <div className="w-11/12 pt-2 pb-5 flex flex-col space-y-1 items-center mx-auto">
                    <h5 className="text-dark font-semibold">{item.name}</h5>
                    <h4 className="text-xs">{item.product_name}</h4>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Testimonial;
