import React, { useContext, useRef } from "react";
import "./OurProduct.scss";

import { useInView } from "framer-motion";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import { DetailsButton } from "../components/Button";
import { Helmet } from "react-helmet-async";
import { screenMobile } from "../utils/Breakpoints";
import mainContext from "../context/MainContext";

const ourProductLinks = [
  {
    page: "Home",
    link: "/",
  },
  {
    page: "Our Product",
    link: "/our-product",
  },
];

const animation = (isInView, delay) => {
  let style = {
    transform: isInView ? "none" : "translateX(50px)",
    WebkitTransform: isInView ? "none" : "translateX(50px)",
    MozTransform: isInView ? "none" : "translateX(50px)",
    msTransform: isInView ? "none" : "translateX(50px)",
    OTransform: isInView ? "none" : "translateX(50px)",
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 1.5}s`,
  };
  return style;
};

const animationSm = (isInView, delay) => {
  let style = {
    transform: isInView ? "none" : "translateY(50px)",
    WebkitTransform: isInView ? "none" : "translateY(50px)",
    MozTransform: isInView ? "none" : "translateY(50px)",
    msTransform: isInView ? "none" : "translateY(50px)",
    OTransform: isInView ? "none" : "translateY(50px)",
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 1.5}s`,
  };
  return style;
};

function OurProduct() {
  const { products, assetsUrl } = useContext(mainContext);
  const ref = useRef(null);
  const isInView = useInView(products !== null && ref, { once: true });

  return (
    <>
      <Layout activeNav="our-product">
        <Helmet>
          <link
            rel="canonical"
            href="https://dhcsupplement.co.id/our-product"
          />
          <title>Our Product - DHC Supplement</title>
          <meta
            name="description"
            content="Produk kami yang sudah masuk di Indonesia dari DHC Supplement Made in Japan. Loved Worldwide produk berkualitas tinggi dan inovatif dari jepang yang dicintai di seluruh penjuru dunia."
          />
          <meta
            name="keywords"
            content="suplemen, supplement, vitamin, multivitamin, obat diet, folic acid, ibu dan anak, kalsium, obat tulang, collagen, kolagen"
          />
          <meta property="og:title" content="Our Product - DHC Supplement" />
          <meta
            property="og:description"
            content="Produk kami yang sudah masuk di Indonesia dari DHC Supplement Made in Japan. Loved Worldwide produk berkualitas tinggi dan inovatif dari jepang yang dicintai di seluruh penjuru dunia."
          />
          <meta
            property="og:url"
            content="https://dhcsupplement.co.id/our-product"
          />
        </Helmet>

        <main className="ourProduct layout-gap">
          <Breadcrumb links={ourProductLinks} />

          <div
            ref={ref}
            className="ourProduct__list flex flex-nowrap sm:flex-wrap items-end space-y-0 sm:space-y-10 px-0 sm:px-8 pb-12 pt-2 xl:px-12 2xl:px-14 xl:pb-14 xl:pt-6 bg-white font-nunito-sans"
          >
            {products !== null &&
              products.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ourProduct__list-item w-[95%] shrink-0 px-3 sm:shrink sm:w-4/12 sm:px-4 xl:px-5"
                    style={
                      window.innerWidth > screenMobile
                        ? animation(isInView, 0.3 * index)
                        : animationSm(isInView, 0.3 * index)
                    }
                  >
                    <div
                      className={`ourProduct__list-item-card flex flex-col space-y-5 sm:space-y-8 rounded-[18px] py-4 xl:py-5`}
                      style={{ backgroundColor: item.color }}
                    >
                      <h5 className="font-bold text-2.1xl sm:text-1xl xl:text-2.1xl text-white text-center font-lora px-4 xl:px-5">
                        DHC {item.name_product}
                      </h5>
                      <img
                        className="w-full px-1 sm:px-3 2xl:px-4"
                        src={`${assetsUrl}/product/${item.slug}/${item.img2}`}
                        alt={item.name_product}
                      />
                      <div className="px-4 xl:px-5">
                        <DetailsButton link={item.slug} color={item.color}>
                          SELENGKAPNYA
                        </DetailsButton>
                      </div>
                      <div
                        className="h-44 sm:h-[250px] lg:h-[220px] xl:h-[160px] 2xl:h-[155px] 3xl:h-[140px] 4xl:h-[130px] px-4 xl:px-5 overflow-hidden text-white text-sm font-nunito-sans font-semibold text-justify"
                        dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      ></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </main>
      </Layout>
    </>
  );
}

export default OurProduct;
