import React from 'react';

function IconTokopedia(props) {
  return (
    <svg
      viewBox="0 0 263 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M138.708 0c4.123 0 8.311.725 11.898 2.818.877.511 1.981.742 2.939 1.121 21.703 8.702 35.435 24.607 40.743 47.583 1.283 5.587 4.335 6.016 8.701 5.983 17.288-.083 34.592.148 51.88-.132 5.665-.1 7.531 1.945 7.515 7.614-.146 48.819.503 97.654-.276 146.473-.633 39.853-21.329 70.064-54.428 82.722-10.178 3.889-21.021 5.603-31.897 5.62-55.353.049-110.706-.083-166.043.198-7.272.033-9.772-1.83-9.74-9.576.26-74.613.244-149.225.017-223.838C0 59.285 2.208 57.142 9.285 57.34c16.736.478 33.504.033 50.24.214 5.065.066 7.5-1.319 8.815-6.906C73.518 28.645 86.78 13.301 107.395 4.5c1.283-.543 2.906-.708 4.156-1.417C114.781 1.252 118.304 0 122.005 0h16.703zm-8.636 290.341c.666-.016 1.315 0 1.981.033 17.417 1.137 34.835 1.187 52.236-.214 15.535-1.253 29.349-6.692 40.841-17.569.357-.347.731-.676 1.12-.973 17.694-14.224 26.038-33.54 26.362-55.823.666-46.825.081-93.682.357-140.507.049-7.433-2.678-9.427-9.512-9.262-15.908.362-31.848-.231-47.74.197-18.895.528-37.481 2.753-54.639 10.746-6.185 2.868-13.246 2.984-19.431.132-7.986-3.692-16.378-6.263-25.063-7.944-24.089-4.68-48.454-2.44-72.722-2.802C7.906 66.108 9.74 65.317 9.74 80.25c-.049 63.883-.016 127.783-.016 191.666 0 20.684 0 21.278 19.333 18.674a21.591 21.591 0 013.57-.182c32.498.907 64.996.94 97.494-.033l-.049-.033zm.747-273.76c-19.901 0-38.082 13.449-45.16 32.815-2.207 6.048-.762 8.455 5.325 9.295 9.772 1.352 19.284 3.725 28.407 7.351 7.646 3.033 16.103 3.05 23.748 0 9.318-3.725 18.993-6.263 29.008-7.516 5.454-.692 6.509-2.95 4.805-8.224-6.412-19.81-25.144-33.721-46.117-33.721h-.016z"
        fill={props.color ? props.color : '#003994'}
      />
      <path
        d="M181.418 221.38c-14.934-7.532-25.452-1.467-34.624 10.812-4.496 6.016-10.34 10.993-15.567 16.432-8.311-8.191-17.06-15.971-24.787-24.706-5.292-5.983-10.389-7.994-17.515-4.318-1.412.725-3.117.84-4.691 1.252-10.519.198-20.989.511-31.134-3.477-25.404-9.988-41.653-35.123-40.014-62.4 1.656-27.524 21.411-51.044 48.016-57.191 26.313-6.066 53.195 6.098 66.862 30.277.666 1.186 1.315 2.389 1.965 3.593 3.538 1.944 3.132-1.764 3.944-3.115 20.745-34.266 60.467-42.375 92.185-18.724 19.171 14.29 27.888 42.869 20.486 67.064-7.743 25.283-30.112 43.265-54.672 43.973-3.49.099-6.98.347-10.486.528h.032zm-50.142 15.789c4.659-4.466 9.399-8.834 13.944-13.399 10.989-11.06 9.837-15.196-5.487-20.157-2.841-.923-5.698-1.55-8.684-.874-8.685-1.137-15.535 2.769-21.833 8.175-1.396 1.203-3.36 2.555-1.153 4.549 7.873 7.087 13.701 16.432 23.213 21.706zm108.758-78.337c0-29.156-23.18-52.989-51.765-53.203-28.992-.214-52.61 23.668-52.562 53.17.049 29.271 23.002 52.906 51.717 53.236 28.813.329 52.61-23.734 52.61-53.203zm-113.32.412c.179-29.601-23.18-53.632-52.122-53.615-28.651 0-52.042 23.635-52.22 52.741-.18 29.552 23.163 53.566 52.155 53.665 28.52.099 52.009-23.668 52.187-52.791zm10.13 35.023c-3.068-2.505-1.624-8.916-6.575-8.043-1.866.33-4.886 5.571-4.188 7.153 2.192 5.093 6.818-.098 10.763.89z"
        fill="#039"
      />
      <path
        d="M240.03 158.82c0 29.469-23.813 53.532-52.61 53.202-28.699-.329-51.668-23.98-51.717-53.235-.048-29.503 23.586-53.385 52.561-53.17 28.586.214 51.782 24.046 51.766 53.203zm-66.618 32.238c4.869 1.945 9.755 2.076 14.641.066 16.801-3.544 27.645-18.806 25.745-36.244-1.866-17.174-16.395-29.897-33.26-29.139 4.707 11.768 3.847 19.119-3.425 24.178-10.584 7.368-18.538 1.896-25.453-6.757-10.811 23.173 4.886 45.967 21.768 47.896h-.016zM126.739 159.23c-.179 29.123-23.667 52.873-52.188 52.791-28.992-.099-52.334-24.113-52.156-53.664.18-29.107 23.57-52.742 52.22-52.742 28.944 0 52.302 24.031 52.124 53.615zm-78.241-7.73c-1.9 4.945-1.948 9.889.048 14.834 3.815 17.289 19.934 28.595 36.67 25.728 17.612-3.017 29.592-18.575 28.033-36.425-1.525-17.52-16.167-30.656-33.325-29.898 4.707 11.785 3.733 18.839-3.393 24.179-4.155 3.132-8.733 3.923-13.797 2.736-5.276-1.236-7.89-5.983-12.142-9.757-.828 3.395-1.478 5.999-2.11 8.62l.016-.017z"
        fill="#FAFAFA"
      />
      <path
        d="M131.268 237.175c-9.512-5.274-15.339-14.619-23.212-21.707-2.208-1.977-.244-3.345 1.152-4.548 6.298-5.406 13.149-9.313 21.833-8.175.081 11.471.146 22.959.227 34.43zM131.29 237.164c-.081-11.471-.146-22.959-.228-34.43 2.987-.676 5.844-.049 8.685.874 15.307 4.961 16.476 9.098 5.487 20.157-4.546 4.565-9.285 8.933-13.944 13.399z"
        fill={props.color === '#ffffff' ? '#F5C25D' : '#003994'}
      />
      <path
        d="M136.884 194.245c-3.944-.989-8.57 4.202-10.762-.89-.682-1.583 2.322-6.84 4.188-7.154 4.951-.857 3.507 5.538 6.574 8.044z"
        fill="#039"
      />
      <path
        d="M173.428 191.056c-16.865-1.928-32.578-24.722-21.767-47.896 6.931 8.653 14.885 14.125 25.452 6.758 7.272-5.06 8.133-12.411 3.425-24.179 16.866-.758 31.394 11.949 33.261 29.14 1.899 17.454-8.944 32.716-25.745 36.243-4.87-2.621-9.74-2.571-14.642-.066h.016z"
        fill={props.color ? '#003994' : '#003994'}
      />
      <path
        d="M173.449 191.048c4.886-2.505 9.772-2.571 14.642.066-4.886 2.027-9.772 1.879-14.642-.066z"
        fill="#039"
      />
      <path
        d="M48.445 151.497c.65-2.621 1.283-5.225 2.11-8.62 4.254 3.774 6.867 8.521 12.142 9.757 5.065 1.187 9.643.396 13.798-2.736 7.126-5.357 8.1-12.394 3.393-24.179 17.158-.741 31.8 12.395 33.325 29.898 1.559 17.833-10.421 33.408-28.033 36.425-16.752 2.867-32.871-8.439-36.67-25.728 2.419-4.961 2.468-9.906-.048-14.834l-.017.017z"
        fill={props.color ? '#003994' : '#003994'}
      />
      <path
        d="M48.423 151.5c2.532 4.928 2.468 9.873.049 14.834-2.013-4.945-1.948-9.889-.049-14.834z"
        fill="#039"
      />
    </svg>
  );
}

export default IconTokopedia;
