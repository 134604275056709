import React, { useRef, useState, useContext, useEffect } from "react";
import "./FindBrands.scss";

import { motion, useInView } from "framer-motion";
import mainContext from "../context/MainContext";
import { partnersData } from "../utils/dummyData";

const animation = {
  anim1: (isInView) => {
    let style = {
      transform: isInView ? "none" : "translateY(100px)",
      WebkitTransform: isInView ? "none" : "translateY(100px)",
      MozTransform: isInView ? "none" : "translateY(100px)",
      msTransform: isInView ? "none" : "translateY(100px)",
      OTransform: isInView ? "none" : "translateY(100px)",
      opacity: isInView ? 1 : 0,
      transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s`,
    };
    return style;
  },
  anim2: (isInView, delay) => {
    let style = {
      transform: isInView ? "none" : "scale(0.6)",
      WebkitTransform: isInView ? "none" : "scale(0.6)",
      MozTransform: isInView ? "none" : "scale(0.6)",
      msTransform: isInView ? "none" : "scale(0.6)",
      OTransform: isInView ? "none" : "scale(0.6)",
      opacity: isInView ? 1 : 0,
      transition: `all 1s cubic-bezier(0.17, 1.75, 0.45, 1) ${delay + 0.7}s`,
    };
    return style;
  },
};

function FindBrands() {
  const {
    getPartners,
    assetsUrl,
    showPartnerModal,
    setShowPartnerModal,
    setPartnerList,
  } = useContext(mainContext);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const partnerLength = partnersData.filter(
    (item) => item.category === "partner"
  ).length;

  const [data, setData] = useState(null);
  const [isHovered, setHovered] = useState(false);
  // const location = useLocation();

  const handlePartner = (dataObj) => {
    setPartnerList(dataObj);
    setShowPartnerModal(!showPartnerModal);
  };

  const handleMarketplace = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (data === null) {
      getPartners(setData);
    }
  }, [getPartners, data]);

  return (
    <div className="findBrands flex flex-col space-y-5">
      <div className="findBrands__main relative">
        <motion.img
          initial={{ x: 0, opacity: 0.5 }}
          animate={{ x: [-10, -40, -10], y: [-20, -20, 0], opacity: 1 }}
          exit={{ x: 0, y: 0 }}
          transition={{ repeat: Infinity, repeatType: "mirror", duration: 4 }}
          className="absolute top-5 left-[24vw] w-[15vw] h-auto"
          src="/images/universal/cloud.png"
          alt="Cloud"
        />
        <motion.img
          initial={{ x: 0, opacity: 0.5 }}
          animate={{ x: [20, 40, 20], y: [-30, -30, 0], opacity: 0.8 }}
          exit={{ x: 0, y: 0 }}
          transition={{ repeat: Infinity, repeatType: "mirror", duration: 6 }}
          className="absolute top-10 right-[24vw] w-[12vw] h-auto"
          src="/images/universal/cloud.png"
          alt="Cloud"
        />
        <div ref={ref} className="findBrands__main-list">
          <h3
            className="w-full text-center pb-8 sm:pb-14 font-bold text-xl sm:text-2.2xl lg:text-3xl xl:text-3.1xl text-graylight font-nunito-sans"
            style={animation.anim1(isInView)}
          >
            Temukan Kami Disini
          </h3>

          <div className="w-full flex flex-wrap items-center justify-center">
            {data !== null && data.length
              ? data
                  .filter((item) => item.category === "partner")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="partner-item w-6/12 sm:w-4/12 px-[4vw] mb-[5vh] sm:mb-[6vh]"
                        style={animation.anim2(isInView, 0.25 * (index + 1))}
                        onMouseEnter={() => setHovered(item.id)}
                        onMouseLeave={() => setHovered(false)}
                      >
                        <div
                          className="relative cursor-pointer"
                          onClick={() => handlePartner(item)}
                        >
                          <img
                            draggable="false"
                            src={`${assetsUrl}/partner/${item.img2}`}
                            alt={item.name_product}
                          />
                          <motion.img
                            className="w-full absolute top-0 absolute-x-center"
                            draggable="false"
                            src={`${assetsUrl}/partner/${item.img}`}
                            alt={item.name_product}
                            initial={{ opacity: 1 }}
                            animate={{
                              opacity: isHovered === item.id ? 1 : 0,
                              transition: { duration: 0.2 },
                            }}
                          />
                        </div>
                      </div>
                    );
                  })
              : ""}

            {data !== null && data.length
              ? data
                  .filter((item) => item.category === "marketplace")
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="partner-item w-6/12 sm:w-4/12 px-[4vw] mb-[5vh] sm:mb-[6vh]"
                        style={animation.anim2(
                          isInView,
                          0.25 * (index + partnerLength)
                        )}
                        onMouseEnter={() => setHovered(item.id)}
                        onMouseLeave={() => setHovered(false)}
                      >
                        <div
                          className="relative cursor-pointer"
                          onClick={() => handleMarketplace(item.link_url)}
                        >
                          <img
                            draggable="false"
                            src={`${assetsUrl}/partner/${item.img2}`}
                            alt={item.name_product}
                          />
                          <motion.img
                            className="w-full absolute top-0"
                            draggable="false"
                            src={`${assetsUrl}/partner/${item.img}`}
                            alt={item.name_product}
                            initial={{ opacity: 1 }}
                            animate={{
                              opacity: isHovered === item.id ? 1 : 0,
                              transition: { duration: 0.2 },
                            }}
                          />
                        </div>
                      </div>
                    );
                  })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindBrands;
