import * as React from 'react';
import { motion } from 'framer-motion';

function EllipseDots({
  widthSvg,
  variants,
  initial,
  animate,
  className,
  style,
}) {
  return (
    <svg
      width={widthSvg}
      viewBox="0 0 1214 1214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <motion.circle
        variants={variants}
        initial={initial}
        animate={animate}
        cx={607.16}
        cy={607.16}
        r={604}
        stroke="#2D2D2D"
        strokeWidth={5}
        strokeLinecap="round"
        strokeDasharray="20 20"
      />
    </svg>
  );
}

export default EllipseDots;
