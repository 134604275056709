import React from "react";
import { motion } from "framer-motion";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";
import { AiOutlineTwitter } from "react-icons/ai";

import { IconLazada, IconShopee, IconTokopedia } from "../svg";
import { screenMobile } from "../../utils/Breakpoints";

function SocmedFooterMobile({ isHovered, setHovered }) {
  return (
    <nav className="flex lg:hidden justify-center items-center space-x-4 sm:space-x-6 pb-5">
      <a
        href="https://www.tokopedia.com/dhcsupplement"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(1)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1] "
          animate={isHovered === 1 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <IconTokopedia
            className={`w-[55%] h-auto bg-transparent transition-all duration-200 ease-out`}
            color={
              window.innerWidth <= screenMobile
                ? "#ffffff"
                : isHovered === 1
                ? "#ffffff"
                : "#003994"
            }
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 bg-[#3FAF48] rounded-full absolute top-0"
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 1
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
      <a
        href="https://shopee.co.id/dhcsupplement"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(2)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1]"
          animate={isHovered === 2 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <IconShopee
            className={`w-[60%] h-auto bg-transparent transition-all duration-200 ease-out`}
            color={
              window.innerWidth <= screenMobile
                ? "#ffffff"
                : isHovered === 2
                ? "#ffffff"
                : "#003994"
            }
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 bg-[#E74B2E] rounded-full absolute top-0"
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 2
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
      <a
        href="https://s.lazada.co.id/l.1gtf"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(3)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1]"
          animate={isHovered === 3 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <IconLazada
            className={`w-[65%] h-auto bg-transparent transition-all duration-200 ease-out`}
            color={
              window.innerWidth <= screenMobile
                ? "#ffffff"
                : isHovered === 3
                ? "#ffffff"
                : "#003994"
            }
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 rounded-full absolute top-0"
          style={{
            background:
              "linear-gradient(90deg, #fead00 0%, #fc9f00 7.14%, #fb9100 14.29%, #fa8200 21.43%, #fb7000 28.57%, #fe5900 35.71%, #fe003c 50%, #fe0062 57.14%, #fe007b 64.29%, #fe008e 71.43%, #fe009f 78.57%, #fe00af 85.71%, #fe00cc 100%)",
          }}
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 3
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100090106659567"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(4)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1]"
          animate={isHovered === 4 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <CgFacebook
            className={`w-[75%] h-auto bg-transparent ${
              window.innerWidth <= screenMobile
                ? "text-white"
                : isHovered === 4
                ? "text-white"
                : "text-primary"
            } transition-all duration-200 ease-out`}
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 bg-[#395693] rounded-full absolute top-0"
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 4
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
      <a
        href="https://www.instagram.com/dhcsupplement.id/"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(5)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1]"
          animate={isHovered === 5 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <FaInstagram
            className={`w-[75%] h-auto bg-transparent ${
              window.innerWidth <= screenMobile
                ? "text-white"
                : isHovered === 5
                ? "text-white"
                : "text-primary"
            } transition-all duration-200 ease-out`}
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 rounded-full absolute top-0"
          style={{
            background:
              "linear-gradient(163deg, #4964da 0%, rgba(146, 73, 221, 0.8) 11.8%, rgba(176, 60, 196, 0.6) 23.6%, rgba(192, 55, 173, 0.4) 35.4%, rgba(217, 46, 116, 0) 59%), radial-gradient(100% 100% at 10% 100%, #fdd575 0%, #fcc06a 14.29%, #fbaa63 28.57%, #f99262 42.86%, #f77868 57.14%, #f55973 71.43%, #d22e8c 100%)",
          }}
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 5
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
      <a
        href="https://twitter.com/DhcsupplementId"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(6)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1]"
          animate={isHovered === 6 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <AiOutlineTwitter
            className={`w-[75%] h-auto bg-transparent ${
              window.innerWidth <= screenMobile
                ? "text-white"
                : isHovered === 6
                ? "text-white"
                : "text-primary"
            } transition-all duration-200 ease-out`}
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 bg-[#55ACEE] rounded-full absolute top-0"
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 6
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
      <a
        href="https://www.tiktok.com/@dhcsupplement.id"
        target="__blank"
        referrerPolicy="no-referrer"
        className="socmed-icon w-8 h-8 xl:w-9 xl:h-9 relative flex justify-center items-center bg-white rounded-full overflow-hidden"
        onMouseEnter={() => setHovered(7)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          className="w-full h-full flex justify-center items-center z-[1]"
          animate={isHovered === 7 ? { rotateY: 360 } : { rotateY: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <FaTiktok
            className={`w-[60%] h-auto bg-transparent ${
              window.innerWidth <= screenMobile
                ? "text-white"
                : isHovered === 7
                ? "text-white"
                : "text-primary"
            } transition-all duration-200 ease-out`}
          />
        </motion.div>
        <motion.div
          className="w-8 h-8 xl:w-9 xl:h-9 bg-[#000000] rounded-full absolute top-0"
          initial={{ y: 36, scale: 0.9 }}
          animate={
            window.innerWidth <= screenMobile
              ? { y: 0 }
              : isHovered === 7
              ? { y: 0 }
              : { y: 36 }
          }
          transition={{ ease: "easeOut", duration: 0.5 }}
        ></motion.div>
      </a>
    </nav>
  );
}

export default SocmedFooterMobile;
