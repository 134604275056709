import * as React from 'react';
import { motion } from 'framer-motion';

function Stars(props) {
  return (
    <motion.svg
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 18.5c10.217 0 18.5-8.283 18.5-18.5 0 10.217 8.282 18.5 18.5 18.5-10.218 0-18.5 8.282-18.5 18.5 0-10.218-8.283-18.5-18.5-18.5z"
        fill="#fff"
        fillOpacity={0.5}
      />
    </motion.svg>
  );
}

export default Stars;
